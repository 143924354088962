import { Entity } from 'aframe-react';
import React, { Component } from 'react';

import { checkResolution } from 'util';

// Data
import config from 'srcDir/config';
import axios from 'axios';

// Entities
import { Camera } from '../../Entities';

class Newspaper extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      images: [],
      playing: false,
      abstract: '',
      description: ''
    };
  }
  
  componentDidMount () {
    this.isSmall = checkResolution();
    
    document.querySelector('a-scene')
      .addEventListener('camera-set-active', this.__onCamSwitch, false);

    axios.get(`${config.API_URL}/newspaper`)
      .then((res) => {
        let images = [];
        
        const randomSpreads = res.data[Math.floor(Math.random() * res.data.length)].images;
        
        if (randomSpreads.length <= 1) {
          return false;
        } else if (randomSpreads.length % 2 !== 0) {
          randomSpreads.splice(randomSpreads.length - 1, 1);
        }

        randomSpreads.map((val) => {
          if (!val) return false;
      
          const obj = {
            url: config.API_URL + val['1024'],
            smallUrl: config.API_URL + val['512'],
            name: val.name
          };
          
          images.push(obj);
        });
        
        images.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        
        this.setState({
          images: images,
          mobile: this.isSmall
        });
      });
      
    // this.getCaption();
      
    if (!this.isSmall) {
      document.querySelector('a-scene').addEventListener('lowFPS', this.__onLowFPS, false);
      document.querySelector('a-scene').addEventListener('highFPS', this.__onHighFPS, false);
    }
  }
  
  getCaption = () => {
    axios.get(`${config.API_URL}/areas/`)
      .then((res) => {
        const data = res.data;
        
        const item = data.find((el) => {
          return el.name == 'newspaper';
        });
        
        if(!item) return;
        
        this.setState({
          abstract: item.abstract,
          description: item.description
        });
      });
  };
  
  __onCamSwitch = (evt) => {
    this.activeCam = evt.detail.cameraEl.id;

    if (this.activeCam === 'newspaper_cam') {
      const scene = document.querySelector('a-scene');
      scene.emit('dong', { title: '', abstract: this.state.abstract, description: this.state.description });
    }
  };
  
  __onLowFPS = () => {
    this.setState({ mobile: true });
  };

  __onHighFPS = () => {
    this.setState({ mobile: false });
  };

  render () {
    const pages = this.state.images.map((val) => {
      if (this.state.mobile) {
        return val.smallUrl;
      } else {
        return val.url;
      }
    });
        
    return (
      <Entity
        id="newspaper"
        position={{x: -0.424, y: 0, z: -11.186}}
      >
      
        <Entity
          id="newspaper_loader"
          asset-loader={{
            cameras: ['museum_display_box_cam', 'golem_cam', 'newspaper_cam', 'lift_cam', 'planetarium_outside_cam']
          }}
        >
          <Entity
            id="newspaper_model"
            animation-mixer={{
              clip: "KeyAction",
              timeScale: .5,
              loop: "repeat"
            }}
            position={{x: 0, y: 1.634, z: 0}}
            rotation={{x: 40, y: -90, z: 0}}
            scale={{x: .3, y: .3, z: .3}}
            newspaper={{
              model: "./models/newspaper/newspaper.gltf",
              pages: pages
            }} 
          />
        </Entity>
        
        <Camera
          id="newspaper_cam"
          position={{ x: -2, y: 0, z: 0 }}
          rotation={{ x: 0, y: -90, z: 0 }}
          cameras={[
            'museum_display_box_cam',
            'golem_cam'
          ]}
        />

      </Entity>
    );
  }
}

export default Newspaper;
