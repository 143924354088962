import AFRAME from 'aframe';

const log = AFRAME.utils.debug('aframe-motion-capture:avatar-replayer:info');

/**
 * Wrapper around individual motion-capture-recorder components for recording camera and
 * controllers together.
 */
AFRAME.registerComponent('recorder', {
  init () {
    this.cameraEl = null;
    this.isRecording = false;

    this.recordingData = null;
  },

  play () {
    window.addEventListener('keydown', this.onKeyDown);
  },

  pause () {
    window.removeEventListener('keydown', this.onKeyDown);
  },

  /**
   * Set motion capture recorder on the camera once the camera is ready.
   */
  setupCamera (doneCb) {
    const el = this.el;
    const self = this;

    // Grab camera.
    if (el.camera && el.camera.el) {
      prepareCamera(el.camera.el);
    }

    function prepareCamera (cameraEl) {
      if (self.cameraEl) {
        self.cameraEl.removeAttribute('capture-recorder');
      }
      self.cameraEl = cameraEl;
      cameraEl.setAttribute('capture-recorder', '');
      doneCb(cameraEl);
    }
  },

  /**
   * Start recording camera and tracked controls.
   */
  startRecording (id, el) {
    const self = this;

    this.cameraEl = document.querySelector(el);
    this.cameraEl.setAttribute('camera', 'active', true);

    this.cameraId = id;

    if (this.isRecording) {
      return;
    }

    log('Starting recording!');

    // Get camera.
    this.setupCamera(() => {
      self.isRecording = true;
      self.cameraEl.components['capture-recorder'].startRecording();
    });
  },

  /**
   * Tell camera and tracked controls motion-capture-recorder components to stop recording.
   * Store recording and replay if autoPlay is on.
   */
  stopRecording (cb) {
    if (!this.isRecording) {
      return;
    }

    log('Stopped recording.');
    this.isRecording = false;
    this.cameraEl.components['capture-recorder'].stopRecording();

    this.recordingData = this.getJSONData();
    this.storeRecording(this.recordingData, cb);
  },

  /**
   * Gather the JSON data from the camera and tracked controls motion-capture-recorder
   * components. Combine them together, keyed by the (active) `camera` and by the
   * tracked controller IDs.
   */
  getJSONData () {
    const data = {};

    if (this.isRecording) {
      return;
    }

    // Camera.
    data.camera = this.cameraEl.components['capture-recorder'].getJSONData();

    return data;
  },

  /**
   * Store recording in IndexedDB using recordingdb system.
   */
  storeRecording (data, cb) {
    cb(this.cameraId, data);
  }
});
