import { Entity } from 'aframe-react';
import React, { Component } from 'react';

class PlanetariumImage extends Component {
  constructor (props) {
    super(props);

    this.state = { aspectRatio: 0 };
  }

  componentDidMount () {
    const aspectRatio = this.props.height / this.props.width;

    this.setState({ aspectRatio });
  }

  render () {
    const image = (
      <Entity
        className="planetarium-image"
        portfolio-image={{
          parentLoader: '#planetarium_loader',
          source: this.props.src,
          width: this.props.scale,
          height: this.props.scale * this.state.aspectRatio,
          textureWidth: this.props.textureWidth !== undefined ? this.props.textureWidth : 1024,
          textureHeight: this.props.textureHeight !== undefined ? this.props.textureHeight : 1024,
          lazyLoad: false
        }}
        look-at="#planetarium_center"
        rotatomatic={{
          offset: this.props.offset * 30,
          radius: this.props.index % 2 ? '4' : '6',
          y: Math.random() * 3.5 - 0.25,
          speed: Math.random() * 4 + 0.25,
          direction: this.props.index % 2 ? 'forward' : 'backward',
          rad: this.props.rad
        }}
      />
    );

    return this.state.aspectRatio !== 0 ? image : '';
  }
}

export default PlanetariumImage;
