import React, { Component } from 'react';

import CornerButton from './CornerButton';

import AboutOverlay from './Overlays/About';
import InfoOverlay from './Overlays/Info';
import ProjectsOverlay from './Overlays/Projects';

import Logo from './Logo';

class Corners extends Component {
  constructor (props) {
    super(props);

    this.state = {
      showLabels: true,
      showInfoOverlay: false,
      showAboutOverlay: false,
      showIndexOverlay: false,
      projectInfo: '',
      muted: false,
      disabledCaptures: false
    };
  }

  componentDidMount () {
    this.timer = setTimeout(() => {
      this.setState({ showLabels: false });
    }, 2000);
    
    document.addEventListener('dong', evt => {
      if(document.body.classList.contains('--has-info-overlay')) {
        this.closeInfoOverlay();
      }
      
      this.setState({projectInfo: evt.detail.description, projectAbstract: evt.detail.abstract});
    });
    
    document.querySelector('a-scene').addEventListener('camera-set-active', this.handleCamSwitch, false);
  }

  handleMouseEnter = () => {
    clearTimeout(this.timer);

    if (!this.state.showLabels) this.setState({ showLabels: true });
  }

  handleMouseLeave = () => {
    if (this.state.showLabels) {
      this.timer = setTimeout(() => {
        this.setState({ showLabels: false });
      }, 2000);
    }
  }
  
  handleCamSwitch = () => {
    this.setState({
      disabledCaptures: false
    });
  }

  handleToggleCaptures = () => {
    this.setState({
      disabledCaptures: !this.state.disabledCaptures
    });

    const event = new CustomEvent('toggle-captures', { detail: !this.state.disabledCaptures });
    document.dispatchEvent(event);

    return !this.state.disabledCaptures;
  }
  
  toggleInfoOverlay = () => {
    if(this.hasOverlay() && !this.hasInfoOverlay()) return;
    
    this.setState({
      showAboutOverlay: false,
      showIndexOverlay: false,
      showInfoOverlay: this.hasInfo() ? !this.state.showInfoOverlay : false
    });

    if (!this.state.showInfoOverlay && this.hasInfo()) {
      document.body.classList.add('--has-overlay');
      document.body.classList.add('--has-info-overlay');
    } else {
      document.body.classList.remove('--has-overlay');
      document.body.classList.remove('--has-info-overlay');
    }

    return !this.state.showInfoOverlay;
  }
  
  closeInfoOverlay = () => {
    this.setState({
      showInfoOverlay: false
    });
    
    document.body.classList.remove('--has-info-overlay');
    document.body.classList.remove('--has-overlay');  
  }
  
  hasInfo = () => {
    if (document.body.classList.contains('--has-info')) {
      return true;
    }
    return false;
  }
  
  hasOverlay = () => {
    if (document.body.classList.contains('--has-overlay')) {
      return true;
    }
    return false;
  }
  
  
  hasInfoOverlay = () => {
    if (document.body.classList.contains('--has-info-overlay')) {
      return true;
    }
    return false;
  }

  toggleAboutOverlay = () => {
    this.setState({
      showAboutOverlay: !this.state.showAboutOverlay,
      showIndexOverlay: false,
      showInfoOverlay: false
    });

    if (!this.state.showAboutOverlay) {
      document.body.classList.add('--has-overlay');
    } else {
      document.body.classList.remove('--has-overlay');
    }

    return !this.state.showAboutOverlay;
  }

  toggleProjectsOverlay = () => {
    this.setState({
      showIndexOverlay: !this.state.showIndexOverlay,
      showInfoOverlay: false,
      showAboutOverlay: false
    });

    if (!this.state.showIndexOverlay) {
      document.body.classList.add('--has-overlay');
    } else {
      document.body.classList.remove('--has-overlay');
    }

    return !this.state.showIndexOverlay;
  }

  handleToggleMute = () => {
    const muted = !this.state.muted;

    if(muted !== true) {
      document.body.classList.remove('--mute');
    } else {
      document.body.classList.add('--mute');
    }

    this.setState({
      muted: muted
    });

    const event = new CustomEvent('mute');
    document.dispatchEvent(event);

    return muted;
  }

  render () {
    const showLabels = this.state.showLabels ? ' show-labels' : '';

    return (
      <nav className={showLabels}>
        <CornerButton
          src="./img/ui/icons/unmute-icon.svg"
          toggledSrc="./img/ui/icons/mute-icon.svg"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleToggleMute}
          active={this.state.muted}
          label="Mute"
          toggledLabel="Unmute"
          position="top-left"
        />

        <CornerButton
          src="./img/ui/icons/view-icon.svg"
          toggledSrc="./img/ui/icons/disable-view-icon.svg"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleToggleCaptures}
          active={this.state.disabledCaptures}
          label="Disable Motion"
          toggledLabel="Enable Motion"
          position="top-right"
          flip
        />

        <CornerButton
          src="./img/ui/icons/index-icon.svg"
          toggledSrc="./img/ui/icons/close-icon.svg"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.toggleProjectsOverlay}
          active={this.state.showIndexOverlay}
          label="A – Z"
          position="bottom-left"
        />

        <CornerButton
          src="./img/ui/icons/info-icon.svg"
          toggledSrc="./img/ui/icons/close-icon.svg"
          label="Info"
          position="bottom-right"
          active={this.state.showAboutOverlay}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.toggleAboutOverlay}
          flip
        />
      
        <div
          id="info_trigger"
          onClick={this.toggleInfoOverlay}
        />

        <AboutOverlay
          visible={this.state.showAboutOverlay}
        />
      
        <InfoOverlay
          visible={this.state.showInfoOverlay}
          info={this.state.projectInfo}
          abstract={this.state.projectAbstract}
        />

        <ProjectsOverlay
          visible={this.state.showIndexOverlay}
          toggleOverlay={this.toggleProjectsOverlay}
        />
      
        <button id="close_about_mobile" onClick={this.toggleInfoOverlay}>
          <img src="./img/ui/icons/close-icon.svg" />
        </button>

        <Logo
          active={this.state.showAboutOverlay}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.toggleAboutOverlay}
          label="Logo"
        />
      </nav>
    );
  }
}

export default Corners;
