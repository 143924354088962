import { Entity } from 'aframe-react';
import React, { Component } from 'react';

import { checkResolution } from 'util';

// Entities

import axios from 'axios';
import config from 'srcDir/config';
import colours from 'srcDir/data/idleColours';
import { Macro, SceneModel } from '../../../Entities';

class Billboard extends Component {
  constructor (props) {
    super(props);

    this.state = {
      url: '',
      colour: '#c7832b'
    };

    this.isSmall = false;
    this.paused = true;
    this.firstLoad = false;

    this.randomBillboard = null;
  }

  componentDidMount () {
    this.isSmall = checkResolution();

    let billboards;

    axios.get(`${config.API_URL}/billboard`)
      .then((res) => {
        billboards = res.data;

        if (billboards === undefined || billboards.length === 0) return;

        const random = 0;
        this.randomBillboard = billboards[random];

        this.setState({ url: config.API_URL + billboards[random].image['512'] });
      });

    const loader = document.querySelector('#billboard_loader');
    loader.addEventListener('load-now', this.play, false);
    loader.addEventListener('remove', this.pause, false);
  }

  play = () => {
    this.paused = false;
    if (!this.firstLoad) {
      this.firstLoad = true;
      setTimeout(this.renderColour, 1500);
    } else {
      this.renderColour();
    }
  }

  pause = () => {
    this.paused = true;
    clearTimeout(this.colourTimer);
  }

  renderColour = () => {
    clearTimeout(this.colourTimer);
    if (!this.paused) {
      let newColour = this.state.colour;
      let found = false;
      while (!found) {
        newColour = colours[Math.floor(Math.random() * colours.length)];
        if (newColour !== this.state.colour) {
          found = true;
        }
      }

      this.setState({
        colour: newColour
      });

      this.colourTimer = setTimeout(this.renderColour, 3000);
    }
  };

  render () {
    return (
      <Entity position={{ x: -0.576, y: 0.243, z: -0.152 }} id="billboard">
        <SceneModel
          id="billboard_model"
          src="./models/billboard/billboard"
          tint={this.state.colour}
          animate
        />

        <Macro id="billboard_image">
          <Entity
            id="billboard_loader"
            asset-loader={{
              cameras: [
                'museum_display_box_cam',
                'planetarium_outside_cam',
                'stormdrain_lift_cam',
                'bench_cam',
                'security_cam',
                'noodle_cam'
              ]
            }}
          >
            <Entity
              id="billboard_front"
              position={{ x: 0.327, y: 17.535, z: 0.798 }}
              scale={{ x: 9, y: 9, z: 0 }}
              rotation={{ x: 0, y: 23, z: 0 }}
              portfolio-image={{
                parentLoader: '#billboard_loader',
                source: this.state.url,
                width: '2.1',
                height: '1',
                textureWidth: '1024',
                textureHeight: '512',
                lazyLoad: 'false'
              }}
            />

            <Entity
              id="billboard_back"
              position={{ x: -0.32, y: 17.535, z: -0.727 }}
              scale={{ x: 9, y: 9, z: 0 }}
              rotation={{ x: 0, y: 23, z: 0 }}
              portfolio-image={{
                parentLoader: '#billboard_loader',
                source: this.state.url,
                width: '2.1',
                height: '1',
                textureWidth: '1024',
                textureHeight: '512'
              }}
            />
          </Entity>
        </Macro>
      </Entity>
    );
  }
}

export default Billboard;
