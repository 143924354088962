import AFRAME from 'aframe';

AFRAME.registerComponent('asset-loader', {
  multiple: true,
  schema: {
    cameras: { type: 'array', default: [] }
  },

  init () {
    this.loaded = false;
    this.exited = true;

    this._attachEventListeners();
  },

  _attachEventListeners () {
    this.el.sceneEl.addEventListener('camera-set-active', this.__onCameraActive.bind(this), false);
    this.el.sceneEl.addEventListener('loaded', this.__onLoad.bind(this), false);
    this.el.addEventListener('reset', this.reset.bind(this), false);
  },

  __onLoad () {
    const active_cam = this.el.sceneEl.camera;

    for (let i = 0; i < this.data.cameras.length; i++) {
      if (active_cam.el.id === this.data.cameras[i]) {
        this.loaded = true;
        this.exited = false;
        this.load();
      }
    }
  },

  __onCameraActive (evt) {
    let camId;
    
    if (evt == null) {
      camId = this.activeCameraId;
    } else {
      camId = evt.detail.cameraEl.id;
      this.activeCameraId = camId;
    }
    
    if (!camId) return;

    let found = false;

    for (let i = 0; i < this.data.cameras.length; i++) {
      if (camId === this.data.cameras[i]) {
        found = true;
        if (!this.loaded) {
          this.loaded = true;
          this.exited = false;
          this.load();
        }
      }
    }

    if (!found && !this.exited) {
      this.loaded = false;
      this.exited = true;
      this.el.emit('remove', null);
    }
  },

  load () {
    this.el.emit('load-now', null);
  },

  reset () {
    this.loaded = false;
    this.__onCameraActive();
  },

  _removeEventListeners () {
    this.el.sceneEl.removeEventListener('camera-set-active', this.__onCameraActive, false);
    this.el.sceneEl.removeEventListener('loaded', this.__onLoad, false);
    this.el.removeEventListener('reset', this.reset, false);
  },

  remove () {
    this._removeEventListeners();
  }
});
