import { Entity } from 'aframe-react';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

import MainScreen from './MainScreen';

// Entities
import { Macro, Camera } from '../../../Entities';

// // DATA
// import axios from 'axios'
// import config from 'srcDir/config'
// import { forEach, shuffle } from 'lodash'

class Lab extends Component {
  constructor (props) {
    super(props);
    this.state = { fov: 70 };
  }

  componentDidMount () {
    this.setFOV();
    window.addEventListener('orientationchange', this.__onOrientChange, false);
  }

  __onOrientChange = () => {
    // listening to resize event have same effect
    const self = this;
    setTimeout(() => {
      self.setFOV();
    }, 100);
  }

  setFOV = () => {
    if (this.state.fov) {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (height < width) {
        this.setState({ fov: 70 });
      } else {
        this.setState({ fov: 100 });
      }
    }
  }

  render () {
    return (
      <Entity
        id="lab"
        position={{ x: -0.766, y: 0.136, z: 0.246 }}
      >

        <Macro rotation={{ x: 0, y: 180, z: 0 }}>

          <Entity
            position={{ x: 0.24, y: 3.2, z: 0.9 }}
            rotation={{ x: 0, y: 180, z: 0 }}
            animation={{
              property: 'position',
              to: '0.24 3.6 0.9',
              dur: '4000',
              dir: 'alternate',
              loop: 'true',
              easing: 'easeInOutSine'
            }}
          >
            <Entity
              id="blob_cam"
              primitive="a-camera"
              camera={{
                fov: this.state.fov,
                near: 0.25,
                far: 100
              }}
              look-controls={{ enabled: false }}
              wasd-controls={{ enabled: false }}
            />
          </Entity>

          <Camera
            id="lab_in_cam"
            position={{ x: 2.354, y: -0.34, z: -3.495 }}
            rotation={{ x: 0, y: 145, z: 0 }}
            macro
            near={0.001}
            far={100}
            cameras={['lab_outside_cam']}
            avatar={false}
          />

          <Camera
            id="lab_outside_cam"
            position={{ x: 6.158, y: 7.060, z: -5.3 }}
            rotation={{ x: 0, y: 90, z: 0 }}
            macro
            far={7000}
            cameras={['lab_in_cam']}
          />

          <Entity
            id="lab_loader"
            asset-loader={{
              cameras: ['lab_in_cam', 'lab_outside_cam', 'blob_cam']
            }}
          />

          <Entity
              id="lab_audio_1"
              primitive="a-sound"
              position={{x: 0.320, y: 5.350, z: -0.050}}
              sound={{
                src: 'url(./audio/Stage2_obj_radio.mp3)',
                loop: true,
                volume: .5,
                maxDistance: 1,
                refDistance: 0.01,
                rolloffFactor: .5
              }}
              sound-controls={{
                loader: 'lab_loader'
              }}
          />
        
          <Entity
              id="lab_audio_2"
              primitive="a-sound"
              position={{x: 0, y: 0, z: 0}}
              sound={{
                src: 'url(./audio/Stage2_obj001.mp3)',
                volume: 1,
                poolSize: 5
              }}
              sound-controls={{
                loader: 'stormdrain_loader',
                playOnEvent: 'dong'
              }}
          />
        </Macro>

        <Entity
          gltf-model="url(./models/lab/lab-person.gltf)"
          animation-mixer={{
            clip: 'Working'
          }}
          position={{ x: 0.013, y: 0, z: -0.011 }}
          rotation={{ x: 0, y: -180, z: 0 }}
          visible={!isMobile}
        />

        <Entity
          gltf-model="url(./models/lab/lab-person.gltf)"
          animation-mixer={{
            clip: 'Typing'
          }}
          position={{ x: -0.014, y: 0.001, z: 0.018 }}
          rotation={{ x: 0, y: -65, z: 0 }}
          visible={!isMobile}
        />

        <MainScreen />

      </Entity>
    );
  }
}

export default Lab;
