import { Entity } from 'aframe-react';
import React, { Component } from 'react';

class Macro extends Component {
  render () {
    return (
      <Entity
        scale={{ x: 0.005, y: 0.005, z: 0.005 }}
        {...this.props}
      >
        {this.props.children}
      </Entity>
    );
  }
}

Macro.defaultProps = {
  position: {
    x: 0,
    y: 0,
    z: 0
  }
};

export default Macro;
