import AFRAME from 'aframe';

AFRAME.registerComponent('capture-recorder', {
  init () {
    this.recordedPoses = [];
    this.addEventListeners();
  },

  addEventListeners () {
    this.tick = AFRAME.utils.throttle(this.throttledTick, 33.3333333333, this);
  },

  getJSONData () {
    let data;
    if (!this.recordedPoses) {
      return;
    }
    data = {
      poses: this.recordedPoses
    };
    return data;
  },

  update () {
    if (this.data.autoRecord) {
      this.startRecording();
    }
  },

  throttledTick: (function () {
    return function (time) {
      let newPoint;

      this.lastTimestamp = time;
      if (!this.isRecording) {
        return;
      }

      const obj = this.el.object3D;

      newPoint = {
        position: AFRAME.utils.clone(obj.position),
        rotation: AFRAME.utils.clone(obj.quaternion),
        timestamp: time
      };

      this.recordedPoses.push(newPoint);
    };
  }()),

  startRecording () {
    if (this.isRecording) {
      return;
    }
    this.isRecording = true;
    this.recordedPoses = [];
  },

  stopRecording () {
    if (!this.isRecording) {
      return;
    }
    this.isRecording = false;
  }
});
