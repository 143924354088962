import AFRAME, { THREE } from 'aframe';
import { forEach } from 'lodash';

const vertexShader = require('./shaders/vertex.glsl');
const fragmentShader = require('./shaders/fragment.glsl');

AFRAME.registerComponent('unlit-gltf', {
  multiple: true,
  schema: {
    model: { type: 'model' },

    targetX: { type: 'number', default: 50.0 },
    targetY: { type: 'number', default: 50.0 },

    offset: { type: 'number', default: 0.0 },

    tint: { type: 'color', default: '#000' },
    tintB: { type: 'color', default: '#000' },

    showNormals: { type: 'boolean', default: false },
    blend: { type: 'string' },
    texture: { type: 'map' },
    animate: { type: 'boolean', default: false }
  },

  init () {
    this.model = null;
    this.modelLoader = new THREE.GLTFLoader();
    this.textureLoader = new THREE.TextureLoader();

    this.loaded = false;
    this.materials = [];

    this.firstCall = false;

    if (this.data.texture) {
      this.texture = this.textureLoader.load(this.data.texture);
    }

    const self = this;
    const el = this.el;
    const src = this.data.model;

    this.modelLoader.load(
      src,
      (gltfModel) => {
        self.model = gltfModel.scene || gltfModel.scenes[0];
        self.model.animations = gltfModel.animations;
        el.setObject3D('mesh', self.model);

        self.setMaterial(self.model);
        el.emit('model-loaded', { format: 'gltf', model: self.model });
        self.loaded = true;
        self.el.setAttribute('loaded', true);
      }
    );

    this.setBlendType();
  },

  update () {
    const colour = new THREE.Color(this.data.tint);

    if (this.loaded) {
      if (this.data.animate) {
        this.oldColour = new THREE.Color(this.newColour);
        this.newColour = colour;

        if (this.newColour !== this.oldColour) {
          this.startAnimation = true;
          this.animating = true;
        }
      } else {
        const mixedColor = new THREE.Color(this.data.tint);

        forEach(this.materials, (material) => {
          material.uniforms.tint.value = mixedColor;
          material.uniforms.tintB.value = new THREE.Color(this.data.tintB);

          material.uniforms.offset.value = this.data.offset;
          material.uniforms.targetY.value = this.data.targetY;
          material.uniforms.targetX.value = this.data.targetX;
        });
      }
    }
  },

  setBlendType () {
    switch (this.data.blend) {
      case 'overlay':
        this.blendMode = 1;
        break;
      case 'multiply':
        this.blendMode = 2;
        break;
      case 'hardLight':
        this.blendMode = 3;
        break;
      case 'none':
        this.blendMode = 0;
        break;
      default:
        this.blendMode = 0;
        break;
    }
  },

  setMaterial () {
    const material = this.material = new THREE.ShaderMaterial({
      uniforms: {
        map: { type: 't', value: null },
        mixMap: { type: 't', value: this.texture },
        offset: { type: 'f', value: this.data.offset },
        targetX: { type: 'f', value: this.data.targetX },
        targetY: { type: 'f', value: this.data.targetY },
        tint: { type: 'c', value: new THREE.Color(this.data.tint) },
        tintB: { type: 'c', value: new THREE.Color(this.data.tintB) },
        showNormals: { type: 'b', value: this.data.showNormals },
        blendMode: { type: 'i', value: this.blendMode },
        hasTexture: { type: 'i', value: !!this.data.texture },
        time: { type: 'f', value: 0.0 }
      },
      vertexShader,
      fragmentShader
    });

    material.needsUpdate = true;

    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh === false) return;

      const newMaterial = material.clone();

      newMaterial.name = child.material.name;
      newMaterial.uniforms.map.value = child.material.map;

      this.materials.push(newMaterial);

      child.material = newMaterial;
    });
  },

  tick (t) {
    if (this.startAnimation) {
      this.time = t;
      this.startAnimation = false;
    }

    if (this.animating) {
      const timePassed = t - this.time;

      if (timePassed > 500) this.animating = false;

      const mixedColor = this.oldColour.lerp(this.newColour, timePassed / 500);

      if (!this.model) return;

      forEach(this.materials, (material) => {
        material.uniforms.tint.value = mixedColor;
      });
    }
  },
  
  remove () {
    if (this.model) this.el.removeObject3D('mesh');
    if (this.material) this.material.dispose();
    if (this.texture) this.texture.dispose();

    this.model = null;
    this.material = null;
    this.texture = null;
  }
});
