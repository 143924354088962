import { Entity } from 'aframe-react';
import React from 'react';

const Photosphere = props => (
  <Entity
    className="planetarium-photosphere"
    photosphere={{
      src: props.src
    }}
  />
);

export default Photosphere;
