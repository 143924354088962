import { Entity } from 'aframe-react';
import React, { Component } from 'react';

class Model extends Component {
  render () {
    return (
      <Entity
        className="gltf-model"
        gltf-model={this.props.src}
        rotation={this.props.rotation}
        rotatomatic={{
          offset: this.props.offset * 50,
          radius: this.props.index % 2 ? '6' : '7',
          y: Math.random() * 3 - 1.5,
          speed: Math.random() * 4 + 0.25,
          direction: this.props.index % 2 ? 'forward' : 'backward',
          rad: this.props.rad
        }}
      />
    );
  }
}

export default Model;
