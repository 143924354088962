import React, { Component } from 'react';

class Interface extends Component {
  constructor (props) {
    super(props);

    this.state = {
      progress: 0
    };
  }

  componentDidMount () {
    window.addEventListener('percentLoaded', (e) => {
      this.setState({ progress: e.detail * 100 });
    });
  }

  render () {
    return (
      <div className="loading-wrapper">
        <img id="loading_frame" alt="Loading frame" src="./img/ui/loading-eye.svg" />
        <div className="cube">
          <div className="front">
            <img src="./img/ui/logo.svg" alt="Logo" className="loader-logo" />

            <div className="progress-bar-wrapper">
              <div
                className="progress-bar-progress"
                style={{
                  width: `${this.state.progress}%`
                }}
              >
                <img src="./img/ui/logo-black.svg" alt="Logo" id="mobile_logo" />
              </div>
            </div>
          </div>
          <div className="back">
            <div className="back-inner">
              <div className="loading-text loading">
                <span>LOADING</span>
                <span>LOADING</span>
                <span>LOADING</span>
                <span>LOADING</span>
                <span>LOADING</span>
                <span>LOADING</span>
                <span>LOADING</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Interface;
