import React, { Component } from 'react';
import { Entity } from 'aframe-react';

import Avatar from './Avatar';
import RecordingTimer from './RecordingTimer';

class Camera extends Component {
  constructor (props) {
    super(props);

    const pos = this.props.position;

    const fov = this.props.fov ? this.props.fov : this.props.macro ? 90 : 65;

    let avatar = true;
    if (this.props.avatar === false) avatar = false;

    let motionCams = this.props.cameras;
    motionCams += `,${this.props.id}`;

    this.state = {
      fov,
      near: this.props.near ? this.props.near : this.props.macro ? 0.25 : 0.001,
      far: this.props.far ? this.props.far : 10000,
      position: pos,
      avatar,
      cameras: motionCams
    };
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const aspect = width / height;
    const newFOV = map(aspect, 0.5, 2.0, 110, 75);

    if (this.props.macro) {
      this.setState({ fov: newFOV });
    }
  }

  render () {
    return (
      <Entity
        className="camera-rig"
        position={this.props.position}
        rotation={this.props.rotation}
        scale={{ x: 1.4, y: 1.4, z: 1.4 }}
      >
        <Entity
          shadows={{
            camera: this.props.id
          }}
        />

        <Entity
          primitive="a-camera"
          camera={{
            fov: this.state.fov,
            near: this.state.near,
            far: this.state.far
          }}
          position={{ x: 0, y: 1.6, z: 0 }}
          look-controls={{ enabled: false }}
          look-controls-alt={{ enabled: true }}
          wasd-controls={{ enabled: false }}
          id={this.props.id}
          message={this.props.message}
          replayer={{
            cameras: this.state.cameras,
            current: this.props.id
          }}
        >
          <RecordingTimer />
        </Entity>

        { this.state.avatar
          ? (
            <Avatar
              cameraId={this.props.id}
              cameras={this.props.cameras}
            />
          ) : null }

      </Entity>
    );
  }
}

function map (value, istart, istop, ostart, ostop) {
  return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
}

Camera.defaultProps = {
  macro: false,
  message: '',
  id: ''
};

export default Camera;
