import React, { Component } from 'react';

// Data
import config from 'srcDir/config';
import axios from 'axios';

import { groupBy } from 'lodash';

class Projects extends Component {
  constructor (props) {
    super(props);

    this.state = {
      projects: []
    };
  }

  componentDidMount () {    
    axios.get(`${config.API_URL}/project`)
      .then((res) => {
        let projects = [];

        res.data.forEach((val) => {
          if ((val.images.length > 0 || val.photosphere) && val.enabled) {
            const obj = {
              title: val.title,
              invert: val.invert,
              camera: val.camera,
              year: val.year
            };
            projects.push(obj);
          }
        });
      
        projects = projects.sort((a, b) => {
          if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
          if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
          return 0;
        });
        
        let groupedProjects = groupBy(projects, project => project.year);
        if(groupedProjects['-1']) {
          groupedProjects['-1'] = groupedProjects['undefined'];
          delete groupedProjects['undefined'];
        }
        
        this.setState({
          projects: groupedProjects
        });
      });
  }

  handleClick = (title, invert, camera) => {
    const event = new CustomEvent('getProject', { detail: { title, invert, camera } });
    document.dispatchEvent(event);

    this.props.toggleOverlay();
  }
  
  renderYear = (key) => {
    if(!this.state.projects[key]) return;
    return this.state.projects[key].map((val, i) =>
      <div className="a-z-item" key={i} onClick={() => this.handleClick(val.title, val.invert, val.camera)}>{val.title}</div>
    );
  }

  render () {
    const visible = this.props.visible ? ' visible' : '';
    const projectItems = Object.keys(this.state.projects).sort().reverse().map((key, i) =>
      <div className="a-z-block-wrapper" key={key}>
        <div className="a-z-header" key={i}>{key !== '-1' ? key : ''}</div>
        {this.renderYear(key, i)}
      </div>
    );

    return (
      <div className={`a-z-overlay overlay${visible}`}>
        <div className="a-z-overlay-items-wrapper">
          {projectItems}
        </div>
      </div>
    );
  }
}

export default Projects;
