import React from 'react';

const Info = (props) => {
  const visible = props.visible ? ' visible' : '';

  return (
    <div className={`info-overlay overlay${visible}`}>
      <div className="info-overlay-text-wrapper">
        <p>{ props.abstract }</p>
        <p>{ props.info }</p>
      </div>
    </div>
  );
};

export default Info;
