const cameras = [
  {
    el: '#museum_display_box_cam',
    name: 'Museum',
    clear: true
  },
  {
    el: '#planetarium_outside_cam',
    name: 'Planetarium',
    clear: true
  },
  {
    el: '#planetarium_main_cam_1',
    name: 'Planetarium'
  },
  {
    el: '#planetarium_main_cam_2',
    name: 'Planetarium'
  },
  {
    el: '#stormdrain_lift_cam',
    name: 'Stormdrain'
  },
  {
    el: '#stormdrain_base_cam_1',
    name: 'Stormdrain'
  },
  {
    el: '#stormdrain_base_cam_2',
    name: 'Stormdrain'
  },
  {
    el: '#stormdrain_pillar_cam',
    name: 'Stormdrain'
  },
  {
    el: '#stormdrain_lift_cam',
    name: 'Stormdrain'
  },
  {
    el: '#golem_cam',
    name: 'Golem',
    clear: true
  },
  {
    el: '#newspaper_cam',
    name: 'Newspaper'
  },
  {
    el: '#lab_outside_cam',
    name: 'Lab'
  },
  {
    el: '#lab_in_cam',
    name: 'Lab'
  },
  {
    el: '#blob_cam',
    name: 'Lab'
  },
  {
    el: '#bench_cam',
    name: 'Museum',
    clear: true
  },
  {
    el: '#noodle_cam',
    name: 'Noodle'
  }
];

export default cameras;
