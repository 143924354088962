import React, { Component } from 'react';
import { Entity } from 'aframe-react';

import { checkResolution } from 'util';

// Data
import config from 'srcDir/config';
import axios from 'axios';

import Frame from './Frame';

class Frames extends Component {
  componentDidMount () {
    this.isSmall = checkResolution();

    axios.get(`${config.API_URL}/frame`)
      .then((res) => {
        const obj = {};

        res.data.map((val) => {
          const reference = val.element;

          if (!val.image) return false;

          return (obj[reference] = {
            url: config.API_URL + val.image['1024'],
            smallUrl: config.API_URL + val.image['512'],
            width: val.image.width,
            height: val.image.height
          });
        });

        this.setState({
          images: obj,
          mobile: this.isSmall
        });
      });

    if (!this.isSmall) {
      document.querySelector('a-scene').addEventListener('lowFPS', this.__onLowFPS, false);
      document.querySelector('a-scene').addEventListener('highFPS', this.__onHighFPS, false);
    }
  }

  __onLowFPS = () => {
    this.setState({ mobile: true });
  };

  __onHighFPS = () => {
    this.setState({ mobile: false });
  };

  render () {
    return (
      <Entity
        id="frames"
        position={{ x: -4.278, y: -0.010, z: -8.661 }}
      >
        {this.state && this.state.images && (
          <Entity
            id="frame_loader"
            asset-loader={{
              cameras: [
                'security_cam',
                'museum_display_box_cam',
                'golem_cam',
                'golem_head_cam',
                'planetarium_outside_cam',
                'lab_outside_cam',
                'stormdrain_lift_cam',
                'bench_cam',
                'noodle_cam'
              ]
            }}
          >
            {/* LEFT MAIN ROOM */}
            <Frame
              position={{ x: -6.055, y: 3.556, z: 8.050 }}
              image={this.state.images.left_main_frame}
              smaller={this.state.mobile}
            />

            {/* CENTER MAIN ROOM */}
            <Frame
              position={{ x: -6.055, y: 3.556, z: 3.038 }}
              image={this.state.images.center_main_frame}
              smaller={this.state.mobile}
            />

            {/* RIGHT MAIN ROOM */}
            <Frame
              position={{ x: -6.055, y: 3.556, z: -1.963 }}
              image={this.state.images.right_main_frame}
              smaller={this.state.mobile}
            />
          </Entity>
        )}
      </Entity>
    );
  }
}

export default Frames;
