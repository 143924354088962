import React, { Component } from 'react';
import { Entity } from 'aframe-react';

class RecordingTimer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      countdown: false
    };
  }

  componentDidMount () {
    window.addEventListener('begin-countdown-timer', this.onRecordingTimer);
    window.addEventListener('end-countdown-timer', this.clearRecordingTimer);
  }

  onRecordingTimer = () => {
    this.setState({
      countdown: true,
      seconds: 10
    }, () => {
      this.interval = setInterval(() => {
        if (this.state.seconds === 1) {
          this.clearRecordingTimer();
        }

        this.setState({
          seconds: this.state.seconds - 1
        });
      }, 1000);
    });
  }

  clearRecordingTimer = () => {
    clearInterval(this.interval);

    this.setState({
      countdown: false
    });
  }

  render () {
    return (
      <Entity
        position={{ x: -0.2, y: 0, z: -1 }}
      >
        <Entity
          position={{ x: 0, y: 0, z: -.2 }}
        />
      
        <Entity
          scale={{ x: 0.2, y: 0.2, z: 0.2 }}
          primitive="a-text"
          value={this.state.seconds}
          visible={this.state.countdown}
        />
      </Entity>
    );
  }
}

export default RecordingTimer;
