import AFRAME, { THREE } from 'aframe';
import { isMobile } from 'react-device-detect';

const utils = AFRAME.utils;
const PolyfillControls = utils.device.PolyfillControls;

// To avoid recalculation at every mouse movement tick
const GRABBING_CLASS = 'a-grabbing';
const PI_2 = Math.PI / 2;

const checkHasPositionalTracking = utils.device.checkHasPositionalTracking;

AFRAME.registerComponent('camera-look', {
  dependencies: ['position', 'rotation'],

  schema: {
    enabled: { default: true },
    hmdEnabled: { default: true },
    pointerLockEnabled: { default: false },
    reverseMouseDrag: { default: false },
    touchEnabled: { default: true }
  },

  init () {
    this.previousHMDPosition = new THREE.Vector3();
    this.hmdQuaternion = new THREE.Quaternion();
    this.hmdEuler = new THREE.Euler();
    this.position = new THREE.Vector3();
    // To save / restore camera pose
    this.savedRotation = new THREE.Vector3();
    this.savedPosition = new THREE.Vector3();
    this.polyfillObject = new THREE.Object3D();
    this.polyfillControls = new PolyfillControls(this.polyfillObject);
    this.rotation = {};
    this.deltaRotation = {};
    this.savedPose = null;
    this.pointerLocked = false;
    this.setupMouseControls();

    this.stepped_timer = 0.0;
    this.hasSavedRot = false;

    this.savedPose = {
      position: new THREE.Vector3(),
      rotation: new THREE.Euler()
    };
    this.savedYaw = new THREE.Euler();
    this.hasSavedYaw = false;

    // Call enter VR handler if the scene has entered VR before the event listeners attached.
    if (this.el.sceneEl.is('vr-mode')) {
      this.onEnterVR();
    }
  },

  update (oldData) {
    const data = this.data;

    // Disable grab cursor classes if no longer enabled.
    if (data.enabled !== oldData.enabled) {
      this.updateGrabCursor(data.enabled);
    }

    // Reset pitch and yaw if disabling HMD.
    if (oldData && !data.hmdEnabled && !oldData.hmdEnabled) {
      this.pitchObject.rotation.set(0, 0, 0);
      this.yawObject.rotation.set(0, 0, 0);
    }

    if (oldData && !data.pointerLockEnabled !== oldData.pointerLockEnabled) {
      this._removeEventListeners();
      this._attachEventListeners();
      if (this.pointerLocked) {
        document.exitPointerLock();
      }
    }
  },

  tick () {
    const data = this.data;
    if (!data.enabled) {
      return;
    }
    this.updateOrientation();
  },

  play () {
    this._attachEventListeners();
  },

  pause () {
    this._removeEventListeners();
  },

  remove () {
    this._removeEventListeners();
  },

  /**
   * Set up states and Object3Ds needed to store rotation data.
   */
  setupMouseControls () {
    this.mouseDown = false;
    this.pitchObject = new THREE.Object3D();
    this.pitchObject.rotation.copy(this.el.object3D.rotation);
    this.yawObject = new THREE.Object3D();
    this.yawObject.rotation.copy(this.el.object3D.rotation);
    this.yawObject.position.y = 10;
    this.yawObject.add(this.pitchObject);
  },

  /**
   * Add mouse and touch event listeners to canvas.
   */
  _attachEventListeners () {
    const sceneEl = this.el.sceneEl;
    const canvasEl = sceneEl.canvas;

    // Wait for canvas to load.
    if (!canvasEl) {
      sceneEl.addEventListener('render-target-loaded',
        this._attachEventListeners.bind(this));
      return;
    }

    // Mouse events.
    canvasEl.addEventListener('mousedown', this.__onMouseDown.bind(this),
      false);

    window.addEventListener('mousemove', this.__onMouseMove.bind(this), false);
    window.addEventListener('mouseup', this.__onMouseUp.bind(this), false);

    // Touch events.
    canvasEl.addEventListener('touchstart', this.__onTouchStart.bind(this));
    window.addEventListener('touchmove', this.__onTouchMove.bind(this));
    window.addEventListener('touchend', this.__onTouchEnd.bind(this));

    // sceneEl events.
    sceneEl.addEventListener('enter-vr', this.__onEnterVR.bind(this));
    sceneEl.addEventListener('exit-vr', this.__onExitVR.bind(this));

    // Pointer Lock events.
    if (this.data.pointerLockEnabled) {
      document.addEventListener('pointerlockchange',
        this.__onPointerLockChange.bind(this), false);
      document.addEventListener('mozpointerlockchange',
        this.__onPointerLockChange.bind(this), false);
      document.addEventListener('pointerlockerror',
        this.__onPointerLockError.bind(this), false);
    }

    // noodle events
    document.querySelector('#noodle1')
      .addEventListener('reset-noodle', this.__onReset.bind(this), false);

    if (isMobile) {
      document.querySelector('a-scene').addEventListener('reset-orientation',
        this.__onResetOrient.bind(this), false);
    }
  },

  /**
   * Remove mouse and touch event listeners from canvas.
   */
  _removeEventListeners () {
    const sceneEl = this.el.sceneEl;
    const canvasEl = sceneEl && sceneEl.canvas;

    if (!canvasEl) {
      return;
    }

    // Mouse events.
    canvasEl.removeEventListener('mousedown', this.__onMouseDown);
    window.removeEventListener('mousemove', this.__onMouseMove);
    window.removeEventListener('mouseup', this.__onMouseUp);

    // Touch events.
    canvasEl.removeEventListener('touchstart', this.__onTouchStart);
    window.removeEventListener('touchmove', this.__onTouchMove);
    window.removeEventListener('touchend', this.__onTouchEnd);

    // sceneEl events.
    sceneEl.removeEventListener('enter-vr', this.__onEnterVR);
    sceneEl.removeEventListener('exit-vr', this.__onExitVR);

    // Pointer Lock events.
    document.removeEventListener('pointerlockchange',
      this.__onPointerLockChange);
    document.removeEventListener('mozpointerlockchange',
      this.__onPointerLockChange);
    document.removeEventListener('pointerlockerror',
      this.__onPointerLockError);

    document.querySelector('#noodle1')
      .removeEventListener('reset-noodle', this.__onReset);

    if (isMobile) {
      document.querySelector('a-scene').removeEventListener('reset-orientation',
        this.__onResetOrient);
    }
  },

  /**
   * Update orientation for mobile, mouse drag, and headset.
   * Mouse-drag only enabled if HMD is not active.
   */
  updateOrientation () {
    const el = this.el;
    const hmdEuler = this.hmdEuler;
    const pitchObject = this.pitchObject;
    const yawObject = this.yawObject;
    const sceneEl = this.el.sceneEl;

    // In VR mode, THREE is in charge of updating the camera rotation.
    if (sceneEl.is('vr-mode') && sceneEl.checkHeadsetConnected()) {
      return;
    }

    // Calculate polyfilled HMD quaternion.
    this.polyfillControls.update();
    hmdEuler.setFromQuaternion(this.polyfillObject.quaternion, 'YXZ');

    // On mobile, do camera rotation with touch events and sensors.
    el.object3D.rotation.x = hmdEuler.x + pitchObject.rotation.x;
    el.object3D.rotation.y = hmdEuler.y + yawObject.rotation.y;
    el.object3D.rotation.z = 0;
  },

  /**
   * Translate mouse drag into rotation.
   *
   * Dragging up and down rotates the camera around the X-axis (yaw).
   * Dragging left and right rotates the camera around the Y-axis (pitch).
   */
  __onMouseMove (event) {
    let direction;
    let movementX;
    let movementY;
    const pitchObject = this.pitchObject;
    const previousMouseEvent = this.previousMouseEvent;
    const yawObject = this.yawObject;

    // Not dragging or not enabled.
    if (!this.data.enabled || (!this.mouseDown && !this.pointerLocked)) {
      return;
    }

    // Calculate delta.
    if (this.pointerLocked) {
      movementX = event.movementX || event.mozMovementX || 0;
      movementY = event.movementY || event.mozMovementY || 0;
    } else {
      movementX = event.screenX - previousMouseEvent.screenX;
      movementY = event.screenY - previousMouseEvent.screenY;
    }
    this.previousMouseEvent = event;

    // Calculate rotation.
    direction = this.data.reverseMouseDrag ? 1 : -1;
    yawObject.rotation.y += movementX * 0.002 * direction;
    pitchObject.rotation.x += movementY * 0.002 * direction;
    pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
  },

  /**
   * Register mouse down to detect mouse drag.
   */
  __onMouseDown (evt) {
    if (!this.data.enabled) {
      return;
    }
    // Handle only primary button.
    if (evt.button !== 0) {
      return;
    }

    const sceneEl = this.el.sceneEl;
    const canvasEl = sceneEl && sceneEl.canvas;

    this.mouseDown = true;
    this.previousMouseEvent = evt;
    document.body.classList.add(GRABBING_CLASS);

    if (this.data.pointerLockEnabled && !this.pointerLocked) {
      if (canvasEl.requestPointerLock) {
        canvasEl.requestPointerLock();
      } else if (canvasEl.mozRequestPointerLock) {
        canvasEl.mozRequestPointerLock();
      }
    }
  },

  /**
   * Register mouse up to detect release of mouse drag.
   */
  __onMouseUp () {
    this.mouseDown = false;
    document.body.classList.remove(GRABBING_CLASS);
  },

  /**
   * Register touch down to detect touch drag.
   */
  __onTouchStart (evt) {
    if (evt.touches.length !== 1 || !this.data.touchEnabled) {
      return;
    }
    this.touchStart = {
      x: evt.touches[0].pageX,
      y: evt.touches[0].pageY
    };
    this.touchStarted = true;
  },

  /**
   * Translate touch move to Y-axis rotation.
   */
  __onTouchMove (evt) {
    const canvas = this.el.sceneEl.canvas;
    let deltaY;
    const yawObject = this.yawObject;

    if (!this.touchStarted || !this.data.touchEnabled) {
      return;
    }

    deltaY = 2 * Math.PI *
      (evt.touches[0].pageX - this.touchStart.x) / canvas.clientWidth;

    // Limit touch orientaion to to yaw (y axis).
    yawObject.rotation.y -= deltaY * 0.5;
    this.touchStart = {
      x: evt.touches[0].pageX,
      y: evt.touches[0].pageY
    };
  },

  /**
   * Register touch end to detect release of touch drag.
   */
  __onTouchEnd () {
    this.touchStarted = false;
  },

  /**
   * Save pose.
   */
  __onEnterVR () {
    this.saveCameraPose();
  },

  /**
   * Restore the pose.
   */
  __onExitVR () {
    this.restoreCameraPose();
    this.previousHMDPosition.set(0, 0, 0);
  },

  /**
   * Update Pointer Lock state.
   */
  __onPointerLockChange () {
    this.pointerLocked = !!(
      document.pointerLockElement || document.mozPointerLockElement
    );
  },

  /**
   * Recover from Pointer Lock error.
   */
  __onPointerLockError () {
    this.pointerLocked = false;
  },

  __onReset (evt) {
    const pitchObject = this.pitchObject;
    const yawObject = this.yawObject;

    this.stepped_timer += 0.1;
    const mix = evt.detail;

    const pitch_x = pitchObject.rotation._x;
    const pitch_y = pitchObject.rotation._y;
    const pitch_z = pitchObject.rotation._z;

    const yaw_x = yawObject.rotation._x;
    const yaw_y = yawObject.rotation._y;
    const yaw_z = yawObject.rotation._z;

    const pitch_vector = new THREE.Vector3(pitch_x, pitch_y, pitch_z);
    const yaw_vector = new THREE.Vector3(yaw_x, yaw_y, yaw_z);

    if (yaw_vector.y > Math.PI) {
      yaw_vector.y -= 2 * Math.PI;
    } else if (yaw_vector.y < -Math.PI) {
      yaw_vector.y += 2 * Math.PI;
    }

    const end = new THREE.Vector3(0, 0, 0);

    const current_pitch = new THREE.Vector3().lerpVectors(pitch_vector, end, mix);
    const current_yaw = new THREE.Vector3().lerpVectors(yaw_vector, end, mix);

    pitchObject.rotation.set(current_pitch.x, current_pitch.y, current_pitch.z);
    yawObject.rotation.set(current_yaw.x, current_yaw.y, current_yaw.z);
  },

  __onResetOrient (evt) {
    if (this.el.id === evt.detail.prevCam) {
      this.saveYaw();
    } else if (this.el.id === evt.detail.newCam) {
      if (!this.hasSavedYaw) {
        this.yawObject.rotation.set(0, 0, 0);
      } else {
        // resets yaw to rotation of capture, not pitch though
        this.yawObject.rotation.copy(this.savedYaw);
      }
    }
  },
  /**
   * Toggle the feature of showing/hiding the grab cursor.
   */
  updateGrabCursor (enabled) {
    const sceneEl = this.el.sceneEl;

    function enableGrabCursor () {
      sceneEl.canvas.classList.add('a-grab-cursor');
    }
    function disableGrabCursor () {
      sceneEl.canvas.classList.remove('a-grab-cursor');
    }

    if (!sceneEl.canvas) {
      if (enabled) {
        sceneEl.addEventListener('render-target-loaded', enableGrabCursor);
      } else {
        sceneEl.addEventListener('render-target-loaded', disableGrabCursor);
      }
      return;
    }

    if (enabled) {
      enableGrabCursor();
      return;
    }
    disableGrabCursor();
  },

  saveYaw () {
    this.savedYaw.copy(this.yawObject.rotation);
    this.hasSavedYaw = true;
  },

  /**
   * Save camera pose before entering VR to restore later if exiting.
   */
  saveCameraPose () {
    const el = this.el;
    const hasPositionalTracking = this.hasPositionalTracking !== undefined
      ? this.hasPositionalTracking
      : checkHasPositionalTracking();

    if (this.hasSavedPose || !hasPositionalTracking) {
      return;
    }

    this.savedPose.position.copy(el.object3D.position);
    this.savedPose.rotation.copy(el.object3D.rotation);
    this.hasSavedPose = true;
  },

  /**
   * Reset camera pose to before entering VR.
   */
  restoreCameraPose () {
    const el = this.el;
    const savedPose = this.savedPose;
    const hasPositionalTracking = this.hasPositionalTracking !== undefined
      ? this.hasPositionalTracking
      : checkHasPositionalTracking();

    if (!this.hasSavedPose || !hasPositionalTracking) {
      return;
    }

    // Reset camera orientation
    el.object3D.position.copy(savedPose.position);
    el.object3D.rotation.copy(savedPose.rotation);
    this.hasSavedPose = false;
  }
});
