import React from 'react';

import { SceneModel } from '../../../Entities';

const Mountain = () => (
  <SceneModel
    id="mountain_model"
    position={{ x: 0.0012, y: 0.005, z: -0.004 }}
    rotation={{ x: 0, y: 180, z: 0 }}
    src="./models/mountain/mountain"
    tint="#7a7a7a"
  />
);

export default Mountain;
