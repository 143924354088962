import { Entity } from 'aframe-react';
import React, { Component } from 'react';

import { checkResolution } from 'react-device-detect';

class Birds extends Component {
  constructor (props) {
    super(props);

    let birdCount = 20;
    if (!checkResolution) {
      birdCount = 10;
    }
    this.state = { count: birdCount };
  }

  render () {
    return (
      <Entity
        id="bird_loader"
        asset-loader={{
          cameras: ['museum_display_box_cam', 'golem_cam', 'golem_head_cam',
            'planetarium_outside_cam', 'stormdrain_lift_cam',
            'lab_outside_cam', 'stormdrain_base_cam_1', 'stormdrain_base_cam_2',
            'stormdrain_pillar_cam', 'bench_cam', 'security_cam']
        }}
      >
        <Entity
          id="birds"
          position={{ x: 0, y: 0.6, z: 0 }}
          birds={{
            count: this.state.count
          }}
        />
      </Entity>
    );
  }
}

export default Birds;
