import AFRAME from 'aframe';

import { isMuted } from 'util';


AFRAME.registerComponent('sound-controls', {
  schema: {
    loader: { type: 'string' },
    sync: { type: 'string' },
    delay: { type: 'number', default: 0 },
    playOnEvent: { type: 'string' }
  },

  init () {
    this.scene = this.el.sceneEl;
    this.object3D = this.el.object3D;

    if(this.data.loader) {
      this.loader = document.querySelector(`#${this.data.loader}`);
    }

    this.delay = this.data.delay;

    this.sound = this.el.components.sound;

    if(this.data.sync) {
      this.syncEl = document.querySelector(`#${this.data.sync}`);
    }

    document.addEventListener('main-models-loaded', this._attachEventListeners.bind(this));
  },

  _attachEventListeners () {
    if(this.loader) {
      this.loader.addEventListener('load-now', this.__onCameraLoad.bind(this), false);
      this.loader.addEventListener('remove', this.__onRemove.bind(this), false);
    } else {
      this.el.sceneEl.addEventListener(this.data.playOnEvent, this.playSound.bind(this));
    }

    document.addEventListener('mute', this.toggleSound.bind(this), false);
  },

  _removeEventListeners () {
    if(this.loader) {
      this.loader.removeEventListener('load-now', this.__onCameraLoad.bind(this), false);
      this.loader.removeEventListener('remove', this.__onRemove.bind(this), false);
    }

    document.removeEventListener('mute', this.toggleSound.bind(this), false);
  },

  toggleSound () {
    if(isMuted()) {
      if(this.sound.isPlaying) {
        this.sound.stopSound();  
      }
    } else if(!this.loader) {
      this.sound.playSound();
    }
  },

  __onCameraLoad () {
    if(this.data.playOnEvent) {
      this.el.sceneEl.addEventListener(this.data.playOnEvent, this.playSound.bind(this));
    } else if(!isMuted()) {
      this.sound.playSound();
    }
  },

  __onRemove () {
    if(this.data.playOnEvent) {
      this.el.sceneEl.removeEventListener(this.data.playOnEvent, this.playSound.bind(this));
    }
    
    if(this.sound.isPlaying) {
      this.sound.stopSound();  
    }
  },

  playSound () {
    if(isMuted()) return;
    if(this.sound.isPlaying) {
      this.sound.stopSound();  
    }
    this.sound.playSound();
  },

  pauseSound () {
    this.sound.pauseSound();
  },

  remove () {
    this._removeEventListeners();
  }
});
