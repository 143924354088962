import React, { Component } from 'react';

class CornerButton extends Component {
  constructor (props) {
    super(props);

    this.el = `${this.props.label.toLowerCase()}_button`;
  }

  handleClick = () => {
    this.props.onClick(this.el);
  }

  render () {
    const flipped = this.props.flip ? ' flipped' : '';
    const active = this.props.active ? ' active' : '';

    const imageSrc = this.props.active ? this.props.toggledSrc : this.props.src;

    let label = this.props.label;
    if (this.props.toggledLabel && this.props.active) label = this.props.toggledLabel;

    return (
      <div
        id={this.el}
        className={`corner-button position-${this.props.position}${flipped}${active}`}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.handleClick}
      >
        <span className="button-label">{label}</span>
        <img
          src={imageSrc}
          alt={`${this.props.label} Icon`}
        />
      </div>
    );
  }
}

export default CornerButton;
