import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('colour-overlay', {
  schema: {
    ySwitch: { type: 'number', default: 0.65 }
  },

  init () {
    this.parent = document.querySelector('#noodle_camera_rig');
    this.rotParent = document.querySelector('#noodle_cam');
    this.loaded = false;
    this._attachEventListeners();
    this.makePlane();
  },

  _attachEventListeners () {
    document.querySelector('a-scene').addEventListener('camera-set-active',
      this.__onCameraActive.bind(this), false);
  },

  makePlane () {
    const planeGeometry = new THREE.PlaneGeometry(2, 2);

    const vertexShader = require('./shaders/vertex.glsl');
    const fragmentShader = require('./shaders/fragment.glsl');

    this.uniforms = {
      uActive: { type: 'f', value: 0.0 }
    };

    const planeMaterial = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader,
      fragmentShader,
      side: THREE.DoubleSide,
      depthWrite: false,
      transparent: true
    });

    this.plane = new THREE.Mesh(planeGeometry, planeMaterial);
    this.plane.frustumCulled = false;
    this.el.object3D.add(this.plane);
  },

  tick () {
    if (this.loaded) {
      if (this.parent.object3D.position.y > this.data.ySwitch) {
        this.plane.rotation.copy(this.rotParent.object3D.rotation);
        this.uniforms.uActive.value = 0.0;
      } else {
        this.uniforms.uActive.value = 0.4;
      }
    }
  },

  __onCameraActive (evt) {
    if (evt.detail.cameraEl.id !== 'noodle_cam') {
      this.uniforms.uActive.value = 0.0;
      this.loaded = false;
    } else {
      this.loaded = true;
    }
  },

  _removeEventListeners () {
    document.querySelector('a-scene').removeEventListener('camera-set-active',
      this.__onCameraActive);
  },

  remove () {
    this._removeEventListener();

    if (this.plane) {
      this.plane.geometry.dispose();
      this.plane.material.dispose();
    }
    this.plane = null;
  }
});
