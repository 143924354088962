import { Entity } from 'aframe-react';
import React, { Component } from 'react';

// DATA
import axios from 'axios';
import config from 'srcDir/config';
import colours from 'srcDir/data/idleColours';

import { forEach, shuffle } from 'lodash';
import { checkResolution } from 'util';
import Image from './Image';
import Gif from './Gif';
import Model from './Model';
import Photosphere from './Photosphere';
import Videosphere from './Videosphere';

import { Macro, SceneModel } from '../../../Entities';

function isGif(fileName) {
  return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase() == 'gif';
}

class Array extends Component {
  constructor (props) {
    super(props);

    this.cameras = props.cameras;
    this.activeCam = '';
    this.lowRes = false;
    this.isSmall = false;

    this.paused = true;
    this.firstLoad = false;
    this.pausedColour = null;
    this.playing = false;

    this.indexing = false;
    this.set = false;
    this.indexedState = false;
    this.outside = false;

    this.state = {
      projectIndex: 0,
      colour: '#335dc4',
      animate: true,
      video: false,
      abstract: '',
      description: ''
    };
  }

  addAnim = () => {
    const arrays = document.querySelector('#image_arrays');

    arrays.setAttribute('animation', {
      property: 'rotation',
      dur: '500',
      easing: 'easeOutCubic',
      loop: false,
      from: '0 30 0',
      to: '0 0 0'
    });
    
    const scene = document.querySelector('a-scene');
    scene.emit('newProject', null);

    setTimeout(() => {
      arrays.removeAttribute('animation');
    }, 1000);
  };
  
  
  // getCaption = () => {
  //   axios.get(`${config.API_URL}/areas/`)
  //     .then((res) => {
  //       const data = res.data;
  // 
  //       const item = data.find((el) => {
  //         return el.name == 'videosphere';
  //       });
  // 
  //       if(!item) return;
  // 
  //       console.log(item.abstract);
  // 
  //       this.setState({
  //         abstract: item.abstract,
  //         description: item.description
  //       });
  //     });
  // };
  
  initProject = (index) => {
    this.addAnim();

    const projects = this.state.projects;
    const project = projects[index];
    
    if (projects.length === 0 || !project ||
        (project.items.length === 0 && !project.photosphere)) return false;
        
    const items = shuffle(project.items).slice(0, 12);
    const photosphere = project.photosphere;
    
    this.count = items.length;
    
    this.setState({
      items: items.map(this.returnPlanetariumItem),
      title: project.title.toUpperCase(),
      abstract: project.abstract,
      description: project.description,
      colour: project.colour,
      photosphere: photosphere
        ? this.returnPlanetariumPhotosphere(photosphere)
        : null
    },
    () => {
      this.set = false;
    });
  }

  nextProject = () => {
    let index = this.state.projectIndex;
    this.initProject(index);

    if (index === this.state.projects.length - 1) {
      index = 0;
    } else {
      index++;
    }

    this.setState({ projectIndex: index });
    
    this.renderProjectTitle();

    if (!this.state.animate) {
      this.setState({ animate: true });
    }
  };
  
  setProject = (newIndex) => {
    this.initProject(newIndex);

    setTimeout(() => this.renderProjectTitle(), 100);
  };

  renderProjectTitle = () => {
    const scene = document.querySelector('a-scene');

    scene.emit('dong', { title: this.state.title, abstract: this.state.abstract, description: this.state.description });
  };

  componentDidMount () {
    const loader = document.querySelector('#planetarium_loader');
    loader.addEventListener('remove', this.pauseColour, false);

    this.getProjects();

    document.addEventListener('getProject', this.__onIndex, false);

    document.querySelector('a-scene')
      .addEventListener('camera-set-active', this.__onCamSwitch, false);

    this.isSmall = checkResolution();

    if (!this.isSmall) {
      document.querySelector('a-scene')
        .addEventListener('lowFPS', this.__onLowFPS, false);
      document.querySelector('a-scene')
        .addEventListener('highFPS', this.__onHighFPS, false);
    }
    
  }

  __onLowFPS = () => {
    this.lowRes = true;
  };

  __onHighFPS = () => {
    this.lowRes = false;
  };

  renderColour = () => {
    clearTimeout(this.colourTimer);
    if (this.paused) {
      let newColour = this.pausedColour;
      let found = false;
      while (!found) {
        newColour = colours[Math.floor(Math.random() * colours.length)];
        if (newColour !== this.pausedColour) {
          found = true;
          this.pausedColour = newColour;
        }
      }

      this.setState({
        colour: newColour
      });

      this.colourTimer = setTimeout(this.renderColour, 3000);
    }
  }

  __onCamSwitch = (evt) => {  
    this.activeCam = evt.detail.cameraEl.id;
    // if change to camera not planetarium_main_cam, reset indexing
    if (this.activeCam === 'planetarium_outside_cam') {
      this.outside = true;
    } else {
      this.outside = false;
    }

    if (this.activeCam === 'planetarium_main_cam_2' ||
        this.activeCam === 'planetarium_index_cam') {
      this.setState({ video: false });
      
      if (!this.indexing) {
        setTimeout(() => this.renderProjectTitle(), 100);
      }
      // if camera is outside or main then show projects
      this.play();
    } else if (this.activeCam === 'planetarium_main_cam_1' ||
               this.activeCam === 'planetarium_outside_cam') {
      this.setState({ 
        video: true,
        abstract: '',
        description: ''
      });
      
      this.indexing = false;
      
      this.pause();
      
      if(this.activeCam === 'planetarium_main_cam_1') {
        setTimeout(() => {
          const scene = document.querySelector('a-scene');
          scene.emit('dong', { title: '', abstract: this.state.abstract, description: this.state.description });
        }, 100);  
      }
    } else {
      if (this.indexing) {
        this.indexedState = false;
        this.indexing = false;
      }

      // if camera not outside/main then pause showing projects
      if (this.state.projects) {
        const index = Math.floor(Math.random() * this.state.projects.length);
        this.setState({ projectIndex: index });
      }
      this.pause();
    }
  };

  __onIndex = (evt) => {
    this.indexing = true;

    clearInterval(this.projectInterval);
    const projects = this.state.projects;

    const indexedTitle = evt.detail.title;

    const index = projects.map(e => e.title).indexOf(indexedTitle);
    
    if (index !== -1) {
      this.setState({ projectIndex: index });
      this.setProject(index);
      this.indexedState = true;
      this.set = true;

      if (!this.set && this.indexedState) {
        if (index !== this.state.projectIndex) {
          this.set = true;
        }
      }
    } else {
      this.indexing = false;
    }
  };

  getProjects = () => {
    axios.get(`${config.API_URL}/project`)
      .then((res) => {
        let projects = [];

        forEach(res.data, (val) => {
          const obj = {
            title: val.title,
            colour: val.colour,
            items: [],
            abstract: val.abstract,
            description: val.description,
            photosphere: val.photosphere,
            enabled: val.enabled
          };
          
          if ((val.images.length === 0 && !val.photosphere) || !val.enabled) return;

          forEach(val.images, (img) => {
            if(img['512'] !== undefined) {
              obj.items.push({
                id: img.id,
                originalUrl: config.API_URL + img['url'],
                url: config.API_URL + img['512'],
                smallUrl: config.API_URL + img['256'],
                isModel: false,
                width: img.width,
                height: img.height
              });
            }
          });
          
          projects.push(obj);
        });

        projects = shuffle(projects);
        
        this.setState({ projects });
      });
  };

  play = () => {
    clearInterval(this.colourTimer);
    this.setState({ animate: false });
    this.firstLoad = false;
    this.paused = false;

    if (!this.indexing && !this.playing) {
      clearInterval(this.projectInterval);
      this.indexedState = false;
      this.playing = true;
      if (this.state.projects) {
        this.nextProject();

        this.projectInterval = setInterval(() => {
          this.nextProject();
        }, 10000);
      }
    } else if (!this.set && !this.outside && !this.playing) {
      this.indexedState = true;
    }
  };

  pause = () => {
    clearInterval(this.projectInterval);
    this.setState({ animate: true });

    this.paused = true;
    this.playing = false;
    if (!this.firstLoad) {
      this.firstLoad = true;
      setTimeout(this.renderColour, 275);
    } else {
      this.renderColour();
    }

    this.setState({ items: null });
  };

  returnPlanetariumItem = (item, i) => {
    if (item.isModel) {
      return this.returnPlanetariumModel(item, i);
    } else if (isGif(item.originalUrl)) {
      return this.returnPlanetariumGif(item, i);
    } else {
      return this.returnPlanetariumImage(item, i);
    }
  };

  returnPlanetariumImage = (item, i) => {
    const count = this.count ? this.count : 7;

    const imageScale = Math.max(1.75, Math.min(2.5, 1.75 * (10 / count)));

    let source = item.url;
    if (this.lowRes) {
      source = item.smallUrl;
    }

    return (
      <Image
        key={item.id}
        src={source}
        scale={imageScale}
        width={item.width}
        height={item.height}
        textureWidth={
          item.textureWidth !== undefined ? item.textureWidth : 1024
        }
        textureHeight={
          item.textureHeight !== undefined ? item.textureHeight : 1024
        }
        offset={(i + 1) / count}
        rad={(i + 1) * (2 * Math.PI) / count}
        index={i}
      />
    );
  };
  
  returnPlanetariumGif = (item, i) => {
    const count = this.count ? this.count : 7;
    const imageScale = Math.max(1.75, Math.min(2.5, 1.75 * (10 / count)));

    let source = item.originalUrl;

    return (
      <Gif
        key={item.id}
        src={source}
        scale={imageScale}
        width={item.width}
        height={item.height}
        offset={(i + 1) / count}
        rad={(i + 1) * (2 * Math.PI) / count}
        index={i}
      />
    );
  };

  returnPlanetariumModel = (item, i) => (
    <Model
      key={`${item.id}_${i}`}
      src={item.url}
      offset={(i + 1) / this.state.imageCount}
      rad={(i + 1) * (2 * Math.PI) / this.state.imageCount}
      index={i}
      rotation={item.rotation}
    />
  );

  returnPlanetariumPhotosphere = (photosphere) => {
    if (!photosphere.image) return;

    return (
      <Photosphere
        src={config.API_URL + photosphere.image['large-photosphere']}
      />
    );
  };

  render () {
    const hasVideo = this.state.video;

    return (
      <Entity>
        <SceneModel
          id="planetarium_model"
          src="./models/planetarium/planetarium"
          rotation={{ x: 0, y: 180, z: 0 }}
          tint={this.state.colour}
          animate={this.state.animate}
        />

        <Macro
          position={{ x: 0, y: -0.007, z: 0 }}
          rotation={{ x: 0, y: -45, z: 0 }}
        >

          <Entity id="image_arrays" position={{ x: 0, y: 1.25, z: 0 }}>
            <Entity
              id="planetarium_loader"
              asset-loader={{
                cameras: ['planetarium_outside_cam', 'planetarium_main_cam_1', 'planetarium_main_cam_2', 'planetarium_index_cam']
              }}
            >
              <Videosphere isPlaying={hasVideo}/>
              
              { hasVideo
                ? ""
                : (
                  this.state.photosphere,
                  this.state.items
                )
              }
            </Entity>
          </Entity>

        </Macro>
      </Entity>
    );
  }
}

export default Array;
