import { Entity } from 'aframe-react';
import React, { Component } from 'react';

import config from 'srcDir/config';

import { forEach } from 'lodash';

class Model extends Component {
  constructor (props) {
    super(props);

    this.state = {
      url: ''
    };
  }

  componentDidMount () {
    forEach(this.props.asset.Object, (asset) => {
      if (asset.ext !== '.gltf') return;
      this.setState({ url: config.API_URL + asset.url });
    });
  }

  render () {
    return (
      <Entity
        gltf-model={this.state.url}
        parent-bone={this.props.attachTo}
        scale-bone={this.props.scale}
      />
    );
  }
}

export default Model;
