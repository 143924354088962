import { Entity } from 'aframe-react';
import React from 'react';

// Micro scenes
import {
  Lab, Planetarium, StormDrain, Mountain, Billboard, Birds
} from '..';

const DisplayCase = () => (
  <Entity
    id="display_case"
    position={{ x: -0.476, y: 0.844, z: -5.553 }}
    rotation={{ x: 0, y: -90, z: 0 }}
  >
  
    <Entity
      id="display_case_loader"
      asset-loader={{
        cameras: ['planetarium_outside_cam', 'stormdrain_lift_cam', 'lab_outside_cam']
      }}
    />
      <Mountain />
      <Birds />
      <Lab />
      <Planetarium />
      <StormDrain />
      <Billboard />
      
      <Entity
          id="bird_audio"
          primitive="a-sound"
          position={{x: 0, y: -4, z: -20}}
          sound={{
            src: 'url(./audio/Stage1_amb_bird.mp3)',
            loop: true,
            volume: 1,
            maxDistance: 10,
            refDistance: 1,
            rolloffFactor: .5
          }}
          sound-controls={{
            loader: 'display_case_loader',
          }}
      />
  </Entity>
);

export default DisplayCase;
