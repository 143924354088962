import AFRAME, { THREE } from 'aframe';

const vertShader = require('./shaders/vertex.glsl');
const fragShader = require('./shaders/fragment.glsl');
const eyeVertShader = require('./shaders/eye_vertex.glsl');
const eyeFragShader = require('./shaders/eye_fragment.glsl');

const warn = AFRAME.utils.debug('components:gltf-model:warn');

AFRAME.registerComponent('head-shading', {
  multiple: true,
  schema: {
    model: { type: 'model' },

    modelDepth: { type: 'number', default: 0.0 },
    offset: { type: 'number', default: 0.0 },
    noseOffset: { type: 'number', default: 0.0 },

    colorA: { type: 'color', default: '' },
    colorB: { type: 'color', default: '' },
    colorC: { type: 'color', default: '' },

    axis: { type: 'int', default: 0 },

    cameras: { type: 'array', default: [] }
  },

  init () {
    this.model = null;
    this.modelLoader = new THREE.GLTFLoader();

    this.blinkTimer = 0.0;
    this.animation = false;
    this.eyeChangeTimer = 0.0;
    this.eyeCalls = 0;
    this.blinkDuration = this.targetTime = 4000.0;
    this.targetBlinkTime = 20.0;

    this.animate = false;
    this.rotation = false;
    this.rotObject = null;

    const self = this;
    const el = this.el;
    const src = this.data.model;

    this.modelLoader.load(src,
      (gltfModel) => {
        self.model = gltfModel.scene || gltfModel.scenes[0];
        self.model.animations = gltfModel.animations;
        el.setObject3D('mesh', self.model);
        el.emit('model-loaded', { format: 'gltf', model: self.model });

        self.setMaterial(self.model);
        self.checkForAnim(self.model);
        self.loaded = true;
        self._attachEventListeners();
      },

      undefined,

      (error) => {
        const message = error && error.message ? error.message : 'Failed to load glTF model';
        warn(message);
        el.emit('model-error', { format: 'gltf', src });
      });
  },

  checkForAnim (model) {
    const self = this;
    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (child.name === 'Balls') {
          self.rotation = true;
          self.rotObject = child;
        }
      }
    });
  },

  _attachEventListeners () {
    document.querySelector('a-scene').addEventListener('camera-set-active',
      this.__onCameraActive.bind(this), false);
  },

  __onCameraActive (evt) {
    for (let i = 0; i < this.data.cameras.length; i++) {
      if (evt.detail.cameraEl.id === this.data.cameras[i]) {
        this.animate = true;
        return;
      }
    }
    if (this.animate) {
      this.animate = false;
    }
  },

  update () {
    if (this.loaded) {
      this.material.uniforms.uColorA.value = new THREE.Color(this.data.colorA);
      this.material.uniforms.uColorB.value = new THREE.Color(this.data.colorB);
      this.offsetMaterial.uniforms.uColorB.value = new THREE.Color(this.data.colorB);

      if (this.data.colorC !== '') {
        this.material.uniforms.uColorC.value = new THREE.Color(this.data.colorC);
        this.offsetMaterial.uniforms.uColorC.value = new THREE.Color(this.data.colorC);
      }

      this.material.uniforms.uOffset.value = this.data.offset;
      this.material.uniforms.uLength.value = this.data.modelDepth;
      this.material.uniforms.uAxis.value = this.data.axis;

      this.offsetMaterial.uniforms.uOffset.value = this.data.offset + this.data.noseOffset;

      this.offsetMaterial.needsUpdate = true;
      this.material.needsUpdate = true;
    }
  },

  setMaterial (model) {
    let colorC = new THREE.Color('#ffffff');
    let noColors = 2;

    if (this.data.colorC !== '') {
      colorC = new THREE.Color(this.data.colorC);
      noColors = 3;
    }

    this.material = new THREE.ShaderMaterial({
      uniforms: {
        uOffset: { type: 'f', value: this.data.offset },
        uLength: { type: 'f', value: this.data.modelDepth },
        uColorA: { type: 'c', value: new THREE.Color(this.data.colorA) },
        uColorB: { type: 'c', value: new THREE.Color(this.data.colorB) },
        uColorC: { type: 'c', value: colorC },
        uAxis: { type: 'i', value: this.data.axis },
        uNoColors: { type: 'i', value: noColors }
      },
      vertexShader: vertShader,
      fragmentShader: fragShader,
      side: THREE.DoubleSide
    });

    const self = this;

    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (child.name === 'Nose') {
          self.offsetMaterial = self.material.clone();
          self.offsetMaterial.uniforms.uOffset.value = self.data.offset + self.data.noseOffset;

          child.material = self.offsetMaterial;
        } else if (child.name === 'Eyes') {
          const texture = new THREE.TextureLoader().load('./img/people/eye-sprites.png');
          self.eyeMaterial = new THREE.ShaderMaterial({
            uniforms: {
              uTime: { type: 'f', value: 0 },
              uOffset: { type: 'f', value: 0 },
              tEyeSprites: { type: 't', value: texture }
            },
            vertexShader: eyeVertShader,
            fragmentShader: eyeFragShader,
            transparent: true
          });
          child.material = self.eyeMaterial;
        } else {
          child.material = self.material;
        }
      }
    });
  },

  tick (t) {
    if (this.loaded && this.rotation) {
      this.rotObject.rotateZ(0.003);
    }
    if (this.loaded && this.animate) {
      if (!this.animation) {
        this.blinkTimer = t;
        if (this.blinkTimer > this.targetTime) {
          this.targetTime = t + this.blinkDuration;
          this.animation = true;
        }
      } else {
        this.eyeChangeTimer = t;
        if (this.eyeChangeTimer > this.targetBlinkTime) {
          this.targetBlinkTime = this.eyeChangeTimer + 20;
          this.eyeMaterial.uniforms.uOffset.value += 1;
          this.eyeCalls++;
        }
        if (this.eyeCalls > 5) {
          this.eyeCalls = 0;
          this.eyeMaterial.uniforms.uOffset.value = 0;
          this.animation = false;
          this.blinkDuration = Math.random() * 2000 + 1500;
        }
      }
    }
  },

  _removeEventListeners () {
    this.el.sceneEl.addEventListener('camera-set-active',
      this.__onCameraActive, false);
  },

  remove () {
    this._removeEventListeners();

    if (this.eyeMaterial.uniforms) this.eyeMaterial.uniforms.tEyeSprites.value.dispose();
    if (this.eyeMaterial) this.eyeMaterial.dispose();
    if (this.material) this.material.dispose();

    this.eyeMaterial = null;
    this.material = null;
  }
});
