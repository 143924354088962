import 'aframe';

import '../../lib';
import 'aframe-extras';
import 'aframe-look-at-component';

import React, { Component } from 'react';

// Micro scenes
import {
  DisplayCase, Golem, Museum, Noodle, Newspaper, AudioAssets
} from './Scenes';

import { Scene, Controls } from './Entities';

class VRScene extends Component {
  render () {
    return (
      <Scene>
        <AudioAssets />
        
        <Golem />
        <Museum />
        <DisplayCase />
        <Newspaper />
        <Noodle />

        <Controls />
      </Scene>
    );
  }
}

export default VRScene;
