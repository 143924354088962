import React, { Component } from 'react';

class Scene extends Component {
  constructor (props) {
    super(props);
  }
  
  shouldComponentUpdate() {
    return false;
  }

  render () {
    return (
      <a-scene
        camera-switcher
        playback
        recorder
        light="defaultLightsEnabled: false"
        id="main"
        init-loader
        renderer="maxCanvasWidth: 1920; maxCanvasHeight: 1920; antialias: true"
      >
        {this.props.children}
      </a-scene>
    );
  }
}

export default Scene;
