import AFRAME, { THREE } from 'aframe';

const vertShader = require('./shaders/vertex.glsl');
const fragShader = require('./shaders/fragment.glsl');

AFRAME.registerComponent('blob', {
  schema: {
    tint: { type: 'color', default: 'blue' },
    parentLoader: { type: 'string', default: '#blob_loader' },
    mix: { type: 'boolean', default: true }
  },

  init () {
    this.loader = document.querySelector(this.data.parentLoader);

    this.setDataVariables();
    this.setMaterial();

    const self = this;
    this.el.addEventListener('loaded', () => {
      self.attachEventListeners();
    });
  },

  attachEventListeners () {
    this.el.addEventListener('model-loaded', this.onModelLoad.bind(this), false);
    document.querySelector('a-scene')
      .addEventListener('camera-set-active', this.onCameraSwitch.bind(this), false);
    this.loader.addEventListener('load-now', this.onCameraLoad.bind(this), false);
    this.loader.addEventListener('remove', this.onRemove.bind(this), false);
  },

  update () {
    this.color01 = new THREE.Color(this.data.tint);
    if (this.data.mix) {
      this.colorAnimation = true;
      this.pulseAnimation = true;
      this.firstPulseCall = true;
      this.firstColorCall = true;
    } else {
      this.glsl_material.uniforms.uColor01.value = this.glsl_material.uniforms.uColor02.value;
      this.glsl_material.uniforms.uColor02.value = this.color01;
    }
  },

  onModelLoad () {
    this.el.setAttribute('loaded', true);
    const self = this;
    const model = this.el.children[0].components['gltf-model'].model;
    model.traverse((child) => {
      if (child instanceof THREE.Mesh) child.material = self.glsl_material;
    });
  },

  onCameraSwitch (evt) {
    if (evt.detail.cameraEl.id === 'blob_cam') {
      this.el.setAttribute('visible', false);
      this.blobCam = true;
    } else if (this.el.getAttribute('visible') === false) {
      this.el.setAttribute('visible', true);
      this.blobCam = false;
    }
  },

  onCameraLoad () {
    if (!this.loaded) {
      this.loaded = true;
    }
  },

  onRemove () {
    if (this.loaded && !this.blobCam) {
      this.loaded = false;
    }
  },

  setDataVariables () {
    this.colorSpeed = 300;
    this.pulseSpeed = 1000;

    this.colorTimer = 0.0;
    this.pulseTimer = 0.0;

    this.firstPulseCall = false;
    this.firstColorCall = false;

    this.trigger = false;
    this.colorAnimation = false;
    this.pulseAnimation = false;

    this.loaded = false;
    this.blobCam = false;

    this.color01 = new THREE.Color(this.data.tint);
  },

  setMaterial () {
    const uniforms = {
      uTime: { type: 'f', value: 0.0 },
      uColorSpeed: { type: 'f', value: 4 },
      uColorTimer: { type: 'f', value: 0.0 },
      uPulseSpeed: { type: 'f', value: 8 },
      uPulseTimer: { type: 'f', value: 0.0 },
      uColor01: { type: 'c', value: this.color01 },
      uColor02: { type: 'c', value: this.color01 }
    };

    this.glsl_material = new THREE.ShaderMaterial({
      uniforms,
      vertexShader: vertShader,
      fragmentShader: fragShader,
      depthTest: true,
      transparent: true,
      vertexColors: true
    });
  },

  animateColour (t) {
    const speed = this.glsl_material.uniforms.uColorSpeed.value;

    if (this.firstColorCall && this.loaded) {
      this.colorStart = t;
      this.colorTimer = t + this.colorSpeed;
      this.firstColorCall = false;
      this.glsl_material.uniforms.uColor01.value = this.glsl_material.uniforms.uColor02.value;
      this.glsl_material.uniforms.uColor02.value = this.color01;
    }

    if (t > this.colorTimer) {
      this.colorAnimation = false;
      this.glsl_material.uniforms.uColorTimer.value = speed;
      return;
    }

    if (this.loaded) {
      this.glsl_material.uniforms.uColorTimer.value = (t - this.colorStart) / this.colorSpeed * speed;
    }
  },

  animatePulse (t) {
    const speed = this.glsl_material.uniforms.uPulseSpeed.value;

    if (this.firstPulseCall) {
      this.pulseStart = t;
      this.pulseTimer = t + this.pulseSpeed;
      this.firstPulseCall = false;
    }

    if (t > this.pulseTimer) {
      this.pulseAnimation = false;
      return;
    }

    if (this.loaded) {
      this.glsl_material.uniforms.uPulseTimer.value = (t - this.pulseStart) / this.pulseSpeed * speed;
    }
  },

  tick (t) {
    if(!this.loaded) return;
    
    this.glsl_material.uniforms.uTime.value = t * 0.001;
    
    if (this.colorAnimation) {
      const self = this;
      setTimeout(() => {
        self.animateColour(t);
      }, 500);
    }
    if (this.pulseAnimation) {
      this.animatePulse(t);
    }    
  },

  removeEventListeners () {
    this.el.removeEventListener('model-loaded', this.__onModelLoad, false);

    this.loader.removeEventListener('load-now', this.__onCameraLoad, false);
    this.loader.removeEventListener('remove', this.__onRemove, false);
  },

  remove () {
    this._removeEventListeners();

    if (this.glsl_material) this.glsl_material.dispose();
    this.glsl_material = null;
  }
});
