import React from 'react';

const About = (props) => {
  const visible = props.visible ? ' visible' : '';

  return (
    <div className={`about-overlay overlay${visible}`}>
      <div className="about-overlay-text-wrapper">
        <p>{ "Amongst 8 billion souls, we found each other through serendipity in this cosmic isolation. Zoo is a place where different cultures mash together to form something new. We want to set new rules, speak our own language, experiment with processes, find beauty in chaos. With strong individual artistic positions, we come together to create our own world — one that's honest and true to the human experience, dark reality, the South, the youth and everything in between." }</p>
        <p>{ "Zoo is a creative outlet for us to seek meaning." }</p>
        <p className="p-half-space"><img src="./img/ui/icons/mail-icon.svg" alt="Contact Icon" className="about-inline-icon"/><a href="mailto:zoo@zooaszoo.com">{ "zoo@zooaszoo.com" }</a></p>
        <p><img src="./img/ui/icons/instagram-icon.svg" alt="Instagram Icon" className="about-inline-icon"/><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/zoo_as_zoo">{ "@zoo_as_zoo" }</a></p>
      </div>
    </div>
  );
};

export default About;
