import React, { Component } from 'react';
import { Entity } from 'aframe-react';

import { checkResolution } from 'util';

class SceneModel extends Component {
  constructor (props) {
    super(props);

    this.isSmall = false;

    this.state = {
      suffix: ''
    };
  }

  componentDidMount () {
    this.isSmall = checkResolution();
    
    const suffix = this.isSmall ? '-mobile' : '';
    
    this.setState({ suffix });

    if (!this.isSmall) {
      document.querySelector('a-scene').addEventListener('lowFPS', this.__onLowFPS.bind(this), false);
      document.querySelector('a-scene').addEventListener('highFPS', this.__onHighFPS.bind(this), false);
    }
  }

  __onLowFPS = () => {
    this.setState({ suffix: '-mobile' });
  };

  __onHighFPS = () => {
    this.setState({ suffix: '' });
  };

  render () {
    return (
      <Entity
        unlit-gltf={{
          model: `${this.props.src + this.state.suffix}.gltf`,
          tint: this.props.tint ? this.props.tint : '#bec9d3',
          tintB: this.props.tintB ? this.props.tintB : 'black',
          offset: this.props.offset ? this.props.offset : 0.0,
          targetX: this.props.targetX ? this.props.targetX : 50.0,
          targetY: this.props.targetY ? this.props.targetY : 50.0,
          blend: this.props.blend ? this.props.blend : 'multiply',
          animate: this.props.animate ? this.props.animate : false
        }}
        {...this.props}
      />
    );
  }
}

export default SceneModel;
