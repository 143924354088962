import { Entity } from 'aframe-react';
import React from 'react';

const Frame = (props) => {
  let imageUrl = '';
  if (props.image) {
    imageUrl = props.smaller ? props.image.smallUrl : props.image.url;
  }

  return (
    <Entity
      position={props.position}
      scale={{ x: 2.99, y: 2.99, z: 1 }}
      rotation={{ x: 0, y: 90, z: 0 }}
      portfolio-image={{
        parentLoader: '#frame_loader',
        source: imageUrl,
        width: 1,
        height: 1.25,
        textureWidth: '1024',
        textureHeight: '1024',
        lazyLoad: 'false'
      }}
    />
  );
};

export default Frame;
