import React from 'react';
import { Entity } from 'aframe-react';

const Blob = props => (
  <Entity
    id="blob_loader"
    asset-loader={{
      cameras: ['lab_in_cam', 'lab_outside_cam']
    }}
  >
    <Entity
      id="blob_model"
      position={{ x: 0, y: 2.5, z: -1 }}
      rotation={{ x: 0, y: 0, z: 0 }}
      scale={{ x: 0.75, y: 0.75, z: 0.75 }}
      blob={{
        tint: props.colour,
        mix: props.mix
      }}
    >
      <a-entity
        gltf-model="url(./models/blob/blob.gltf);"
      />
    </Entity>
  </Entity>
);

export default Blob;
