import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('loading-anim', {
  schema: {
    width: { type: 'number', default: 0 },
    height: { type: 'number', default: 0 },
    zPos: { type: 'number', default: 0.1 },
    loaded: { type: 'bool', default: true }
  },

  init () {
    this.createSquare();
  },

  createSquare () {
    const w = this.data.width;
    const h = this.data.height;

    let scale = this.data.width;

    if (scale > this.data.height) scale = this.data.height;

    const ratio = w / h;

    const geometry = new THREE.PlaneBufferGeometry(
      0.25 * scale * ratio,
      0.25 * scale * ratio,
      2,
      2
    );

    this.uniforms = {
      uTime: { type: 'f', value: 0.0 }
    };

    let material = null;

    if (!this.data.loaded) {
      const texture = new THREE.TextureLoader().load('./img/utility/broken_image_link.png');
      material = new THREE.MeshBasicMaterial({ map: texture });
    } else {
      const vertShader = require('./shaders/vertex.glsl');
      const fragShader = require('./shaders/fragment.glsl');

      material = new THREE.ShaderMaterial({
        uniforms: this.uniforms,
        vertexShader: vertShader,
        fragmentShader: fragShader,
        side: THREE.DoubleSide
      });
    }

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.position.setZ(this.data.zPos);

    this.el.object3D.add(this.mesh);
  },

  tick (t) {
    if (this.data.loaded) {
      this.uniforms.uTime.value = t * 0.0015;
    }
  },

  remove () {
    if (this.mesh) {
      this.mesh.geometry.dispose();
      if (this.mesh.material.map) this.mesh.material.map.dispose();
      this.mesh.material.dispose();
      this.el.object3D.remove(this.mesh);
    }
    this.mesh = null;
  }
});
