import { Entity } from 'aframe-react';
import React from 'react';

const FloatingImage = (props) => {
  const aspectRatio = props.image.width / props.image.height;

  let imageUrl = '';
  if (props.image) {
    imageUrl = props.smaller ? props.image.smallUrl : props.image.url;
  }

  return (
    <Entity
      position={props.position}
      rotation={props.rotation}
      animation={{
        property: 'position',
        to: props.to,
        dur: '4000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      portfolio-image={{
        parentLoader: '#noodle_loader',
        width: 0.05 * aspectRatio,
        height: 0.05,
        zLoadingPos: 0.001,
        source: imageUrl
      }}
    />
  );
};

export default FloatingImage;
