import { Entity } from 'aframe-react';
import React from 'react';

// Entities
import { SceneModel } from '../../../Entities';

const SecondaryCases = () => (
  <Entity
    id="secondary_case_items"
    position={{ x: 11.462, y: 1.4, z: -4.975 }}
  >
    <SceneModel
      src="./models/cases/aigo"
      position={{ x: 7.299, y: 0, z: -15.396 }}
      scale={{ x: 2, y: 2, z: 2 }}
      tint="#ffffff"
      prop-rotate
    />

    <SceneModel
      src="./models/cases/butterflys"
      position={{ x: 2.732, y: 0, z: -15.396 }}
      scale={{ x: 2, y: 2, z: 2 }}
      tint="#ffffff"
      prop-rotate
    />

    <SceneModel
      src="./models/cases/takeaway"
      position={{ x: -1.648, y: -0.307, z: -15.396 }}
      scale={{ x: 2, y: 2, z: 2 }}
      tint="#ffffff"
      prop-rotate
    />

    <SceneModel
      src="./models/cases/hot-sauce"
      position={{ x: -5.891, y: 0, z: -15.396 }}
      scale={{ x: 2, y: 2, z: 2 }}
      tint="#ffffff"
      prop-rotate
    />

    <SceneModel
      src="./models/cases/eye"
      position={{ x: 7.299, y: 0, z: 14.289 }}
      scale={{ x: 2, y: 2, z: 2 }}
      tint="#ffffff"
      prop-rotate={{
        invert: true
      }}
    />

    <SceneModel
      src="./models/cases/fish"
      position={{ x: 2.732, y: 0, z: 14.289 }}
      scale={{ x: 2, y: 2, z: 2 }}
      tint="#ffffff"
      prop-rotate={{
        invert: true
      }}
    />

    <SceneModel
      src="./models/cases/macaroni"
      position={{ x: -1.648, y: -0.307, z: 14.289 }}
      scale={{ x: 2, y: 2, z: 2 }}
      tint="#ffffff"
      prop-rotate={{
        invert: true
      }}
    />

    <SceneModel
      src="./models/cases/nakamura"
      position={{ x: -5.891, y: 0, z: 14.289 }}
      scale={{ x: 2, y: 2, z: 2 }}
      tint="#ffffff"
      prop-rotate={{
        invert: true
      }}
    />
  </Entity>
);

export default SecondaryCases;
