import { Entity } from 'aframe-react';
import React from 'react';

import Array from './Array';

// Entities
import { Macro, Camera } from '../../../Entities';

const Planetarium = () => (
  <Entity
    id="planetarium"
    position={{ x: 0.222, y: 0.344, z: 0.194 }}
  >

    <Entity
      id="planetarium_screen_model"
      unlit-gltf="model: /models/planetarium/planetarium-screen.gltf"
      rotation="0 180 0"
      blend="none"
    />

    <Array
      cameras={['planetarium_outside_cam', 'planetarium_main_cam_1', 'planetarium_main_cam_2', 'planetarium_index_cam']}
    />

    <Macro
      position={{ x: 0, y: -0.007, z: 0 }}
      rotation={{ x: 0, y: -45, z: 0 }}
    >

      <Camera
        position={{ x: 2.838, y: -7.366, z: 14.641 }}
        rotation={{ x: 0, y: 210, z: 0 }}
        id="planetarium_outside_cam"
        macro
        cameras={['museum_display_box_cam', 'planetarium_main_cam_1', 'planetarium_main_cam_2']}
      />

      <Entity
        id="planetarium_center"
        position={{ x: 0, y: 0.5, z: 0 }}
      />

      <Camera
        id="planetarium_main_cam_1"
        position={{ x: 0, y: 0, z: 0 }}
        macro
        far={80}
        cameras={['planetarium_outside_cam', 'planetarium_main_cam_2']}
      />

      <Camera
        id="planetarium_main_cam_2"
        avatar={false}
        position={{ x: -1.2, y: 0, z: 0 }}
        macro
        far={80}
        cameras={['planetarium_outside_cam', 'planetarium_main_cam_1']}
      />

      <Entity
          id="planetarium_audio_1"
          primitive="a-sound"
          position={{x: 0, y: 0, z: 0}}
          sound={{
            src: 'url(./audio/Stage2_obj001.mp3)',
            volume: 1,
            poolSize: 5
          }}
          sound-controls={{
            loader: 'planetarium_loader',
            playOnEvent: "newProject"
          }}
      />

      <Entity
        rotation={{ x: 10, y: 0, z: 0 }}
        position={{ x: 0, y: 0, z: 0 }}
        animation={{
          property: 'rotation',
          to: '10 360 0',
          dur: '60000',
          loop: 'true',
          easing: 'linear'
        }}
      >
        <Entity
          id="planetarium_index_cam"
          camera={{
            fov: 90,
            near: 0.25,
            far: 100
          }}
          primitive="a-camera"
          look-controls={{ enabled: true }}
          wasd-controls={{ enabled: false }}
        />
      </Entity>
    </Macro>
  </Entity>
);

export default Planetarium;
