import AFRAME, { THREE } from 'aframe';

import { isMobile } from 'react-device-detect';

AFRAME.registerComponent('capture-replayer', {
  // Allow on multiple elements
  multiple: true,

  init () {
    this.currentPoseTime = 0;
    this.currentPoseIndex = 0;
    this._attachEventListeners();
    this.discardedFrames = 0;
    this.playingPoses = [];
    this.on = false;
    this.magicWindowEnabled = false;
    
    this.checkDeviceOrientationPermission();    
  },

  _attachEventListeners () {
    this.el.addEventListener('pause', this.playComponent.bind(this), false);
  },

  _removeEventListeners () {
    this.el.removeEventListener('pause', this.playComponent);
  },
  
  remove () {
    this._removeEventListeners();
    this.stopReplaying();
  },

  playComponent () {
    this.el.isPlaying = true;
    this.play();
  },
  
  checkDeviceOrientationPermission () {
    if (isMobile) {
      if (typeof DeviceOrientationEvent !== 'undefined' && DeviceOrientationEvent.requestPermission) {
        this.magicWindowEnabled = false;
        if (this.el.sceneEl.components['device-orientation-permission-ui'].permissionGranted) {
          this.magicWindowEnabled = true;
        } else {
          this.el.sceneEl.addEventListener('deviceorientationpermissiongranted', () => {
            this.magicWindowEnabled = true;
          });
        }
      }
    }
  },

  /**
   * @param {object} data - Recording data.
   */
  startReplaying (data, on) {
    if (on && isMobile) {
      this.el.components['look-controls'].play();
      this.el.components['look-controls-alt'].play();
    }
    this.ignoredFrames = 0;
    this.storeInitialPose();
    this.startReplayingPoses(data.poses);
    this.on = this.el.id === this.el.sceneEl.camera.el.id;
    this.play();
  },

  stopReplaying () {
    this.isReplaying = false;
    this.restoreInitialPose();
  },

  storeInitialPose () {
    const el = this.el;
    this.initialPose = {
      position: new THREE.Vector3(),
      rotation: new THREE.Vector3()
    };

    this.initialPose.position.copy(el.object3D.position);
    this.initialPose.rotation.copy(el.object3D.rotation);
  },

  restoreInitialPose () {
    const el = this.el;
    if (!this.initialPose) {
      return;
    }
    el.setAttribute('position', this.initialPose.position);
    el.setAttribute('rotation', this.initialPose.rotation);
  },

  startReplayingPoses (poses) {
    this.isReplaying = true;
    if (poses.length === 0) {
      return;
    }

    this.currentPoseIndex = 0;
    
    let newPoses = [];
    for (let i = 0; i < poses.length; i = i+2) {
      newPoses.push(poses[i]);
    }

    this.playingPoses = newPoses;
    this.currentPoseTime = newPoses[0].timestamp;
  },

  /**
   * Called on tick.
   */
  playRecording (delta) {
    let currentPose;

    const playingPoses = this.playingPoses;
    currentPose = playingPoses && playingPoses[this.currentPoseIndex];

    this.currentPoseTime += delta / 1.75;

    if (currentPose !== undefined) {
      while (currentPose.timestamp < this.currentPoseTime) {
        this.currentPoseIndex += 1;
        currentPose = playingPoses[this.currentPoseIndex];

        if (this.currentPoseIndex === playingPoses.length - 1) {
          this.resetRecording();
        }
      }

      const interpolatedPose = this.getInterpolatedPose(currentPose, playingPoses);
      this.applyPose(interpolatedPose);
    }
  },
  
  getInterpolatedPose (currentPose, playingPoses) {
    const interpolatedPose = {};

    if (this.currentPoseIndex > 0) {
      const prev = this.currentPoseIndex - 1;

      const currentRotation = this.toQuaternion(currentPose.rotation);
      const previousRotation = this.toQuaternion(playingPoses[prev].rotation);
      
      const currentPosition = this.toVec(currentPose.position);
      const previousPosition = this.toVec(playingPoses[prev].position);

      const ratio = (this.currentPoseTime - playingPoses[prev].timestamp) /
        (currentPose.timestamp - playingPoses[prev].timestamp);

      interpolatedPose.rotation = previousRotation.slerp(
        currentRotation,
        ratio
      );

      interpolatedPose.position = previousPosition.lerp(
        currentPosition,
        ratio
      );
    }
    
    return interpolatedPose;
  },

  toQuaternion (arr) {
    return new THREE.Quaternion(arr._x, arr._y, arr._z, arr._w);
  },

  toVec (arr) {
    return new THREE.Vector3(arr.x, arr.y, arr.z);
  },

  resetRecording () {
    this.currentPoseIndex = 0;

    if (this.playingPoses.length) {
      this.currentPoseTime = this.playingPoses[0].timestamp;
    }
  },

  tick (time, delta) {
    // Ignore the first couple of frames that come from window.RAF on Firefox.
    // if (this.ignoredFrames !== 2 && !window.debug) {
    //   this.ignoredFrames++;
    //   return;
    // }

    if (!this.isReplaying) {
      return;
    }
    this.playRecording(delta);
  },
    
  applyPose (pose) {
    const obj = this.el.object3D;

    if (pose.position) obj.position.copy(pose.position);
        
    if (pose.rotation) {
      // If on mobile and no magic window, copy rotation
      // If not on mobile, copy rotation
      // If on mobile and not the current camera, copy rotation
      
      if(!isMobile || (isMobile && !this.on) || (isMobile && !this.magicWindowEnabled)) {
         obj.quaternion.copy(pose.rotation);
       }
    }
    
    obj.matrixAutoUpdate = false;
    obj.updateMatrix();
  }
});
