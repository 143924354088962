import { Entity } from 'aframe-react';
import React from 'react';

const Vegetables = () => (
  <Entity id="veggies">

    <Entity
      id="corn1"
      position={{ x: -3.05, y: 0.649, z: 2.504 }}
      rotation={{ x: 0, y: 0, z: 0 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-3.05 0.651 2.504',
        dur: '4000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      unlit-gltf={{
        model: './models/vegetables/noodle-ingredient-sweetcorn.gltf',
        blend: 'none'
      }}
    />

    <Entity
      id="corn2"
      position={{ x: -2.99, y: 0.649, z: 2.535 }}
      rotation={{ x: 10, y: -30, z: 15 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-2.99 0.651 2.535',
        dur: '4000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      unlit-gltf={{
        model: './models/vegetables/noodle-ingredient-sweetcorn.gltf',
        blend: 'none'
      }}
    />

    <Entity
      id="corn3"
      position={{ x: -2.96, y: 0.649, z: 2.554 }}
      rotation={{ x: 30, y: 160, z: 60 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-2.96 0.651 2.554',
        dur: '4000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      unlit-gltf={{
        model: './models/vegetables/noodle-ingredient-sweetcorn.gltf',
        blend: 'none'
      }}
    />

    <Entity
      id="corn4"
      position={{ x: -2.985, y: 0.649, z: 2.592 }}
      rotation={{ x: -30, y: 0, z: 0 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-2.985 0.651 2.592',
        dur: '4000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      unlit-gltf={{
        model: './models/vegetables/noodle-ingredient-sweetcorn.gltf',
        blend: 'none'
      }}
    />

    <Entity
      id="tofu1"
      position={{ x: -3.01, y: 0.641, z: 2.594 }}
      rotation={{ x: 0, y: 0, z: 0 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-3.01 0.650 2.594',
        dur: '3000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      unlit-gltf={{
        model: './models/vegetables/noodle-ingredient-tofu.gltf',
        blend: 'none'
      }}
    />

    <Entity
      id="tofu2"
      position={{ x: -3.01, y: 0.648, z: 2.517 }}
      rotation={{ x: -30, y: -60, z: 65 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-3.01 0.650 2.517',
        dur: '3000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      unlit-gltf={{
        model: './models/vegetables/noodle-ingredient-tofu.gltf',
        blend: 'none'
      }}
    />

    <Entity
      id="tofu3"
      position={{ x: -2.95, y: 0.648, z: 2.509 }}
      rotation={{ x: 0, y: 40, z: 0 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-2.95 0.650 2.509',
        dur: '3000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      unlit-gltf={{
        model: './models/vegetables/noodle-ingredient-tofu.gltf',
        blend: 'none'
      }}
    />

    <Entity
      id="sesames"
      position={{ x: -3, y: 0.6487, z: 2.537 }}
      scale={{ x: 1, y: 1, z: 1 }}
      random-layout={{ count: 15, radius: 0.075, bboxY: 0 }}
    >
      {[...Array(15)].map((x, i) => (
        <Entity
          unlit-gltf={{
            model: './models/vegetables/noodle-ingredient-sesame.gltf',
            blend: 'none'
          }}
          key={i}
        />
      ))}
    </Entity>

    <Entity
      id="onions"
      position={{ x: -3, y: 0.53, z: 2.537 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-3 0.55 2.54',
        dur: '4000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      random-layout={{ count: 3, radius: 0.06, bboxY: 0.05 }}
    >
      {[...Array(3)].map((x, i) => (
        <Entity
          unlit-gltf={{
            model: './models/vegetables/noodle-ingredient-onion.gltf',
            blend: 'none'
          }}
          key={i}
        />
      ))}
    </Entity>

    <Entity
      id="peppers"
      position={{ x: -3, y: 0.53, z: 2.537 }}
      scale={{ x: 1, y: 1, z: 1 }}
      animation={{
        property: 'position',
        to: '-3 0.55 2.54',
        dur: '4000',
        dir: 'alternate',
        loop: 'true',
        easing: 'easeInOutSine'
      }}
      random-layout={{ count: 4, radius: 0.065, bboxY: 0.05 }}
    >
      {[...Array(4)].map((x, i) => (
        <Entity
          unlit-gltf={{
            model: './models/vegetables/noodle-ingredient-pepper.gltf',
            blend: 'none'
          }}
          key={i}
        />
      ))}
    </Entity>

  </Entity>
);

export default Vegetables;
