import { Route } from 'react-router-dom';

import React, { Component } from 'react';
import './styles.css';

import VRScene from './VRScene';
// import Sidebar from './Sidebar';
import UI from './UI';

import config from 'srcDir/config';

import {Helmet} from "react-helmet";

class App extends Component {
  constructor (props) {
    super(props);

    this.state = {
      sidebarOpen: true
    };
  }

  handleResizeCanvas = (val) => {
    this.setState({ sidebarOpen: val });
  }

  render () {
    return ([
      <Helmet key="meta">
        <meta name="description" content={config.DESCRIPTION}/>
      </Helmet>,
      
      <Route
        key="ui"
        path="/"
        component={UI}
        exact
      />,

      <VRScene
        key="vr-scene"
        sidebarOpen={this.state.sidebarOpen}
        passSceneData={this.handleSceneData}
      />
    ]);
  }
}

export default App;
