import AFRAME from 'aframe';

AFRAME.registerComponent('layout', {
  schema: {
    radius: { default: 1, min: 0 }
  },

  /**
   * Store initial positions in case need to reset on component removal.
   */
  init () {
    const el = this.el;
    this.children = el.getChildEntities();
    const initialPositions = [];

    this.children.forEach((childEl) => {
      initialPositions.push(childEl.getAttribute('position'));
    });

    this.childAttachedCallback = this.update.bind(this);
    el.addEventListener('child-attached', this.childAttachedCallback);
  },

  /**
   * Update child entity positions.
   */
  update () {
    const children = this.el.getChildEntities();
    const data = this.data;
    const el = this.el;
    const numChildren = children.length;
    let positionFn;
    let positions;
    const startPosition = el.getAttribute('position');

    positionFn = getCirclePositions;

    positions = positionFn(data, numChildren, startPosition);
    setPositions(children, positions);
  },

  /**
   * Reset positions.
   */
  remove () {
    this.el.removeEventListener('child-attached', this.childAttachedCallback);
    setPositions(this.children, this.initialPositions);
  }
});

/**
 * Get positions for `circle` layout.
 * TODO: arcLength.
 */
function getCirclePositions (data, numChildren, startPosition) {
  const positions = [];

  for (let i = 0; i < numChildren; i++) {
    const rad = i * (2 * Math.PI) / numChildren;
    positions.push([
      startPosition.x + data.radius * Math.sin(rad),
      startPosition.y,
      startPosition.z + data.radius * Math.cos(rad)
    ]);
  }
  return positions;
}

/**
 * Set position on child entities.
 *
 * @param {array} els - Child entities to set.
 * @param {array} positions - Array of coordinates.
 */
function setPositions (els, positions) {
  els.forEach((el, i) => {
    const position = positions[i];
    el.setAttribute('position', {
      x: position[0],
      y: position[1],
      z: position[2]
    });
  });
}
