import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('planetarium-colour', {
  schema: {
    colour: { type: 'color', default: '#000000' }
  },

  init () {
    this.mesh = document.querySelector('#planetarium_screen_model');

    if (this.mesh) {
      const model = this.mesh.object3D;
      const self = this;

      model.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
          self.material = child.material;
          self.material.uniforms.color.value = new THREE.Color(
            this.data.colour
          );
          self.material.uniforms.useColor.value = 1.0;
        }
      });
    }
  },

  remove () {
    if (this.mesh) {
      const model = this.mesh.object3D;
      const self = this;

      model.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          self.material = child.material;
          self.material.uniforms.color.value = new THREE.Color('#6e767b');
          self.material.uniforms.useColor.value = 0.0;
        }
      });
      this.mesh = null;
    }
  }
});
