import React, { Component } from 'react';

import { isMobile } from 'react-device-detect';

class Cursor extends Component {
  constructor (props) {
    super(props);

    this.state = {
      top: 0,
      left: 0,
      countdown: false,
      showInfoCursor: false
    };
  }

  componentDidMount () {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mousedown', this.handleMouseDown);
    window.addEventListener('mouseup', this.handleMouseUp);

    window.addEventListener('preSwitch', this.preSwitch);
    window.addEventListener('clearSwitch', this.clearSwitch);
  }

  preSwitch = () => {
    this.setState({
      countdown: true,
      seconds: 3
    }, () => {
      this.interval = setInterval(() => {
        if (this.state.seconds === 1) {
          this.clearSwitch();
        }

        this.setState({
          seconds: this.state.seconds - 1
        });
      }, 1000);
    });
  }

  clearSwitch = () => {
    clearInterval(this.interval);

    this.setState({
      countdown: false
    });
  }

  handleMouseMove = (evt) => {
    if (!this.inspectorOpen()) {
      let direction;

      const isTopLeft = (evt.clientX < 200 && evt.clientY < 150);
      const isTopRight = (evt.clientX > window.innerWidth - 200 && evt.clientY < 150);
      const isBottomLeft = (evt.clientX < 200 && evt.clientY > window.innerHeight - 150);
      const isBottomRight = (evt.clientX > window.innerWidth - 200 && evt.clientY > window.innerHeight - 150);
      const isOverInfo = (evt.clientX > 200 && evt.clientX < window.innerWidth - 200) && (evt.clientY > window.innerHeight - 150);
      
      const hasOverlay = this.hasOverlay();
      
      const switchWidthModifier = isMobile ? 3 : 5;
      
      if (isTopLeft || isTopRight || isBottomLeft || isBottomRight) {
        direction = 'none';
      } else if (evt.clientX > window.innerWidth - window.innerWidth / switchWidthModifier) {
        direction = 'next';
      } else if (evt.clientX < window.innerWidth / switchWidthModifier) {
        direction = 'prev';
      } else {
        direction = 'none';
      }

      if (evt.clientX < 10 || evt.clientX > window.innerWidth - 10 || evt.clientY < 10 || evt.clientY > window.innerHeight - 10) {
        document.body.classList.add('--hide-cursor');
      } else {
        document.body.classList.remove('--hide-cursor');
      }

      if (hasOverlay || isOverInfo) {
        direction = 'none';
      }
      
      if (this.hasOverlay() && !this.hasInfoOverlay() || isMobile) {
        this.setState({showInfoCursor: false});
      } else if(isOverInfo && this.hasInfo()) {
        this.setState({showInfoCursor: true});
      } else {
        this.setState({showInfoCursor: false});        
      }

      this.setState({
        left: evt.clientX,
        top: evt.clientY,
        direction
      });

      if (this.click === true) this.timer++;
    }
  }

  handleMouseDown = (evt) => {
    this.setState({
      mouseDown: true,
      mouseDownX: evt.clientX,
      mouseDownY: evt.clientY
    });
  }

  handleMouseUp = (evt) => {
    this.setState({ mouseDown: false });
    if (!this.inspectorOpen() && !this.props.ignoreClick && !this.hasOverlay()) {
      const xDelta = Math.abs(this.state.mouseDownX - evt.clientX);
      const yDelta = Math.abs(this.state.mouseDownY - evt.clientY);

      if (xDelta > 10 || yDelta > 10) return;

      const event = new CustomEvent('switch-camera', { detail: this.state.direction });
      document.dispatchEvent(event);
    }
  }

  inspectorOpen = () => {
    if (document.body.classList.contains('aframe-inspector-opened')) {
      return true;
    }
    return false;
  }
  
  hasInfo = () => {
    if (document.body.classList.contains('--has-info')) {
      return true;
    }
    return false;
  }
  
  hasInfoOverlay = () => {
    if (document.body.classList.contains('--has-info-overlay')) {
      return true;
    }
    return false;
  }

  hasOverlay = () => {
    if (document.body.classList.contains('--has-overlay')) {
      return true;
    }
    return false;
  }

  render () {
    const cursorStyle = {
      top: this.state.top,
      left: this.state.left
    };

    return (
      <div
        id="cursor"
        className={`direction-${this.state.direction} ${this.state.showInfoCursor ? 'show-info-cursor' : ''}`}
        style={cursorStyle}
      >
        <span className="switch-countdown">{this.state.countdown ? this.state.seconds : ''}</span>
        <img 
          className={`question-cursor ${this.state.showInfoCursor}`}
          src="./img/ui/icons/question-cursor.svg"
        />
      
        <img 
          className="close-icon"
          src="./img/ui/icons/close-icon.svg"
        />
      </div>
    );
  }
}

export default Cursor;
