import React from 'react';
import { Entity } from 'aframe-react';

const AudioAssets = () => (
  <Entity
    primitive="a-assets"
  >
    <audio id="stage_1_amb_l" src="./audio/Stage1_ambL.mp3" />
  </Entity>
);

export default AudioAssets;
