import AFRAME from 'aframe';

import axios from 'axios';
import config from 'srcDir/config';

import { isMobile } from 'react-device-detect';
import { isCapturing } from 'util';
import data from './Cameras';

const invertedCameras = [
  'museum_display_box_cam',
  'golem_cam',
  'golem_head_cam',
  'planetarium_outside_cam',
  'stormdrain_lift_cam',
  'bench_cam',
  'security_cam'
];

AFRAME.registerComponent('camera-switcher', {
  init () {
    this.disablePlayback = false;

    if (isCapturing()) {
      this.disablePlayback = true;
    }

    this.sceneEl = this.el;
    this.vr = false;
    this.data = data;
    this.planetariumCamIndex = 7;

    this.cameras = [];
    this.captures = [];
    this.loadedCaptures = 0;

    // ANIMATION
    this.duration = 450;

    this.mute = false;

    this.getAllCaptures();

    document.addEventListener('main-models-loaded', () => {
      this.setCameras();
      this.resetTimeout(27000);
      this._attachEventListeners();
    });
    
    this.el.sceneEl.addEventListener('camera-set-active', () => {
      this.disablePlayback = false;
      this.disableSwitch = false;
    });
  },

  __onToggleCaptures (evt) {
    this.disablePlayback = evt.detail;
    this.disableSwitch = evt.detail;

    if (!this.disablePlayback) {
      this.resetTimeout();
    } else {
      clearTimeout(this.timer);
    }
  },

  getAllCaptures () {
    axios.get(`${config.CAPTURES_URL}/captures`)
      .then((res) => {
        this.handleCaptures(res.data);
        this.el.emit('captures-loaded');
      });
  },

  handleCaptures (captureData) {
    this.captures = [];
    const captures = this.captures;

    captureData.forEach((cam) => {
      if (!cam.capture || cam.capture.length < 1) return;

      captures.push({
        capture: cam.capture,
        id: cam._id,
        camera: cam.element
      });

      this.getSingleCapture(cam._id, cam.element, cam.capture[0]);
    });
  },

  preNextCamera () {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.nextCamera();
    }, 3000);

    this.el.emit('preSwitch', null);
  },

  nextCamera () {
    this.__switchCamera();
    // Reset captures back to 0

    // loop through every camera
    this.resetReplayer();
  },

  resetReplayer () {
    if (this.disablePlayback) return;

    this.data.forEach((cam) => {
      const el = cam.el;
      const replayer = document.querySelector(el).components['capture-replayer'];
      if (replayer) {
        replayer.resetRecording();
      }
    });
  },

  resetTimeout (camDuration) {
    const switchTime = camDuration || 12000;

    this.el.emit('clearSwitch', null);

    if (!this.disablePlayback) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.preNextCamera();
      }, switchTime);
    }
  },

  getSingleCapture (id, el, captureId) {
    axios.get(`${config.CAPTURES_URL}/captures/${id}/${captureId}`)
      .then((res) => {
        const data = res.data;

        if (!data || !data.mocap) return false;

        const replayer = document.querySelector(el).components.replayer;        
        replayer.setReplayData(data.mocap);
        
        if (el === '#museum_display_box_cam') {
          replayer.startReplaying(el);
        }

        // if (el === '#museum_display_box_cam' && isMobile) {
        //   replayer.resetReplayer(true);
        // }

        return this.loadedCaptures++;
      });
  },

  setCameras () {
    this.cameras = [];

    for (let i = 0; i < this.data.length; i++) {
      const obj = {
        el: document.querySelector(this.data[i].el),
        name: this.data[i].name,
        clear: this.data[i].clear
      };
      this.cameras.push(obj);
    }

    this.activeCameraIndex = 0;

    if (this.cameras[this.activeCameraIndex]) {
      const cam = this.cameras[this.activeCameraIndex].el;
      cam.setAttribute('camera', 'active', true);

      this.hideAvatar(cam);
      this.invertUI(true);
    }
  },

  activateCamera () {
    // CLEAR INTERVAL
    const cam = this.cameras[this.activeCameraIndex];
    cam.el.setAttribute('camera', 'active', true);

    this.triggerAnimation = true;
    this.animating = true;

    if (this.disablePlayback) {
      const lookControls = cam.el.components['look-controls-alt'];
      
      if(!lookControls) return;
      lookControls.play();
    }

    this.checkInvertUI(cam.el.id);

    this.hideAvatar(cam.el);
  },

  checkInvertUI (cam) {
    if (invertedCameras.includes(cam)) {
      this.invertUI(true);
    } else {
      this.invertUI(false);
    }
  },

  hideAvatar (cam) {
    const avatars = document.querySelectorAll('.camera-avatar');

    for (const item of avatars) {
      item.setAttribute('visible', true);
    }

    const checkAndHide = (item) => {
      if (item.classList.contains('camera-avatar')) {
        item.setAttribute('visible', false);
      }
    };

    if (Array.isArray(cam)) {
      for (const item of cam) {
        for (const children of item.parentNode.children) {
          checkAndHide(children);
        }
      }
    } else {
      for (const item of cam.parentNode.children) {
        checkAndHide(item);
      }
    }
  },

  invertUI (isInverted) {
    const body = document.body;

    if (isInverted) {
      body.classList.add('--invert-ui');
    } else {
      body.classList.remove('--invert-ui');
    }
  },

  _attachEventListeners () {
    document.addEventListener('switch-camera', AFRAME.utils.throttle(this.__switchCamera, 250, this), false);
    document.addEventListener('shuffle-camera', AFRAME.utils.throttle(this.__onShuffle, 250, this), false);
    document.addEventListener('toggle-captures', this.__onToggleCaptures.bind(this), false);
    document.addEventListener('getProject', this.__onIndex.bind(this), false);
    document.addEventListener('mute', this.__onMute.bind(this), false);
    
    document.addEventListener('keydown', this.__onKeyDown.bind(this), false);
    
    // document.addEventListener("finishedTutorial", this.__onFinishedTutorial.bind(this), false);
    document.querySelector('a-scene').addEventListener('enter-vr',
      this.__onVR.bind(this), false);
  },
  
  __onKeyDown (evt) {
    let foo = {};
    
    if(evt.code == "ArrowRight") {
      foo.detail = 'next';
      this.__switchCamera(foo);
    } else if(evt.code == "ArrowLeft") {
      foo.detail = 'prev';
      this.__switchCamera(foo);
    }
  },

  __onFinishedTutorial () {
    if (this.loadedCaptures === this.captures.length && !this.disableSwitch) {
      this.resetTimeout();
    }
  },

  __switchCamera (evt) {
    if (this.cameras.length < 1) {
      return false;
    }

    const detail = evt === undefined ? 'next' : evt.detail;

    const prevCam = this.cameras[this.activeCameraIndex].el;
    let newCam = null;

    if (detail === 'next') {
      if (this.activeCameraIndex < this.cameras.length - 1) {
        this.activeCameraIndex++;
      } else {
        this.activeCameraIndex = 0;
      }
      newCam = this.cameras[this.activeCameraIndex].el;
    } else if (detail === 'prev') {
      if (this.activeCameraIndex > 0) {
        this.activeCameraIndex = this.activeCameraIndex - 1;
      } else {
        this.activeCameraIndex = this.cameras.length - 1;
      }
      newCam = this.cameras[this.activeCameraIndex].el;
    } else {
      return false;
    }

    this.resetCameraTimeout(newCam.id);

    if (isMobile) {
      this.el.emit('reset-orientation', { prevCam: prevCam.id, newCam: newCam.id }, false);
    }

    if (this.cameras[this.activeCameraIndex].clear) {
      const scene = document.querySelector('a-scene');
      scene.emit('dong', { title: '', abstract: '', description: '' });
    }

    this.activateCamera();
  },
  
  resetCameraTimeout (id) {    
    if (id === 'noodle_cam' ||
       id === 'planetarium_main_cam_1' ||
       id === 'planetarium_main_cam_2' ||
       id === 'museum_display_box_cam' ||
       id === 'stormdrain_pillar_cam' ||
       id === 'newspaper_cam') {
      this.resetTimeout(27000);
    } else {
      this.resetTimeout();
    }
  },

  __onIndex (evt) {
    let targetCam = evt.detail.camera ? evt.detail.camera : 'planetarium_index_cam';
    const cam = this.cameras[this.activeCameraIndex].el.id;
    const nextCam = document.querySelector(`#${targetCam}`);

    if(targetCam == 'planetarium_index_cam') {
      this.activeCameraIndex = 2;
      
      if (cam !== '#planetarium_index_cam') {
        const planetariumMainCamOne = document.querySelector('#planetarium_main_cam_1');
        const planetariumMainCamTwo = document.querySelector('#planetarium_main_cam_2');
        const planetariumOutsideCam = document.querySelector('#planetarium_outside_cam');

        this.hideAvatar([planetariumMainCamOne, planetariumMainCamTwo, planetariumOutsideCam]);
      }
      
      clearTimeout(this.timer);
      this.disableSwitch = true;
    } else {
      this.activeCameraIndex = this.cameras.map(e => e.el.id).indexOf(targetCam);
      this.hideAvatar(nextCam);
      
      if(targetCam !== cam) this.resetCameraTimeout(targetCam);
    }
    
    nextCam.setAttribute('camera', 'active', true);
    
    this.checkInvertUI(targetCam);
  },

  __onMute () {
    if (this.mute) {
      this.mute = false;
      document.body.classList.remove('--mute');
    } else {
      this.mute = true;
      document.body.classList.add('--mute');
    }
  },

  __onVR () {
    this.vr = true;
    this.planetariumCamIndex = 6;
    this.data = data[1];
    this.setCameras();
  },

  tick (t) {
    if (this.triggerAnimation) {
      this.startTime = t;
      this.endTime = this.startTime + this.duration;
      this.triggerAnimation = false;

      this.currentCam = this.sceneEl.camera;
      this.startFov = this.currentCam.fov - 10;
      this.endFov = this.currentCam.fov;
    }

    if (this.animating) {
      const cam = this.currentCam;
      this.val = map(t, this.startTime, this.endTime, 0, 1);

      if (this.val > 1) this.val = 1;

      this.fov = map(easeOutCubic(this.val), 0, 1, this.startFov, this.endFov);

      cam.fov = this.fov;
      cam.updateProjectionMatrix();

      if (t > this.endTime) this.animating = false;
    }
  },

  _removeEventListeners () {
    document.removeEventListener('switch-camera', this.__switchCamera);
    document.removeEventListener('shuffle-camera', this.__onShuffle);
    document.removeEventListener('getProject', this.__onIndex);
    document.removeEventListener('mute', this.__onMute);
    document.removeEventListener('keydown', this.__onKeyDown.bind(this), false);
    document.querySelector('a-scene').removeEventListener('enter-vr',
      this.__onVR);
  },

  remove () {
    this._removeEventListeners();
  }
});

function easeOutCubic (t) {
  return --t * t * t + 1;
}

function map (value, istart, istop, ostart, ostop) {
  return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
}
