import { Entity } from 'aframe-react';
import React from 'react';

const Videosphere = (props) => (
  <Entity
    className="planetarium-videosphere"
    videosphere={{
      src: './video/frank_videosphere_mobile.mp4',
      active: props.isPlaying
    }}
  />
);

export default Videosphere;
