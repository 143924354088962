import { Entity } from 'aframe-react';
import React from 'react';

// Entities
import { Camera } from '../../../Entities';

const Lift = props => (
  <Entity
    id="lift"
    position={{ x: 0, y: 16.72, z: 0 }}
    {...props}
  >

    <Entity
      id="lift_loader"
      asset-loader={{
        cameras: ['stormdrain_lift_cam', 'stormdrain_base_cam']
      }}
    >
      <Entity
        gltf-model="obj: url(./models/stormdrain/stormdrain_platform.gltf);"
        lift-anim
      >
        <Camera
          id="stormdrain_lift_cam"
          position={{ x: 0, y: -1.2, z: 0 }}
          macro
          cameras={['museum_display_box_cam', 'stormdrain_base_cam', 'stormdrain_pillar_cam']}
        />
      </Entity>
      
      <Entity
          id="lift_audio"
          primitive="a-sound"
          position={{x: 0, y: 0, z: 0}}
          sound={{
            src: 'url(./audio/Motion_ElevDown.mp3)',
            loop: false,
            volume: .5,
            positional: false
          }}
          sound-controls={{
            loader: 'lift_loader'
          }}
      />
    </Entity>

  </Entity>
);

export default Lift;
