import { Entity } from 'aframe-react';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

class NoodleCamera extends Component {
  constructor (props) {
    super(props);

    this.state = { near: 0.001 };
  }

  componentDidMount () {
    if (isMobile) {
      this.setState({ near: 0.003 });
    }
  }

  render () {
    return (
      <Entity
        id="noodle_camera_rig"
      >

        <Entity
          id="noodle_cam"
          primitive="a-camera"
          position={{ x: 0, y: 0, z: 0 }}
          camera={{
            fov: 80,
            near: this.state.near,
            far: 40
          }}
          look-controls-alt={{ enabled: true }}
          wasd-controls={{ enabled: false }}
        >

          <Entity
            id="noodle_overlay"
            colour-overlay={{
              ySwitch: 0.65
            }}
          />

        </Entity>

      </Entity>
    );
  }
}

export default NoodleCamera;
