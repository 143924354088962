import React, { Component } from 'react';
import { Entity } from 'aframe-react';

import data from './Data';

class Avatar extends Component {
  constructor (props) {
    super(props);

    const modelChosen = Math.round(Math.random() * (data[1].length - 1));
    const colorChosen = Math.round(Math.random() * (data[0].length - 1));

    this.state = {
      model: data[1][modelChosen].src,
      depth: data[1][modelChosen].depth,
      offset: data[1][modelChosen].offset,
      noseOffset: data[1][modelChosen].noseOffset,
      axis: data[1][modelChosen].axis,
      colorA: data[0][colorChosen].colorA,
      colorB: data[0][colorChosen].colorB,
      colorC: data[0][colorChosen].colorC,
      cameras: this.props.cameras
    };
  }

  render () {
    return (
      <Entity
        className="camera-avatar"
        scale={{ x: 0.8, y: 0.8, z: 0.8 }}
        position={{ x: 0, y: 0, z: 0 }}
        head-shading={{
          model: this.state.model,
          modelDepth: this.state.depth,
          offset: this.state.offset,
          noseOffset: this.state.noseOffset,
          colorA: this.state.colorA,
          colorB: this.state.colorB,
          colorC: this.state.colorC,
          axis: this.state.axis,
          cameras: this.props.cameras
        }}
        attach-to={this.props.cameraId}
      />
    );
  }
}

export default Avatar;
