import AFRAME from 'aframe';

const requiredModels = [
  '#lab_model',
  '#planetarium_model',
  '#planetarium_screen_model',
  '#billboard_model',
  '#blob_model',
  '#mountain_model',
  '#stormdrain_outer_model',
  '#stormdrain_inner_model',
  '#stormdrain_cables_model',
  '#golem_model',
  '#museum_main_model',
  '#main_display_cases_model',
  '#secondary_display_cases_model',
  '#newspaper_model'
];

const loadedModels = [];

AFRAME.registerComponent('init-loader', {
  init () {
    // if entity is loading image/animation
    this.el.setAttribute('visible', false);
    this.loaded = false;

    this.loadedAmount = 0;
    this.neededAmount = requiredModels.length;

    this.start = 0;
    this.startedLoad = false;
    this.end = 3000;

    this._attachEventListeners();
  },

  _attachEventListeners () {
    this.el.sceneEl.addEventListener('loaded',
      this.__onSceneLoaded.bind(this), false);
    this.el.sceneEl.addEventListener('captures-loaded',
      this.__onCaptureLoaded.bind(this), false);

    // only one entity has event listeners, no double checking
    for (const model in requiredModels) {
      const gltfModel = document.querySelector(requiredModels[model]);

      if (gltfModel.components.loaded === true) {
        this.loadModel();
      } else {
        document.querySelector(requiredModels[model]).addEventListener('model-loaded', () => {
          this.loadModel();
        });
      }
    }
  },

  loadModel (model) {
    let found = false;

    for (const loadedModel in loadedModels) {
      if (model === loadedModel) {
        found = true;
      }
    }
    if (!found) {
      loadedModels.push(model);
      this.loadedAmount++;
      this.checkLoaded();
    }
  },

  loadScene () {
    this.el.setAttribute('visible', true);
    this.el.emit('main-models-loaded');

    this.el.removeAttribute('init-loader');
  },

  checkLoaded () {
    if (loadedModels.length === requiredModels.length &&
        this.loadedAmount === this.neededAmount) {
      this.loaded = true;
    }
  },

  tick (t) {
    const loaded = loadedModels.length / requiredModels.length;
    this.el.emit('percentLoaded', loaded);

    if (this.sceneLoaded && !this.loaded && t > 15000) {
      this.loaded = true;
      this.loadScene();
    } else if (this.loaded) {
      if (!this.startedLoad) {
        this.startedLoad = true;
        this.start = t;
      } else {
        if (t - this.start >= this.end) {
          this.loadScene();
        }
      }
    }
  },

  __onSceneLoaded () {
    // failsafe but scene should always be loaded before models
    this.sceneLoaded = true;
    // this.loadedAmount++;
  },

  __onCaptureLoaded () {
    // this.loadedAmount++;
  },

  _removeEventListeners () {
    this.el.sceneEl.removeEventListener('loaded', this.__onSceneLoaded, false);
  },

  remove () {
    this._removeEventListeners();
  }
});
