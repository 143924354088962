import React from 'react';
import { Entity } from 'aframe-react';

const Controls = () => (
  <Entity
    id="vive_controls"
    vive-controls="hand: right"
  />
);

export default Controls;
