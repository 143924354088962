import AFRAME from 'aframe';

AFRAME.registerComponent('rotatomatic', {
  schema: {
    radius: { type: 'number', default: 4 },
    y: { type: 'number', default: 0 },
    speed: { type: 'number', default: 1 },
    offset: { type: 'number', default: 0 },
    direction: { type: 'string', default: 'forward' },
    rad: { type: 'number' }
  },

  init () {
    this.started = true;
    this.startTime = 0;
  },

  tick (t) {
    if (this.started) {
      this.startTime = t;
      this.started = false;
    }

    let time = (this.startTime - t) / 75000;
    if (this.data.direction === 'forward') time *= -1;

    const x = Math.sin(this.data.rad + time) * this.data.radius;
    const z = Math.cos(this.data.rad + time) * this.data.radius;

    this.el.object3D.position.set(x, this.data.y, z);
  }
});
