import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('newspaper', {
  schema: {
    pages: {type: 'array'},
    model: { type: 'model' }
  },
  
  init: function () {
    this.index = 0;
    
    this.loader = document.querySelector('#newspaper_loader');
  },
  
  update: function () {
    this.model = null;
    this.modelLoader = new THREE.GLTFLoader();
    
    const el = this.el;
    const src = this.data.model;
    
    this.animationMixer = this.el.components["animation-mixer"];
        
    if(!this.data.pages.length > 0) return;
    
    this.loadTextures(() => {
      this.modelLoader.load(src,
        (gltfModel) => {
          this.model = gltfModel.scene || gltfModel.scenes[0];
          this.model.animations = gltfModel.animations;
          el.setObject3D('mesh', this.model);
          el.emit('model-loaded', { format: 'gltf', model: this.model });
        
          this.action = this.animationMixer.activeActions[0];
          this.action.clampWhenFinished = true;
          
          this.setMaterial(this.model);

          this.loaded = true;
          this._attachEventListeners();
        },

        undefined,

        (error) => {
          // const message = error && error.message ? error.message : 'Failed to load glTF model';
          el.emit('model-error', { format: 'gltf', error });
        });
    });
  },
  
  _attachEventListeners () {
    this.loader.addEventListener('load-now', this.__onCameraLoad.bind(this), false);
    this.loader.addEventListener('remove', this.__onRemove.bind(this), false);

    this.animationMixer.mixer.addEventListener("loop", () => {
      this.action.paused = true;
      this.rightPage.visible = false;
      
      setTimeout(() => {
        this.action.paused = false;
 
        setTimeout(() => {
          this.rightPage.visible = true;
        }, 450);
      }, 2000);
      
      this.index = this.index + 2;
      this.setMaterial(this.model);       
    });
  },
  
  __onCameraLoad () {
    if (!this.loaded) {
      this.loaded = true;
      this.action.paused = false;
    }
  },

  __onRemove () {
    if (this.loaded) {
      this.loaded = false;
      this.action.paused = true;
    }
  },
  
  setMaterial (model) {
    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if(this.index+1 > this.data.pages.length) {
          this.index = 0;
        }
        
        this.removeTexture(child.material.map);
                
        if (child.name === 'LEFT_PAGE') {
          this.leftPage = child;
          child.material.map = this.textures[this.index].texture;
        } else if (child.name === 'TURNING_PAGE_LEFT') {
          this.turningPageLeft = child;         
          child.material.map = this.textures[this.index + 1].texture;
        } else if (child.name === 'TURNING_PAGE_RIGHT') {
          let index = this.index + 2;
          if(this.index+2 > this.data.pages.length-1) index = 0;
          
          child.material.map = this.textures[index].texture;
        } else {
          let index = this.index + 3;
          if(this.index+3 > this.data.pages.length-1) index = 1;
          
          this.rightPage = child;
          child.material.map = this.textures[index].texture;
        }
                 
        child.material.map.flipY = false;
        child.material.needsUpdate = true;
      }
    });
  },
  
  loadTextures (cb) {
    const images = this.data.pages;
    this.textures = [];
    
    for (let i = 0; i < images.length; i++) {
      const loader = new THREE.TextureLoader();    
      
      loader.load(images[i], (texture) => {
        this.textures.push({
          id: i,
          texture: texture
        });
        
        this.checkLoaded(cb);
      });
    }
  },
  
  checkLoaded (cb) {
    if (this.textures.length == this.data.pages.length) {
      this.textures.sort((a, b) => {
        return a.id - b.id;
      });
      
      cb();
    }
  },
  
  
  removeTexture (texture) {
    if (texture) texture.dispose();

    texture = null;
  },
  
  
  remove () {
    if (this.model) this.el.removeObject3D('mesh');
    if (this.material) this.material.dispose();
    if (this.texture) this.texture.dispose();

    this.model = null;
    this.material = null;
    this.texture = null;
  }
});