import React, { Component } from 'react';

class Logo extends Component {
  constructor (props) {
    super(props);

    this.el = `${this.props.label.toLowerCase()}_button`;
  }

  handleClick = () => {
    this.props.onClick(this.el);
  }

  render () {
    const active = this.props.active ? ' active' : '';

    return (
      <div
        id={this.el}
        className={`${active}`}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.handleClick}
      >
        <img
          id="logo"
          src="./img/ui/logo.svg"
          alt="Zoo Logo"
        />
      </div>
    );
  }
}

export default Logo;
