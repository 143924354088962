import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('photosphere', {
  schema: {
    src: { type: 'string', default: '' }
  },

  init () {
    this.mesh = document.querySelector('#planetarium_screen_model');

    this.texture = new THREE.TextureLoader().load(
      this.data.src,
      this.setMaterial.bind(this),
      undefined
    );
  },

  setMaterial () {
    const model = this.mesh.object3D;

    this.texture.flipY = false;
    this.texture.wrapS = THREE.RepeatWrapping;
    this.texture.wrapT = THREE.RepeatWrapping;

    const self = this;

    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        self.material = child.material;
        self.material.uniforms.map.value = self.texture;
      }
    });
  },

  remove () {
    this.material.uniforms.map.value = null;

    if (this.texture) this.texture.dispose();
    if (this.mesh) this.mesh = null;
  }
});
