import { Entity } from 'aframe-react';
import React, { Component } from 'react';

import { isCapturing } from 'util';
import Vegetables from './Vegetables';
import NoodleCamera from './NoodleCamera';
import FloatingImages from './FloatingImages';

// Data
import config from 'srcDir/config';
import axios from 'axios';

// Entities
import { Camera, SceneModel } from '../../Entities';

class Noodle extends Component {
  constructor (props) {
    super(props);

    this.state = { 
      broth: this.returnBroth(),
      abstract: '',
      description: ''
    };
  }

  componentDidMount () {
    document.querySelector('a-scene')
      .addEventListener('camera-set-active', this.__onCameraSwitch, false);
      
    if (isCapturing()) {
      this.setState({
        broth: null
      });
    }
    
    this.getCaption();
  }
  
  getCaption = () => {
    axios.get(`${config.API_URL}/areas/`)
      .then((res) => {
        const data = res.data;
        
        const item = data.find((el) => {
          return el.name == 'noodle';
        });
        
        if(!item) return;
        
        this.setState({
          abstract: item.abstract,
          description: item.description
        });
      });
  };
  
  __onCameraSwitch = (evt) => {
    this.activeCam = evt.detail.cameraEl.id;
    
    if (this.activeCam === 'noodle_cam') {
      const scene = document.querySelector('a-scene');
      scene.emit('dong', { title: '', abstract: this.state.abstract, description: this.state.description, canDrag: true });
    }
  }

  returnBroth = () => (
    <Entity
      id="Broth1"
      position={{ x: -3, y: 0.65, z: 2.537 }}
      rotation={{ x: -90, y: 0, z: 0 }}
      broth
    />
  );

  render () {
    const broth = this.state.broth;

    return (
      <Entity
        id="noodle"
        position={{ x: -2.772, y: 0, z: -8.539 }}
      >
        <SceneModel
          id="cup_model"
          src="./models/noodle/cup-noodle"
          position={{ x: -3.001, y: 0.492, z: 2.536 }}
          rotation={{ x: 0, y: -90, z: 0 }}
          blend="none"
        />

        <Camera
          id="bench_cam"
          position={{ x: -3, y: 0, z: 1.8 }}
          rotation={{ x: 0, y: 180, z: 0 }}
          cameras={[
            'museum_display_box_cam',
            'planetarium_outside_cam',
            'lab_outside_cam',
            'stormdrain_lift_cam',
            'golem_cam',
            'golem_head_cam'
          ]}
        />

        <NoodleCamera />

        <Entity
          id="inside_noodle_loader"
          asset-loader={{
            cameras: ['noodle_cam']
          }}
        />

        <Entity
            id="noodle_audio"
            primitive="a-sound"
            position={{x: 0, y: 0, z: 0}}
            sound={{
              src: 'url(./audio/Stage2_obj_water.mp3)',
              loop: true,
              volume: .5,
              positional: false
            }}
            sound-controls={{
              loader: 'inside_noodle_loader',
            }}
        />

        <Entity
          id="noodle_loader"
          asset-loader={{
            cameras: ['bench_cam', 'noodle_cam']
          }}
        >
          <Entity
            id="noodle1"
            position={{ x: -3, y: 0.60, z: 2.537 }}
            noodle={{
              maxRad: 0.0575,
              minRad: 0.04,
              height: 0.11,
              length: 0.225,
              seed: 1,
              smooth: 1,
              seated: true
            }}
          />

          <Entity
            id="noodle2"
            position={{ x: -3, y: 0.55, z: 2.54 }}
            noodle={{
              seed: 2,
              dir: -1
            }}
          />

          <Entity
            id="noodle3"
            position={{ x: -3, y: 0.57, z: 2.54 }}
            noodle={{
              speed: 0.001,
              seed: 3
            }}
          />

          <Vegetables />
          <FloatingImages />

          {broth}
        </Entity>
      </Entity>
    );
  }
}

export default Noodle;
