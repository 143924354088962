import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('portfolio-image', {
  multiple: true,
  schema: {
    parentLoader: { type: 'string', default: '' },
    source: { type: 'string', default: '' },
    width: { type: 'number', default: 0 },
    height: { type: 'number', default: 0 },
    depth: { type: 'number', default: 0 },
    zLoadingPos: { type: 'number', default: 0.1 },
    textureWidth: { type: 'number', default: 1024 },
    textureHeight: { type: 'number', default: 1024 },
    lazyLoad: { type: 'bool', default: true }
  },

  init () {
    this.loaded = false;
    this.mesh = null;

    this.object3D = this.el.object3D;

    this.imageAspectRatio = this.data.width / this.data.height;
    this.textureAspectRatio = this.data.textureWidth / this.data.textureHeight;

    this.orientation = this.data.width > this.data.height ? 'landscape' : 'portrait';

    if (!this.data.parentLoader) return false;
    this.parentLoader = document.querySelector(this.data.parentLoader);

    if (this.data.depth === 0) {
      this.createPlane();
    } else {
      this.createBox();
    }

    if (!this.data.lazyLoad) this.addTexture();

    this._attachEventListeners();
  },

  createPlane () {
    const geometry = new THREE.PlaneBufferGeometry(
      this.data.width,
      this.data.height
    );
    const material = new THREE.MeshBasicMaterial({
      map: null,
      side: THREE.DoubleSide,
      depthWrite: true
    });

    this.mesh = new THREE.Mesh(geometry, material);

    const uvs = this.mesh.geometry.attributes.uv.array;

    let uvX = 1;
    let uvY = 1;

    if (this.orientation === 'portrait') {
      uvX = 1.0 / (this.textureAspectRatio / this.imageAspectRatio);
    } else {
      uvY = this.textureAspectRatio / this.imageAspectRatio;
    }

    for (let i = 0; i < uvs.length; i += 8) {
      const j = 0;
      uvs[i + j] = 0;
      uvs[i + (j + 1)] = uvY;

      uvs[i + (j + 2)] = uvX;
      uvs[i + (j + 3)] = uvY;

      uvs[i + (j + 4)] = 0;
      uvs[i + (j + 5)] = 0;

      uvs[i + (j + 6)] = uvX;
      uvs[i + (j + 7)] = 0;
    }

    this.mesh.geometry.uvsNeedUpdate = true;
    this.el.object3D.add(this.mesh);
  },

  createBox () {
    const geometry = new THREE.BoxBufferGeometry(
      this.data.width,
      this.data.height,
      this.data.depth
    );
    const material = new THREE.MeshBasicMaterial({
      map: null,
      side: THREE.DoubleSide
    });

    this.mesh = new THREE.Mesh(geometry, material);

    const uvs = this.mesh.geometry.attributes.uv.array;

    let uvX = 1;
    let uvY = 1;

    if (this.orientation === 'portrait') {
      uvX = 1.0 / (this.textureAspectRatio / this.imageAspectRatio);
    } else {
      uvY = this.textureAspectRatio / this.imageAspectRatio;
    }

    for (let i = 0; i < uvs.length; i += 8) {
      const j = 0;

      if (i / 8 !== 4 && i / 8 !== 5) {
        uvs[i + j] = 0;
        uvs[i + (j + 1)] = 0;

        uvs[i + (j + 2)] = 0;
        uvs[i + (j + 3)] = 0;

        uvs[i + (j + 4)] = 0;
        uvs[i + (j + 5)] = 0;

        uvs[i + (j + 6)] = 0;
        uvs[i + (j + 7)] = 0;
      } else {
        uvs[i + j] = 0;
        uvs[i + (j + 1)] = uvY;

        uvs[i + (j + 2)] = uvX;
        uvs[i + (j + 3)] = uvY;

        uvs[i + (j + 4)] = 0;
        uvs[i + (j + 5)] = 0;

        uvs[i + (j + 6)] = uvX;
        uvs[i + (j + 7)] = 0;
      }
    }

    this.mesh.geometry.uvsNeedUpdate = true;
    this.el.object3D.add(this.mesh);
  },

  addTexture () {
    const source = this.data.source;

    this.el.setAttribute('loading-anim', {
      width: this.data.width,
      height: this.data.height,
      zPos: this.data.zLoadingPos,
      loaded: true
    });

    this.mesh.material.map = null;
    this.mesh.material.color = new THREE.Color('black');
    this.mesh.material.needsUpdate = true;

    if (source === '' || source === undefined || source === null) {
      return;
    }

    if (this.texture) {
      this.texture.dispose();
      this.texture = null;
    }

    const self = this;
    
    this.texture = new THREE.TextureLoader().load(source,
      () => {
        this.mesh.material.transparent = true;
        this.mesh.material.map = this.texture;
        this.mesh.material.color = null;
        this.mesh.material.needsUpdate = true;
        this.el.removeAttribute('loading-anim');
        this.loaded = true;
      },

      undefined,

      () => {
        self.el.removeAttribute('loading-anim');
        self.el.setAttribute('loading-anim', {
          width: self.data.width,
          height: self.data.height,
          loaded: false
        });

        this.el.emit('image-loaded', null);
      });
  },

  _attachEventListeners () {
    if (!this.parentLoader) return false;
    this.parentLoader.addEventListener('load-now', this.__onCameraLoad.bind(this), false);
    this.parentLoader.addEventListener('remove', this.__onRemove.bind(this), false);
  },

  _removeEventListeners () {
    if (!this.parentLoader) return false;
    this.parentLoader.removeEventListener('load-now', this.__onCameraLoad, false);
    this.parentLoader.removeEventListener('remove', this.__onRemove, false);
  },

  __onCameraLoad () {
    if (!this.loaded) {
      this.addTexture();
    }
  },

  __onRemove () {
    if (this.mesh) {
      this.mesh.material.map = null;
      this.mesh.material.needsUpdate = true;
      if (this.texture) {
        this.texture.dispose();
        this.texture = null;
      }
    }
    this.loaded = false;
  },

  remove () {
    this._removeEventListeners();

    if (this.texture) this.texture.dispose();

    if (this.mesh) {
      this.mesh.geometry.dispose();
      this.mesh.material.dispose();
    }
    this.el.object3D.remove(this.mesh);

    this.texture = null;

    // breaks it for whatever reason
    // this.mesh = undefined;
  }
});
