import AFRAME from 'aframe';

AFRAME.registerComponent('prop-rotate', {
  schema: {
    invert: { type: 'boolean', default: false }
  },

  init () {
    this.started = true;
    this.startTime = 0;

    this.rotationOffset = Math.random(360);
  },

  tick (t) {
    if (this.started) {
      this.startTime = t;
      this.started = false;
    }

    let time = (this.startTime - t) / 750000;
    if (this.data.invert === true) time *= -1;

    const y = Math.sin(time) * 360;

    this.el.object3D.rotation.set(0, y + this.rotationOffset, 0);
  }
});
