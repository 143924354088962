import React, { Component } from 'react';
import { Entity } from 'aframe-react';

import { checkResolution } from 'util';

// Data
import config from 'srcDir/config';
import axios from 'axios';

import { forEach } from 'lodash';
import FloatingImage from './FloatingImage';

class FloatingImages extends Component {
  constructor (props) {
    super(props);

    this.state = {
      images: []
    };
  }

  componentDidMount () {
    axios.get(`${config.API_URL}/ramen`).then((res) => {
      const ramenImages = [];

      forEach(res.data, (val) => {
        if (!val.image) return false;

        ramenImages.push({
          url: config.API_URL + val.image['1024'],
          smallUrl: config.API_URL + val.image['512'],
          width: val.image.width,
          height: val.image.height
        });
      });

      this.isSmall = checkResolution();

      this.setState({
        images: ramenImages,
        mobile: this.isSmall
      });
    });

    if (!this.isSmall) {
      document.querySelector('a-scene')
        .addEventListener('lowFPS', this.__onLowFPS, false);
      document.querySelector('a-scene')
        .addEventListener('highFPS', this.__onHighFPS, false);
    }
  }

  __onLowFPS = () => {
    this.setState({ mobile: true });
  };

  __onHighFPS = () => {
    this.setState({ mobile: false });
  };

  render () {
    return (
      <Entity id="floating-images">
        <Entity
          geometry={{ primitive: 'plane' }}
          material={{ color: '#edbb6b', opacity: 0, depthTest: false }}
          position={{ x: -3, y: 0.64, z: 2.3 }}
          rotation={{ x: 270, y: 0, z: 0 }}
        />

        {this.state.images.length > 0 && (
          <Entity>
            {this.state.images[0] && (
              <FloatingImage
                position={{ x: -3.03, y: 0.608, z: 2.542 }}
                rotation={{ x: -40.9, y: 106.4, z: -0.57 }}
                to={{ x: -3.03, y: 0.618, z: 2.542 }}
                image={this.state.images[0]}
                smaller={this.state.mobile}
              />
            )}

            {this.state.images[1] && (
              <FloatingImage
                position={{ x: -2.988, y: 0.615, z: 2.495 }}
                rotation={{ x: -44.2, y: -17, z: -7.79 }}
                to={{ x: -2.988, y: 0.625, z: 2.495 }}
                image={this.state.images[1]}
                smaller={this.state.mobile}
              />
            )}

            {this.state.images[2] && (
              <FloatingImage
                position={{ x: -2.982, y: 0.608, z: 2.576 }}
                rotation={{ x: -33.5, y: -159, z: 18.22 }}
                to={{ x: -2.982, y: 0.618, z: 2.576 }}
                image={this.state.images[2]}
                smaller={this.state.mobile}
              />
            )}

            {this.state.images[3] && (
              <FloatingImage
                position={{ x: -2.974, y: 0.565, z: 2.532 }}
                rotation={{ x: -67.5, y: -97.1, z: 24.6 }}
                to={{ x: -2.974, y: 0.555, z: 2.532 }}
                image={this.state.images[3]}
                smaller={this.state.mobile}
              />
            )}
          </Entity>
        )}
      </Entity>
    );
  }
}

export default FloatingImages;
