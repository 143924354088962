import AFRAME from 'aframe';

AFRAME.registerComponent('fps-counter', {
  init () {
    this.frames = 0;
    this.fps = 0;
    this.prevTime = 0;
    this.arrayFPS = [];

    this.currAvg = 0;
    this.average = 0;
    this.emitted = false;
    this.lastTime = 0;
    this.ready = true;
  },

  tick (t) {
    const self = this;
    const interval = 1000;
    const time = Date.now();

    this.frames++;

    if (this.prevTime == null) this.prevTime = time;

    if (time > this.prevTime + interval) {
      this.fps = Math.round(this.frames * 1000 / (time - this.prevTime));
      this.frames = 0;
      this.prevTime = time;

      this.arrayFPS.push(this.fps);
      if (this.arrayFPS.length >= 5) {
        if (this.arrayFPS.length > 5) {
          this.arrayFPS.shift();
        }
        this.arrayFPS.forEach((val) => {
          self.currAvg += val;
        });

        this.currAvg /= 5;
        this.average = this.currAvg;

        // console.log(this.average);

        if (this.average < 30 && !this.emitted) {
          this.el.emit('lowFPS');
          this.emitted = true;
          this.ready = false;
          this.lastTime = t;
        } else if (this.average >= 55 && this.ready && this.emitted) {
          this.el.emit('highFPS');
          this.emitted = false;
        }

        if (this.emitted && !this.ready) {
          if (t >= this.lastTime + 3000) {
            this.ready = true;
          }
        }

        this.currAvg = 0;
      }
    }
  }
});
