import { Entity } from 'aframe-react';
import React, { Component } from 'react';

import { checkResolution, isGif } from 'util';

// Entities

// DATA
import axios from 'axios';
import config from 'srcDir/config';
import colours from 'srcDir/data/idleColours';
import { forEach, shuffle } from 'lodash';
import { Macro, SceneModel } from '../../../Entities';
import Blob from './Blob';

class MainScreen extends Component {
  constructor (props) {
    super(props);

    this.state = {
      projectIndex: 0,
      animate: false,
      mix: true,
      colour: '#00c181'
    };

    this.loaded = false;
    this.reset = false;
    this.paused = true;
    this.lowRes = false;
    this.indexing = false;

    this.isSmall = false;
    this.oldColour = 0xfffff;
    this.pausedColour = '#00c3ff';
  }

  play = () => {
    this.reset = true;
    this.setState({ mix: false });

    this.paused = false;
    // this.indexing = false;

    if (!this.loaded) {
      this.loaded = true;
    }
    
    if (this.state.projects && !this.indexing) {
      this.nextProject();
      this.imageTimer = setTimeout(this.nextImage, 3000);
    }
  };

  pause = () => {
    this.reset = true;
    this.paused = true;
    this.indexing = false;

    this.oldColour = this.state.colour;

    this.setState({ image: null });

    clearTimeout(this.imageTimer);

    this.renderColour();
  };

  resetAnimation = () => {
    if (this.reset && (this.loaded || this.paused)) {
      this.setState({
        animate: false,
        mix: true
      });
      this.reset = false;
    } else if (!this.state.animate && (this.loaded || this.paused)) {
      this.setState({ animate: true });
    }
  };
  
  getProject = (index) => {
    const projects = this.state.projects;
    const project = projects[index];
    
    if (projects.length === 0 || !project) return;

    this.resetAnimation();
    
    let imageCount = 4;
    if(this.indexing) imageCount = 8;

    let images = shuffle(project.images).slice(0, imageCount);
    
    const {image, imageWidth, imageHeight} = this.getScreenImage(images, 0);

    this.setState({
      projectIndex: index,
      images,
      imageIndex: 0,
      colour: project.colour,
      image,
      imageWidth,
      imageHeight
    },
    () => {
      if (this.loaded) {
        clearTimeout(this.colourTimer);
        this.renderProjectTitle(project.title, project.abstract, project.description);
        this.renderScreenImage();
        this.imageTimer = setTimeout(this.nextImage, 3000);
      } else {
        this.pause();
      }
    });
  };
  
  nextProject = () => {
    if(this.indexing) return false;
    
    let index = this.state.projectIndex;

    if (index === this.state.projects.length - 1) {
      index = 0;
    } else {
      index++;
    }
    
    this.getProject(index);
  };

  getScreenImage = (images, imageIndex) => {
    if (!images) return false;

    if (this.reset) {
      this.setState({ colour: this.oldColour });
    }
    this.resetAnimation();

    const currentImage = images[imageIndex];
        
    let source = currentImage.url;
    if (this.isSmall) {
      source = currentImage.smallUrl;
    }

    return {
      image: source,
      imageWidth: currentImage.width,
      imageHeight: currentImage.height
    };
  };
  
  renderScreenImage = () => {
    const {image, imageWidth, imageHeight} = this.getScreenImage(this.state.images, this.state.imageIndex);
        
    this.setState({
      image,
      imageWidth,
      imageHeight
    });
  };

  renderColour = () => {
    if (this.paused) {
      clearTimeout(this.colourTimer);

      this.resetAnimation();

      let newColour = this.pausedColour;
      let found = false;
      while (!found) {
        newColour = colours[Math.floor(Math.random() * colours.length)];
        if (newColour !== this.pausedColour) {
          found = true;
          this.pausedColour = newColour;
        }
      }

      this.setState({
        colour: newColour
      });

      this.colourTimer = setTimeout(this.renderColour, 3000);
    }
  };

  renderProjectTitle = (title, abstract, description) => {
    const scene = document.querySelector('a-scene');
    scene.emit('dong', { value: title });
    
    setTimeout(() => {
      const scene = document.querySelector('a-scene');
      scene.emit('dong', { title: title, abstract: abstract, description: description });
    }, 100);  
  };

  componentDidMount () {
    this.isSmall = checkResolution();

    const loader = document.querySelector('#lab_loader');
    this.screen = document.querySelector('#lab_main_screen');

    this.getProjects();
    
    document.addEventListener('getProject', this.__onIndex, false);

    loader.addEventListener('load-now', this.play.bind(this), false);
    loader.addEventListener('remove', this.pause.bind(this), false);

    this.screen.addEventListener('image-loaded', this.__onImageLoaded, false);

    if (!this.isSmall) {
      document.querySelector('a-scene')
        .addEventListener('lowFPS', this.__onLowFPS, false);
      document.querySelector('a-scene')
        .addEventListener('highFPS', this.__onHighFPS, false);
    }
  }

  __onImageLoaded = () => {
      this.renderScreenImage();
  }
  
  nextImage = () => {
    clearTimeout(this.imageTimer);
    
    if (!this.paused) {
      let index = this.state.imageIndex;
      
      if (index === this.state.images.length - 1 && !this.indexing) {
        this.imageTimer = setTimeout(this.nextProject, 3000);
      } else {
        
        if (index === this.state.images.length - 1) {
          index = 0;
        } else {
          index++;
        }
        
        this.setState({ imageIndex: index }, () => {
          this.renderScreenImage();
        });
        this.imageTimer = setTimeout(this.nextImage, 3000);
      }
    }
  }

  __onLowFPS = () => {
    this.lowRes = true;
  };

  __onHighFPS = () => {
    this.lowRes = false;
  };
  
  __onIndex = (evt) => {
    this.indexing = true;
    clearTimeout(this.projectTimer);

    const projects = this.state.projects;
    const indexedTitle = evt.detail.title;
    const index = projects.map(e => e.title).indexOf(indexedTitle);
        
    if (index !== -1) {
      this.setState({ 
        projectIndex: index
      }, () => {
        this.setProject(index);
      });
    }
  }
  
  clearScreen = (cb) => {
    // const labImage = this.screen.components['lab-image'];
    // 
    // if(labImage) {
    //   labImage.__onRemove()
    // }
    this.setState({ image: null }, cb);
  }
  
  setProject = (newIndex) => {
    this.clearScreen(() => {
      this.getProject(newIndex);
      this.renderScreenImage();
    });
  };

  getProjects = () => {
    axios.get(`${config.API_URL}/project`)
      .then((res) => {
        let projects = [];

        forEach(res.data, (val) => {
          const obj = {
            title: val.title,
            colour: val.colour,
            abstract: val.abstract,
            description: val.description,
            images: []
          };
          
          
          // Remove GIFs from array
          let images = val.images.filter(item => {
            return !isGif(item.url);
          });
          
          if (images.length === 0 || !val.enabled) return;

          forEach(images, (img) => {
            if(img['512'] !== undefined) {
              obj.images.push({
                id: img.id,
                url: config.API_URL + img['1024'],
                smallUrl: config.API_URL + img['512'],
                width: img.width,
                height: img.height
              });
            }
          });

          projects.push(obj);
        });

        projects = shuffle(projects);

        this.setState({ projects }, this.nextProject);
      });
  };

  render () {
    return (
      <Entity>
        <SceneModel
          id="lab_model"
          src="./models/lab/lab"
          tint={this.state.colour}
          animate={this.state.animate}
          rotation={{ x: 0, y: 180, z: 0 }}
        />

        <Macro rotation={{ x: 0, y: 180, z: 0 }}>

          <Entity
            id="lab_loader"
            position={{ x: 0.259, y: 3.668, z: 6.201 }}
            scale={{ x: -1, y: 1, z: 1 }}
            asset-loader={{
              cameras: ['lab_outside_cam', 'lab_in_cam', 'blob_cam']
            }}
          >
            <Entity
              id="lab_main_screen"
              position={{ x: 0.037, y: 0.096, z: -0.002 }}
              scale={{ x: 2.25, y: 2.25, z: 2.25 }}
              lab-image={{
                src: this.state.image,
                width: this.state.imageWidth,
                height: this.state.imageHeight
              }}
            />
          </Entity>

          <Blob
            colour={this.state.colour}
            mix={this.state.mix}
          />
        </Macro>
      </Entity>
    );
  }
}

export default MainScreen;
