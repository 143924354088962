import AFRAME from 'aframe';
import { isMobile } from 'react-device-detect';

AFRAME.registerComponent('replayer', {
  multiple: true,
  schema: {
    cameras: { type: 'array', default: [] },
    current: { type: 'string', default: '' }
  },

  init () {
    this.playing = false;
    this.firstLoad = false;
    this._attachEventListeners();
  },

  _attachEventListeners () {
    document.addEventListener('camera-set-active', this.__onCameraActive.bind(this), false);
    document.addEventListener('toggle-captures', this.__onToggleCaptures.bind(this), false);
  },

  __onCameraActive (evt) {
    if (!this.el) return;
    
    if(this.el && this.el.components["capture-replayer"]) {
      this.el.components['capture-replayer'].resetRecording();
    }
    
    const newCam = evt.detail.cameraEl;
    
    if (!newCam.components['replayer']) return;
    
    const newCameras = newCam.components['replayer'].data.cameras;

    // Check if this camera is in the play list of the new camera
    if(newCameras.indexOf(this.el.id) !== -1) {
      this.startReplaying(newCam.id == this.el.id);
    } else {
      this.stopReplaying();
    }
  },

  __onToggleCaptures (evt) {
    const disabled = evt.detail;
    if (!this.el.components['capture-replayer']) return;

    if (disabled) {
      this.el.components['capture-replayer'].stopReplaying();
      this.el.components['look-controls-alt'].play();
    } else {
      if (!this.replayData) return;
      this.el.components['capture-replayer'].startReplaying(this.replayData.camera, false);
      this.el.components['look-controls-alt'].pause();
    }
  },

  remove () {
    this.stopReplaying();
  },
  
  setReplayData (replayData) {
    this.replayData = replayData;
    this.el.setAttribute('capture-replayer', '');
  },

  /**
   * Set player on camera and controllers (marked by ID).
   *
   * @params {object} replayData - {
   *   camera: {poses: [], events: []},
   *   [c1ID]: {poses: [], events: []},
   *   [c2ID]: {poses: [], events: []}
   * }
   */
  startReplaying (on) {
    const replayData = this.replayData;
        
    const captureReplayer = this.el.components['capture-replayer'];
    
    if(!captureReplayer) {
      this.el.setAttribute('capture-replayer', '');
    }
    
    captureReplayer.startReplaying(replayData['camera'], on);
  },

  /**
   * Remove capture-replayer components.
   */
  stopReplaying () {
    if (!this.el) return;
    this.el.removeComponent('capture-replayer');
  },

  resetReplayer (onCamera) {
    if (!this.replayData) {
      return;
    }

    if (this.el && onCamera) {
      this.el.components['capture-replayer'].resetRecording();

      if (!isMobile) {
        return;
      }
      this.el.components['look-controls-alt'].play();
    }
    this.el.components['capture-replayer'].setActive(onCamera);
  }
});