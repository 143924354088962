import { Entity } from 'aframe-react';
import React, { Component } from 'react';

// Entities
import { Macro, Camera, SceneModel } from '../../../Entities';

import Inner from './Inner';

import Lift from './Lift';

class StormDrain extends Component {
  render () {
    return (

      <Entity
        position={{ x: -0.320, y: 0.103, z: 0.700 }}
        id="stormdrain"
      >

        <SceneModel
          id="stormdrain_outer_model"
          rotation={{ x: 0, y: 180, z: 0 }}
          src="./models/stormdrain/stormdrain-outer"
          tint="#AAC3EE"
          blend="overlay"
        />

        <Inner />

        <SceneModel
          id="stormdrain_cables_model"
          src="./models/stormdrain/stormdrain_cables"
          tint="#6e767b"
        />

        <Macro
          rotation={{ x: 0, y: 0, z: 0 }}
        >

          <Lift />

          <Camera
            position={{ x: 10, y: -19.5, z: 12 }}
            rotation={{ x: 0, y: 0, z: 0 }}
            id="stormdrain_base_cam_1"
            macro
            far={5000}
            cameras={['stormdrain_pillar_cam']}
          />

          <Camera
            position={{ x: -11.785, y: -19.5, z: -15.352 }}
            rotation={{ x: 0, y: 0, z: 0 }}
            id="stormdrain_base_cam_2"
            macro
            far={4000}
            cameras={['stormdrain_pillar_cam']}
          />

          <Camera
            position={{ x: -7.078, y: -19.5, z: 15.64 }}
            rotation={{ x: 0, y: 0, z: 0 }}
            id="stormdrain_pillar_cam"
            macro
            far={4000}
            cameras={['stormdrain_base_cam']}
          />

          <Entity
            position={{
              x: 0,
              y: -11.956,
              z: 0
            }}
            rotation={{
              x: 0,
              y: 45,
              z: 0
            }}
          >
            <Entity
                id="stormdrain_audio_1"
                primitive="a-sound"
                position={{x: 0, y: -4, z: 0}}
                sound={{
                  src: 'url(./audio/Stage2_amb_Cla.mp3)',
                  loop: true,
                  volume: .5,
                  maxDistance: 1,
                  refDistance: 0.01,
                  rolloffFactor: .5
                }}
                sound-controls={{
                  loader: 'stormdrain_loader'
                }}
            />

            <Entity
                id="stormdrain_audio_2a"
                primitive="a-sound"
                position={{x: 0, y: -4, z: 20}}
                sound={{
                  src: 'url(./audio/Stage2_ambR.mp3)',
                  loop: true,
                  volume: .5,
                  maxDistance: 1,
                  refDistance: 0.01,
                  rolloffFactor: .5
                }}
                sound-controls={{
                  loader: 'stormdrain_loader',
                }}
            />

            <Entity
                id="stormdrain_audio_2b"
                primitive="a-sound"
                position={{x: 0, y: -4, z: -20}}
                sound={{
                  src: 'url(./audio/Stage2_ambR.mp3)',
                  loop: true,
                  volume: .5,
                  maxDistance: 1,
                  refDistance: 0.01,
                  rolloffFactor: .5
                }}
                sound-controls={{
                  loader: 'stormdrain_loader',
                }}
            />
          
            <Entity
                id="stormdrain_audio_3"
                primitive="a-sound"
                position={{x: 0, y: 0, z: 0}}
                sound={{
                  src: 'url(./audio/Stage2_obj001.mp3)',
                  volume: 1,
                  poolSize: 5
                }}
                sound-controls={{
                  loader: 'stormdrain_loader',
                  playOnEvent: 'dong'
                }}
            />
          </Entity>

        </Macro>
      </Entity>

    );
  }
}

export default StormDrain;
