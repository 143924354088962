import AFRAME, { THREE } from 'aframe';

const vertShader = require('./shaders/vertex.glsl');
const fragShader = require('./shaders/fragment.glsl');

AFRAME.registerComponent('lab-image', {
  schema: {
    parentLoader: { type: 'string', default: '#lab_loader' },
    src: { type: 'string', default: '' },
    width: { type: 'number', default: 0 },
    height: { type: 'number', default: 0 }
  },

  init () {
    this.parentLoader = this.data.parentLoader;
    this.width = 4.6;
    this.height = 2.6;

    this.aspectRatio = 0.0;

    this.notLoadedTex = new THREE.TextureLoader().load('./img/utility/broken_image_link.png');

    this.createPlane();

    this._attachEventListeners();
  },

  createPlane () {
    const geometry = new THREE.BufferGeometry();

    const w = this.width * 0.5;
    const h = this.height * 0.5;

    const vertices = new Float32Array([-w, -h, 0, w, -h, 0, w, h, 0, -w, h, 0]); // eslint-disable-line no-undef

    const indices = [0, 1, 2, 2, 3, 0];

    const uvs = new Float32Array([ // eslint-disable-line no-undef
      0, 0,
      1, 0,
      1, 1,
      0, 1
    ]);

    geometry.setIndex(indices);
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

    const uniforms = {
      uPaddingOffset: { type: 'f', value: 0.0 },
      uScaleWidth: { type: 'f', value: 0.0 },
      uScaleX: { type: 'f', value: 1.0 },
      uScaleY: { type: 'f', value: 1.0 },
      tImage: { type: 't', value: null },
      uLoaded: { type: 'b', value: true }
    };

    this.material = new THREE.ShaderMaterial({
      uniforms,
      vertexShader: vertShader,
      fragmentShader: fragShader,
      depthTest: true,
      side: THREE.DoubleSide,
      transparent: true
    });

    this.mesh = new THREE.Mesh(geometry, this.material);

    this.el.object3D.add(this.mesh);
  },

  _attachEventListeners () {
    document.querySelector(this.parentLoader).addEventListener('remove',
      this.__onRemove.bind(this), false);
  },

  _removeEventListeners () {
    document.querySelector(this.parentLoader).removeEventListener('remove',
      this.__onRemove, false);
  },

  update () {
    if (this.data.src === '' || this.data.src === null || this.data.src === undefined) {
      this.__onRemove();
      return;
    }

    const src = this.data.src;
    const self = this;

    if (this.texture) {
      this.mesh.visible = false;
      
      this.material.uniforms.tImage.value = null;
      this.texture.dispose();
    }

    const texture = this.texture = new THREE.TextureLoader().load(src,
      () => {
        self.imageAspectRatio = self.data.width / self.data.height;
        self.textureAspectRatio = texture.image.width / texture.image.height;

        self.orientation = self.data.width > self.data.height ? 'landscape' : 'portrait';

        let wScale = 1;
        let hScale = 1;

        if (self.orientation === 'portrait') {
          wScale = self.imageAspectRatio / self.textureAspectRatio;
        } else {
          hScale = self.textureAspectRatio / self.imageAspectRatio;
        }

        self.material.uniforms.uScaleX.value = wScale;
        self.material.uniforms.uScaleY.value = hScale;

        const aspectRatio = self.data.width / self.data.height;

        const width = self.height * aspectRatio;
        self.aspectRatio = self.width / width;

        self.material.uniforms.uPaddingOffset.value = (1.0 - width / self.width) / 2;

        self.material.uniforms.uScaleWidth.value = self.aspectRatio;

        self.material.uniforms.uLoaded.value = true;
        self.material.uniforms.tImage.value = texture;

        self.el.emit('image-loaded');
        this.mesh.visible = true;
      },

      undefined,

      () => {
        self.material.uniforms.uLoaded.value = false;
        self.material.uniforms.tImage.value = self.notLoadedTex;

        self.el.emit('image-loaded');
      });
  },

  __onRemove () {
    if (this.material) {
      this.material.uniforms.tImage.value = null;
      this.material.needsUpdate = true;
    }
  },

  remove () {
    this._removeEventListeners();
    if (this.mesh) {
      this.mesh.geometry.dispose();
      this.el.object3D.remove(this.mesh);
    }
    if (this.notLoadedTex) this.notLoadedTex.dispose();
    if (this.texture) this.texture.dispose();
    if (this.material) this.material.dispose();

    this.mesh = null;
    this.notLoadedTex = null;
    this.texture = null;
    this.material = null;
  }
});
