import { Entity } from 'aframe-react';
import React, { Component } from 'react';

// Entities
import config from 'srcDir/config';
import axios from 'axios';
import { Camera, Macro } from '../../Entities';
import Image from './Image';
import Model from './Model';

// Data

class Golem extends Component {
  constructor (props) {
    super(props);

    this.state = {
      bones: null,
      visible: true,
      itemsCount: 0,
      abstract: '',
      description: ''
    };
  }

  componentDidMount () {
    document.querySelector('a-scene')
      .addEventListener('camera-set-active', this.__onCameraSwitch, false);

    axios.get(`${config.API_URL}/golem`)
      .then((res) => {
        const bones = [];

        res.data.forEach((val) => {
          if (val.bone == null) return;

          const bone = {
            id: val.id,
            attachTo: val.bone.reference,
            scale: val.scale,
            image: val.image,
            model: val.model,
            flip: val.flip
          };

          bones.push(bone);
        });

        this.setState({
          bones: bones.map(this.renderBones)
        }, () => this.setState({ itemsCount: res.data.length }));
      });
      
    // this.getCaption();
  }
  
  
  getCaption = () => {
    axios.get(`${config.API_URL}/areas/`)
      .then((res) => {
        const data = res.data;
        
        const item = data.find((el) => {
          return el.name == 'golem';
        });
        
        if(!item) return;
        
        this.setState({
          abstract: item.abstract,
          description: item.description
        });
      });
  };

  __onCameraSwitch = (evt) => {
    this.activeCam = evt.detail.cameraEl.id;

    if (this.activeCam === 'golem_head_cam') {
      this.setState({ visible: false });
    } else if (!this.state.visible) {
      this.setState({ visible: true });
    }
    
    if (this.activeCam === 'golem_cam') {
      const scene = document.querySelector('a-scene');
      scene.emit('dong', { title: '', abstract: this.state.abstract, description: this.state.description });
    }
  }

  renderBones = (bone) => {
    if (bone.model !== null && bone.model !== undefined) {
      return (
        <Model
          key={bone.id}
          asset={bone.model}
          attachTo={bone.attachTo}
          scale={bone.scale}
          flip={bone.flip}
        />
      );
    } if (bone.image !== null && bone.image !== undefined) {
      return (
        <Image
          key={bone.id}
          asset={bone.image}
          attachTo={bone.attachTo}
          scale={bone.scale}
          flip={bone.flip}
        />
      );
    }
  };

  render () {
    return (
      <Macro id="golem" position={{ x: -0.451, y: 1.175, z: 0.582 }}>

        <Entity
          id="golem_model"
          golem={{
            model: './models/golem/character.gltf',
            items: this.state.itemsCount
          }}
          scale={{ x: 120, y: 120, z: 120 }}
          rotation={{ x: 0, y: 90, z: 0 }}
          animation-mixer={{
            clip: 'idle'
          }}
          visible
        >

          <Camera
            id="golem_head_cam"
            position={{ x: 0.98, y: -0.5, z: -0.075 }}
            avatar={false}
            near={0.1}
            far={5000}
          />

          {this.state.bones}

          <Entity
            id="golem_loader"
            asset-loader={{
              cameras: [
                'museum_display_box_cam',
                'golem_cam',
                'golem_head_cam',
                'stormdrain_lift_cam',
                'bench_cam',
                'security_cam'
              ]
            }}
          />

        </Entity>

      </Macro>
    );
  }
}

export default Golem;
