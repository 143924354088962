const data = [
// COLOURS
  [
    {
      colorA: '#e3cbfc',
      colorB: '#6a82fb',
      colorC: '#55fff6'
    },
    {
      colorA: '#fcbcf6',
      colorB: '#fb8b85',
      colorC: '#ffd6a8'
    },
    {
      colorA: '#baa7fc',
      colorB: '#0038fb',
      colorC: '#dba9ff'
    },
    {
      colorA: '#fcf9f6',
      colorB: '#fbb2c4',
      colorC: '#ff7375'
    }
  ],

  // MODELS
  [{
    src: './models/avatar/blobbo_rosie.gltf',
    depth: 1.1,
    offset: 0.3,
    noseOffset: 0.1,
    axis: '1'
  },
  {
    src: './models/avatar/blobbo_amy.gltf',
    depth: -300,
    offset: 0.2,
    noseOffset: 0.3,
    axis: '2'
  },
  {
    src: './models/avatar/blobbo_ben.gltf',
    depth: -4,
    offset: 0.32,
    noseOffset: 0.150,
    axis: '2'
  }]

];

export default data;
