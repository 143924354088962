import { isMobile } from 'react-device-detect';

export const isCapturing = () => document.body.classList.contains('--captures');

export const isHighRes = () => {
  // override isMobile
  const urlParams = new URLSearchParams(window.location.search);
  const isHighRes = urlParams.get('high-res');
  if (isMobile && isHighRes) {
    return true;
  }
  return false;
};

export const isMuted = () => document.body.classList.contains('--mute');

export const isLowRes = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isLowRes = urlParams.get('low-res');
  if (!isMobile && isLowRes) {
    return true;
  }
  return false;
};

export const checkResolution = () => {
  if (isHighRes() && !isCapturing()) {
    return false;
  } if (isLowRes() && !isCapturing()) {
    return true;
  }
  return (isCapturing() || isMobile);
  // return true;
};

export const isPlaybackDisabled = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const disablePlayback = urlParams.get('disable-playback');
  return disablePlayback;
};

export const isSwitchDisabled = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const disableSwitch = urlParams.get('disable-switch');
  return disableSwitch;
};

export const isGif = (fileName) => {
  return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase() == 'gif';
};