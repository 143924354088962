import React from 'react';

const MobileCursor = () => (
  <div className="mobile-prev-next">
    <button id="mobile_prev_button">
      <img src="./img/ui/icons/prev-cursor.svg" alt="Previous cursor" />
    </button>

    <button id="mobile_next_button">
      <img src="./img/ui/icons/next-cursor.svg" alt="Next cursor" />
    </button>
  </div>
);

export default MobileCursor;
