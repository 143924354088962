import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('golem', {
  schema: {
    model: { type: 'string' },
    items: { type: 'int', default: 0 }
  },

  init () {
    this.modelLoaded = false;

    this.emptyBones = [];
    this.headBone = null;

    this.loadModel();
    this.loaded = false;
  },

  loadModel () {
    const self = this;
    const el = this.el;

    this.model = null;
    this.modelLoader = new THREE.GLTFLoader();
    const golem = this.data.model;

    this.modelLoader.load(golem,
      (gltfModel) => {
        this.model = gltfModel.scene || gltfModel.scenes[0];
        this.model.animations = gltfModel.animations;
        el.setObject3D('mesh', self.model);

        this.model.traverse((child) => {
          if (child instanceof THREE.Bone) {
            if (child.name === 'Armature_mixamorigNeck') {
              self.headBone = child;
            }
            self.emptyBones.push(child);
          }
          if (child.type === 'SkinnedMesh') {
            child.visible = false;
          }
        });
        if (this.data.items !== 0) {
          this.attachItems();
        }
        this.modelLoaded = true;
        this._attachEventListeners();
      });
  },

  _attachEventListeners () {
    this.el.sceneEl.addEventListener('camera-set-active', this.__onCameraSwitch.bind(this), false);
  },

  update () {
    const self = this;
    if (this.modelLoaded && !this.loaded && this.data.items !== 0) {
      setTimeout(() => {
        self.attachItems();
      }, 3000);
    }
  },

  __onCameraSwitch (evt) {
    if (evt.detail.cameraEl.id === 'golem_head_cam') {
      this.headBone.visible = false;
    } else if (!this.headBone.visible) {
      this.headBone.visible = true;
    }
  },

  attachItems () {
    const children = this.el.getChildEntities();
    const self = this;

    children.forEach((childEl) => {
      const parent = childEl.getAttribute('parent-bone');
      if (parent === null) {
        return false;
      }
      const scale = childEl.getAttribute('scale-bone');
      const flip = childEl.getAttribute('flip-bone') === 'true';

      self.attachTo(childEl, parent, scale, flip);
    });

    this.el.emit('model-loaded', { format: 'gltf', model: this.model });
    this.loaded = true;
    this.el.setAttribute('loaded', true);
  },

  attachTo (childEl, parentBone, scale, flip) {
    const child = childEl.object3D;
    const sceneEl = this.el.sceneEl;
    let bone = null;

    for (let i = 0; i < this.emptyBones.length; i++) {
      if (this.emptyBones[i].name === parentBone) {
        bone = this.emptyBones[i];
        this.emptyBones.splice(i, 1);
      }
    }
    // remove child from scene and add it to parent
    const newScale = scale * 15;
    child.scale.set(newScale, newScale, newScale);

    child.position.set(0, 0, 0);
    child.rotation.set(Math.PI, 0, 0);

    if (flip) child.rotation.set(0, 0, 0);

    sceneEl.remove(child);
    bone.add(child);
  },
  
  __removeEventListeners () {
    this.el.sceneEl.removeEventListener('camera-set-active', this.__onCameraSwitch);
  },

  remove () {
    this.__removeEventListeners();
    this.el.removeObject3D('mesh');
    this.model = null;
  }

});
