import React, { Component } from 'react';

import Corners from './Corners';
import Message from './Message';
import Cursor from './Cursor';
import MobileCursor from './MobileCursor';

import Tutorial from './Tutorial';

class Interface extends Component {
  constructor (props) {
    super(props);

    this.state = {
      tutorial: true
    };
  }

  finishedTutorial = () => {
    this.setState({ tutorial: false });

    const scene = document.querySelector('a-scene');
    scene.emit('finishedTutorial', null);
    
    // const event = new CustomEvent('switch-camera', { detail: 'next' });
    // document.dispatchEvent(event);
  }

  render () {
    return (
      <div className="interface-wrapper">
        { this.state.tutorial
          ? <Tutorial handleFinishedTutorial={this.finishedTutorial} />
          : (
            <div>
              <Corners />
              <Message />
            </div>
          )
        }

        <MobileCursor />
        <Cursor ignoreClick={this.state.tutorial} />
      </div>
    );
  }
}

export default Interface;
