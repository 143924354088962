import AFRAME, { THREE } from 'aframe';

import { checkResolution } from 'util';

AFRAME.registerComponent('videosphere', {
  schema: {
    src: { type: 'string', default: '' },
    width: { type: 'number', default: 4096 },
    height: { type: 'number', default: 2048 },
    active: { type: 'boolean', default: false }
  },

  init () {
    this.mesh = document.querySelector('#planetarium_screen_model');
    this.textureCache = {};
    this.loadVideo();
  },
  
  update (oldData) {
    if(oldData.active == this.data.active) {
      return;
    }
    
    if(this.data.active) {
      this.playVideosphere();
    } else {
      this.stopVideosphere();
    }
  },

  loadVideo () {
    let videoTextureResult;

    const handleVideoTextureLoaded = (result) => {
      result.texture.needsUpdate = true;
      this.setMaterial(result.texture, result.videoEl);
    };

    const videoScale = checkResolution() ? 0.5 : 1;
    
    const videoEl = this.videoEl = this.createVideoEl(this.data.src, this.data.width * videoScale, this.data.height * videoScale);
    const texture = this.texture = new THREE.Texture(videoEl);
    
    texture.minFilter = THREE.LinearFilter;
    texture.maxFilter = THREE.LinearFilter;
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;

    texture.format = THREE.RGBFormat;
    texture.flipY = false;
    texture.needsCorrectionFlipY = true;
  
    videoEl.addEventListener("playing", () => {
      if(!videoEl) return;
      
      this.interval = setInterval(() => {
        if (videoEl.readyState >= videoEl.HAVE_CURRENT_DATA) {
          texture.needsUpdate = true;
        }
      }, 1000 / 29.97);
      
      videoTextureResult = {texture: texture, videoEl: videoEl};
      handleVideoTextureLoaded(videoTextureResult);
    }, true);
  },
  
  playVideosphere () {
    this.videoEl.play();
    
    if(this.material) {
      this.material.uniforms.map.value = this.texture;
    }
  },
  
  stopVideosphere () {
    this.videoEl.pause();
    
    if (this.material) {
      this.material.uniforms.map.value = null;
    }

    clearInterval(this.interval);
  },
  
  createVideoEl (src, width, height) {
    const videoEl = document.createElement('video');
    videoEl.width = width;
    videoEl.height = height;

    // Support inline videos for iOS webviews.
    videoEl.setAttribute('playsinline', '');
    videoEl.setAttribute('webkit-playsinline', '');
    videoEl.loop = true;
    videoEl.muted = true;
    videoEl.crossOrigin = 'anonymous';

    videoEl.src = src;
      
    return videoEl;
  },

  setMaterial (texture) {
    const model = this.mesh.object3D;

    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        this.material = child.material;
        this.material.uniforms.map.value = texture;
      }
    });
  },

  remove () {
    if (this.material) {
      this.material.uniforms.map.value = null;
      this.material.dispose();
    }
    if (this.texture) this.texture.dispose();
    
    this.material = null;
    this.texture = null;
    this.videoEl = null;
    
    clearInterval(this.interval);
  }
});