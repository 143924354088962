import AFRAME from 'aframe';

/**
 * Modified from Kevin Ngo's A-frame layout component
 *
 * Original Author : Kevin Ngo
 * Link: https://github.com/Ionaut/aframe-layout-component/blob/master/index.js
 */
AFRAME.registerComponent('random-layout', {
  schema: {
    count: { default: 1, min: 0, if: { type: ['random'] } },
    radius: { default: 1, min: 0, if: { type: ['random'] } },
    bboxY: { default: 1, min: 0, if: { type: ['random'] } }
  },

  /**
   * Store initial positions in case need to reset on component removal.
   */
  init () {
    const self = this;
    const el = this.el;

    this.children = el.getChildEntities();
    this.initialPositions = [];
    this.initialRotations = [];

    this.children.forEach((childEl) => {
      if (childEl.hasLoaded) {
        return _getPositions();
      }
      childEl.addEventListener('loaded', _getPositions);
      function _getPositions () {
        const position = childEl.getAttribute('position');
        self.initialPositions.push([position.x, position.y, position.z]);
      }
    });

    this.children.forEach((childEl) => {
      if (childEl.hasLoaded) {
        return _getRotations();
      }
      childEl.addEventListener('loaded', _getRotations);
      function _getRotations () {
        const rotation = childEl.getAttribute('rotation');
        self.initialRotations.push([rotation.x, rotation.y, rotation.z]);
      }
    });

    el.addEventListener('child-attached', (evt) => {
      // Only update if direct child attached.
      if (evt.detail.el.parentNode !== el) {
        return;
      }
      self.children.push(evt.detail.el);
      self.update();
    });

    el.addEventListener('child-detached', (evt) => {
      // Only update if direct child detached.
      if (self.children.indexOf(evt.detail.el) === -1) {
        return;
      }
      self.children.splice(self.children.indexOf(evt.detail.el), 1);
      self.initialPositions.splice(self.children.indexOf(evt.detail.el), 1);
      self.initialRotations.splice(self.children.indexOf(evt.detail.el), 1);
      self.update();
    });
  },

  /**
   * Update child entity positions.
   */
  update () {
    const children = this.children;
    const data = this.data;
    let definedData;
    const el = this.el;
    let positionFn;
    let rotationFn;
    let positions;
    let rotations;

    positionFn = getRandomPositions;
    rotationFn = getRandomRotations;

    definedData = el.getDOMAttribute('random-layout');
    positions = positionFn(
      data,
      typeof definedData === 'string'
        ? definedData.indexOf('margin') !== -1
        : 'margin' in definedData
    );
    if (data.reverse) {
      positions.reverse();
    }
    setPositions(children, positions);

    rotations = rotationFn(
      data,
      typeof definedData === 'string'
        ? definedData.indexOf('margin') !== -1
        : 'margin' in definedData
    );
    if (data.reverse) {
      rotations.reverse();
    }
    setRotations(children, rotations);
  },

  /**
   * Reset positions.
   */
  remove () {
    this.el.removeEventListener('child-attached', this.childAttachedCallback);
    setPositions(this.children, this.initialPositions);
    setRotations(this.children, this.initialRotations);
  }
});

export function getRandomPositions (data) {
  // get random point in a CIRCLE
  let position;
  const positions = [];
  const radius = data.radius;
  const sizeY = data.bboxY;

  for (let i = 0; i < data.count; i++) {
    let a = Math.random();
    let b = Math.random();

    if (b < a) {
      const temp = b;
      b = a;
      a = temp;
    }

    position = [0, 0, 0];
    position[0] = b * radius * Math.cos(2 * Math.PI * (a / b));
    position[1] = Math.random() * sizeY - sizeY / 2.0;
    position[2] = b * radius * Math.sin(2 * Math.PI * (a / b));
    positions.push(position);
  }

  return positions;
}

export function getRandomRotations (data) {
  let rotation;
  const rotations = [];

  for (let i = 0; i < data.count; i++) {
    rotation = [0, 0, 0];
    rotation[0] = Math.random() * 360;
    rotation[1] = Math.random() * 360;
    rotation[2] = Math.random() * 360;
    rotations.push(rotation);
  }

  return rotations;
}
/**
 * Set position on child entities.
 *
 * @param {array} els - Child entities to set.
 * @param {array} positions - Array of coordinates.
 */
function setPositions (els, positions) {
  els.forEach((el, i) => {
    const position = positions[i];
    el.setAttribute('position', {
      x: position[0],
      y: position[1],
      z: position[2]
    });
  });
}

/**
 * Set rotation on child entities.
 *
 * @param {array} els - Child entities to set.
 * @param {array} rotations - Array of rotation coordinates.
 */

function setRotations (els, rotations) {
  els.forEach((el, i) => {
    const rotation = rotations[i];
    el.setAttribute('rotation', {
      x: rotation[0],
      y: rotation[1],
      z: rotation[2]
    });
  });
}
