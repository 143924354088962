import AFRAME, { THREE } from 'aframe';
import { checkResolution } from 'util';

const vertexShader = require('./shaders/vertex.glsl');
const fragmentShader = require('./shaders/fragment.glsl');

AFRAME.registerComponent('type-move', {
  schema: {
    source: { type: 'string' },
    tint: { type: 'color', default: 'white' },
    src: { type: 'map' },
    color: { type: 'string', default: 'blue' },
    texture: { type: 'map' },
    projectImages: { type: 'array' },
    title: { type: 'string', default: '' },
    parentLoader: { type: 'string', default: '' },
    invert: { type: 'boolean', default: false }
  },

  init () {
    this.model = null;
    this.modelLoader = new THREE.GLTFLoader();
    this.textureLoader = new THREE.TextureLoader();

    this.isSmall = checkResolution();
    this.loaded = false;
    this.firstCall = true;
    this.loader = document.querySelector(this.data.parentLoader);

    this.oldTint = this.newTint = new THREE.Color(this.data.tint);
    this.newColour = new THREE.Color(this.data.color);

    this.repeats = 4.0;
    this.lowRes = false;

    this.paused = true;

    this.createMaterial();
    this.loadModel();
    this.createImageCanvas();

    this._attachEventListeners();
  },

  _attachEventListeners () {
    this.loader.addEventListener('load-now', this.__onCameraLoad.bind(this), false);
    this.loader.addEventListener('remove', this.__onRemove.bind(this), false);

    document.querySelector('a-scene')
      .addEventListener('lowFPS', this.__onLowFPS.bind(this), false);
    document.querySelector('a-scene')
      .addEventListener('highFPS', this.__onHighFPS.bind(this), false);
  },

  __onLowFPS () {
    this.lowRes = true;
    this.createImageCanvas();
  },

  __onHighFPS () {
    this.lowRes = false;
    this.createImageCanvas();
  },

  __onCameraLoad () {
    this.paused = false;
    if (!this.loaded) {
      this.loaded = true;
      if (!this.imageTexture) {
        this.loadImages(this.updateTexture);
      }
    }
  },

  removeTextures () {
    if (this.imageTexture) {
      this.imageTexture.dispose();
      this.imageTexture = null;
      this.material.uniforms.imageTexture.value = null;
    }
    if (this.typeTexture) {
      this.typeTexture.dispose();
      this.typeTexture = null;
      this.material.uniforms.typeTexture.value = null;
    }

    this.material.uniforms.hasTexture.value = false;
  },

  __onRemove () {
    this.paused = true;
    if (this.loaded) {
      this.removeTextures();
      if (this.images) {
        this.images = null;
      }
      this.loaded = false;
    }
  },

  loadImages (cb) {
    this.imagesLoaded = 0;
    this.images = [];

    const images = this.data.projectImages;

    this.drawPortfolioImage(images[0], 0, cb);
    this.drawPortfolioImage(images[1], 1, cb);
    this.drawPortfolioImage(images[2], 2, cb);
  },

  drawPortfolioImage (src, pos, cb) {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = src;

    const self = this;

    img.onload = function () {
      if (img.src !== self.data.projectImages[pos]) {
        return false;
      }

      let left = 10;
      let top = 0;

      let width; let
        height;

      self.imagesLoaded++;

      let divisor = 2;
      if (self.isSmall || self.lowRes) divisor = 4;

      switch (pos) {
        case 0:
          top = 1320 / divisor;

          height = 720 / divisor;
          width = this.width / this.height * 720 / divisor;
          break;
        case 1:
          left = 400 / divisor;
          top = 320 / divisor;

          height = 800 / divisor;
          width = this.width / this.height * 800 / divisor;
          break;
        case 2:
          left = 1200 / divisor;
          top = 1200 / divisor;

          height = 500 / divisor;
          width = this.width / this.height * 500 / divisor;
          break;
        default:
      }

      const obj = {
        img,
        top,
        left,
        width,
        height
      };
      if (self.images) {
        self.images.push(obj);
      }

      if (self.imagesLoaded === 3 && self.images) {
        self.drawImages(cb, pos);
      }
    };
  },

  drawImages (cb) {
    const self = this;

    this.imageContext.clearRect(
      0, 0,
      this.imageCanvas.width,
      this.imageCanvas.height
    );

    for (let i = 0; i < this.images.length; i++) {
      const image = this.images[i];
      self.imageContext.drawImage(
        image.img,
        image.left,
        image.top,
        image.width,
        image.height
      );
    }

    this.removeTextures();
    this.imageTexture = new THREE.Texture(this.imageCanvas);

    this.createTypeCanvas();
    this.addText(cb);

    if (!this.material.uniforms.hasTexture.value) {
      this.material.uniforms.hasTexture.value = true;
    }

    this.el.emit('stormdrain-textures-loaded');
  },

  loadModel () {
    const self = this;
    const el = this.el;
    let src = this.data.source;

    if (!src) {
      return;
    }
    if (this.isSmall) {
      src += '-mobile.gltf';
    } else {
      src += '.gltf';
    }

    this.removeModel();

    this.modelLoader.load(src,
      (gltfModel) => {
        self.model = gltfModel.scene || gltfModel.scenes[0];
        self.model.animations = gltfModel.animations;
        el.setObject3D('mesh', self.model);
        el.emit('model-loaded', { format: 'gltf', model: self.model });
        self.setMaterial(self.model);
        el.setAttribute('loaded', true);
      },
      undefined,
      () => {
        el.emit('model-error', { format: 'gltf', src });
      });
  },

  update () {
    if (this.loaded && this.data.projectImages.length !== 0) {
      // console.log("load new images");
      this.images = null;
      this.loadImages(this.updateTexture);
    }
    if (this.paused && this.material) {
      this.updateColour(this);
    }
    this.newTint = new THREE.Color(this.data.tint);
  },

  removeModel () {
    if (!this.model) {
      return;
    }
    this.el.removeObject3D('mesh');
  },

  createTypeCanvas () {
    if (this.typeCanvas === undefined) {
      this.typeCanvas = document.createElementNS(
        'http://www.w3.org/1999/xhtml',
        'canvas'
      );
    }

    this.typeCanvas.width = 1024/2;
    this.typeCanvas.height = 256/2;

    if (this.isSmall || this.lowRes) {
      this.typeCanvas.width = 1024;
      this.typeCanvas.height = 256;
    }

    this.typeContext = this.typeCanvas.getContext('2d');
  },

  createImageCanvas () {
    if (this.imageCanvas === undefined) {
      this.imageCanvas = document.createElementNS(
        'http://www.w3.org/1999/xhtml',
        'canvas'
      );
    }

    this.imageCanvas.width = 1024;
    this.imageCanvas.height = 1024;

    if (this.isSmall || this.lowRes) {
      this.imageCanvas.width = 512;
      this.imageCanvas.height = 512;
    }
    this.imageContext = this.imageCanvas.getContext('2d');
  },

  addText (cb) {
    const ctx = this.typeContext;
    const canvasWidth = this.typeCanvas.width;
    const canvasHeight = this.typeCanvas.height;

    const size = this.fitTextOnCanvas(this.data.title, 'sans-serif');

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    ctx.font = `bold ${size}px sans-serif`;
    ctx.fontWeight = 400;
    ctx.fillStyle = this.data.invert ? 'black' : 'white';
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    ctx.fillStyle = this.data.invert ? 'white' : 'black';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(this.data.title, canvasWidth / 2, canvasHeight / 2);

    if (this.typeTexture) {
      this.material.uniforms.typeTexture.value = null;
      this.typeTexture.dispose();
      this.typeTexture = null;
    }

    this.typeTexture = new THREE.Texture(this.typeCanvas);

    if (cb) cb(this);
  },

  fitTextOnCanvas (text, fontface) {
    const size = this.measureTextBinaryMethod(
      text,
      fontface,
      25,
      125,
      this.typeCanvas.width * 0.9
    );
    return size;
  },

  measureTextBinaryMethod (text, fontface, min, max, desiredWidth) {
    if (max - min < 1) {
      return min;
    }
    const test = min + (max - min) / 2; // Find half interval
    this.typeContext.font = `${test}px ${fontface}`;
    const measureTest = this.typeContext.measureText(text).width;

    let found;

    if (measureTest > desiredWidth) {
      found = this.measureTextBinaryMethod(
        text,
        fontface,
        min,
        test,
        desiredWidth
      );
    } else {
      found = this.measureTextBinaryMethod(
        text,
        fontface,
        test,
        max,
        desiredWidth
      );
    }
    return found;
  },

  createMaterial () {
    this.material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0.0 },
        imageTexture: { type: 't', value: null },
        typeTexture: { type: 't', value: null },
        shadowTexture: { type: 't', value: null },
        tint: { type: 'c', value: new THREE.Color(this.data.tint) },
        hasTexture: { type: 'i', value: false },
        invert: { type: 'i', value: this.data.invert },
        repeats: { type: 'f', value: this.repeats }
      },
      vertexShader,
      fragmentShader
    });
  },

  updateColour (self) {
    if (self.material) {
      self.oldTint = self.material.uniforms.tint.value;
      self.newTint = new THREE.Color(self.data.tint);
      self.mixTint(self);
    }
  },

  updateTexture (self) {
    if (self.material) {
      self.updateColour(self);

      self.material.uniforms.imageTexture.value = self.imageTexture;
      self.material.uniforms.typeTexture.value = self.typeTexture;
      self.material.uniforms.invert.value = self.data.invert;
    }

    if (self.imageTexture && self.typeTexture) {
      self.imageTexture.needsUpdate = true;
      self.imageTexture.wrapS = THREE.RepeatWrapping;
      self.imageTexture.wrapT = THREE.RepeatWrapping;

      self.typeTexture.needsUpdate = true;
      self.typeTexture.wrapS = THREE.RepeatWrapping;
      self.typeTexture.wrapT = THREE.RepeatWrapping;
    }
  },

  mixTint (self) {
    if (self.oldTint !== self.newTint) {
      self.startAnimation = true;
      self.animating = true;
    }
  },

  setMaterial (model) {
    const self = this;

    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        self.material.uniforms.shadowTexture.value = child.material.map;
        child.material = self.material;
      }
    });
  },

  tick (t) {
    if (this.typeTexture && this.loaded) {
      this.material.uniforms.time.value = t / 2000;
    }

    if (this.startAnimation) {
      this.time = t;
      this.startAnimation = false;
    }

    if (this.animating) {
      const timePassed = t - this.time;

      if (timePassed > 1000) this.animating = false;

      const mixedColor = this.oldTint.lerp(this.newTint, timePassed / 500);
      this.material.uniforms.tint.value = mixedColor;
    }
  },

  remove () {
    // if (this.imageCanvas) document.removeChild(this.imageCanvas);
    // if (this.typeCanvas) document.removeChild(this.typeCanvas);
    if (this.typeTexture) this.typeTexture.dispose();
    if (this.imageTexture) this.imageTexture.dispose();
  }
});
