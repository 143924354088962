import { Entity } from 'aframe-react';
import React, { Component } from 'react';

// Micro scenes
import Frames from './Frames';
import SecondaryCases from './SecondaryCases';

// Entities
import { Camera, SceneModel } from '../../Entities';

class Museum extends Component {
  render () {
    return (
      <Entity id="museum">

        <Frames />
        <SecondaryCases />

        <Camera
          id="museum_display_box_cam"
          position={{ x: -3.068, y: -0.25, z: -5.562 }}
          rotation={{ x: 0, y: -90, z: 0 }}
          near={.1}
          active
          cameras={[
            'security_cam',
            'golem_cam',
            'golem_head_cam',
            'planetarium_outside_cam',
            'lab_outside_cam',
            'stormdrain_lift_cam',
            'bench_cam',
            'noodle_cam'
          ]}
        />

        <Camera
          id="golem_cam"
          position={{ x: -2.192, y: -0.25, z: -0.034 }}
          rotation={{ x: 0, y: -90, z: 0 }}
          cameras={[
            'golem_head_cam',
            'museum_display_box_cam',
            'planetarium_outside_cam',
            'stormdrain_lift_cam',
            'bench_cam',
            'noodle_cam'
          ]}
        />

        <SceneModel
          id="secondary_display_cases_model"
          src="./models/museum/secondary-display-cases"
          position={{ x: -0.092, y: 0, z: 0 }}
          tint="#cccccc"
          blend="overlay"
        />

        <SceneModel
          id="main_display_cases_model"
          src="./models/museum/main-display-cases"
          position={{ x: -2.264, y: 0, z: -5.604 }}
          rotation={{x: 0, y: 90, z: 0}}
          tint="#cccccc"
          blend="overlay"
        />

        <Entity
          id="museum_loader"
          asset-loader={{
            cameras: ['museum_display_box_cam' ,'planetarium_outside_cam' ,'planetarium_main_cam_1' ,'planetarium_main_cam_2' ,'stormdrain_lift_cam' ,'stormdrain_base_cam_1' ,'stormdrain_base_cam_2' ,'stormdrain_pillar_cam' ,'stormdrain_lift_cam' ,'golem_cam' ,'newspaper_cam' ,'lab_outside_cam' ,'lab_in_cam' ,'blob_cam' ,'bench_cam' ,'noodle_cam']
          }}
        />

        <Entity
            id="museum_audio_1"
            primitive="a-sound"
            position={{x: 0, y: 0, z: 0}}
            sound={{
              src: 'url(./audio/Stage1_ambL.mp3)',
              loop: true,
              volume: 1,
              poolSize: 5
            }}
            sound-controls={{
              playOnEvent: "finishedTutorial"
            }}
        />

        <Entity
            id="museum_audio_2"
            primitive="a-sound"
            position={{x: 0, y: 0, z: 0}}
            sound={{
              src: 'url(./audio/Stage1_obj001.mp3)',
              loop: true,
              volume: 1,
              poolSize: 5
            }}
            sound-controls={{
              playOnEvent: "finishedTutorial"
            }}
        />

        <SceneModel
          rotation={{ x: 0, y: 180, z: 0 }}
          id="museum_main_model"
          src="./models/museum/museum"
          blend="multiply"
          tint="#ffffff"
        />

      </Entity>
    );
  }
}

export default Museum;
