import { Entity } from 'aframe-react';
import React, { Component } from 'react';

import { checkResolution } from 'util';

import config from 'srcDir/config';

class Image extends Component {
  constructor (props) {
    super(props);

    this.state = {
      aspectRatio: 0,
      url: ''
    };

    this.asset = null;
    this.isSmall = false;
  }

  componentDidMount () {
    this.isSmall = checkResolution();

    this.asset = this.props.asset;
    if (!this.asset) return;
    const aspectRatio = this.asset.height / this.asset.width;

    if (!this.isSmall) {
      this.setState({
        aspectRatio,
        url: config.API_URL + this.asset['256']
      });

      document.querySelector('a-scene')
        .addEventListener('lowFPS', this.__onLowFPS, false);
      document.querySelector('a-scene')
        .addEventListener('highFPS', this.__onHighFPS, false);
    } else {
      this.setState({
        aspectRatio,
        url: config.API_URL + this.asset['256']
      });
    }
  }

  __onLowFPS = () => {
    if (this.asset) {
      this.setState({ url: config.API_URL + this.asset['256'] });
    }
  };

  __onHighFPS = () => {
    if (this.asset) {
      this.setState({ url: config.API_URL + this.asset['256'] });
    }
  };

  render () {
    const image = (
      <Entity
        portfolio-image={{
          parentLoader: '#golem_loader',
          source: this.state.url,
          depth: 0.04,
          width: 1,
          height: 1 * this.state.aspectRatio,
          textureWidth:
            this.props.textureWidth !== undefined
              ? this.props.textureWidth
              : 1024,
          textureHeight:
            this.props.textureHeight !== undefined
              ? this.props.textureHeight
              : 1024,
          lazyLoad: false
        }}
        parent-bone={this.props.attachTo}
        scale-bone={this.props.scale}
        flip-bone={this.props.flip}
      />
    );

    return this.state.aspectRatio !== 0 ? image : '';
  }
}

export default Image;
