import { Entity } from 'aframe-react';
import React, { Component } from 'react';

import { forEach, shuffle } from 'lodash';
import { isGif } from 'util';

// DATA
import axios from 'axios';
import config from 'srcDir/config';
import colours from 'srcDir/data/idleColours';

class Inner extends Component {
  constructor (props) {
    super(props);

    this.paused = true;
    this.pausedColour = null;
    this.firstLoad = false;
    this.indexing = false;

    this.state = {
      projectIndex: 0,
      colour: '#656ef2',
      images: []
    };
  }

  componentDidMount () {
    axios.get(`${config.API_URL}/project`)
      .then((res) => {
        let projects = [];

        forEach(res.data, (val) => {
          const obj = {
            title: val.title,
            colour: val.colour,
            invert: val.invert,
            abstract: val.abstract,
            description: val.description,
            images: [],
            enabled: val.enabled
          };
          
          // Remove GIFs from array
          let images = val.images.filter(item => {
            return !isGif(item.url);
          });

          if (images.length === 0 || !val.enabled) return;

          forEach(shuffle(images), (img) => {
            if(img['large-stormdrain'] !== undefined ) {
              obj.images.push(config.API_URL + img['large-stormdrain']);
            }
          });

          projects.push(obj);
        });

        if (projects.length < 1) return false;

        projects = shuffle(projects);

        this.setState({ projects }, () => {
          this.nextProject();
          this.pause();
        });
      });

    document.addEventListener('getProject', this.__onIndex, false);
    document.addEventListener('stormdrain-textures-loaded', this.__onLoad, false);
    
    const loader = document.querySelector('#stormdrain_loader');
    loader.addEventListener('load-now', this.play, false);
    loader.addEventListener('remove', this.pause, false);
  }

  play = () => {
    this.paused = false;
    this.pausedColour = this.state.colour;
    this.indexing = false;

    clearTimeout(this.colourTimer);
    
    if (this.state.projects && !this.indexing) {
      this.nextProject();
    }
  }

  pause = () => {
    clearTimeout(this.projectTimer);
    this.paused = true;
    this.firstLoad = false;
    this.indexing = false;

    if (!this.firstLoad) {
      this.firstLoad = true;
      setTimeout(this.renderColour, 500);
    } else {
      this.renderColour();
    }
  }

  __onLoad = () => {
    clearTimeout(this.projectTimer);
    
    if (!this.paused) {
      this.projectTimer = setTimeout(this.nextProject, 7500);
    }
  };
  
  __onIndex = (evt) => {
    clearTimeout(this.projectTimer);

    const projects = this.state.projects;
    const indexedTitle = evt.detail.title;
    const index = projects.map(e => e.title).indexOf(indexedTitle);
        
    if (index !== -1) {
      this.setState({ 
        projectIndex: index
      }, () => {
        this.setProject(index);
      });
    }
  }

  renderColour = () => {
    clearTimeout(this.colourTimer);
    if (this.paused) {
      let newColour = this.pausedColour;
      let found = false;
      while (!found) {
        newColour = colours[Math.floor(Math.random() * colours.length)];
        if (newColour !== this.pausedColour) {
          found = true;
          this.pausedColour = newColour;
        }
      }

      this.setState({
        colour: newColour
      });

      this.colourTimer = setTimeout(this.renderColour, 3000);
    }
  }
  
  initProject = (index) => {
    const projects = this.state.projects;
    const project = projects[index];
        
    if (projects.length === 0 || !project) return false;
        
    this.setState({
      projectIndex: index,
      colour: project.colour,
      images: project.images,
      title: project.title.toUpperCase(),
      abstract: project.abstract,
      description: project.description,
      invert: project.invert
    },
    () => {
      setTimeout(() => {
        const scene = document.querySelector('a-scene');
        scene.emit('dong', { title: this.state.title, abstract: this.state.abstract, description: this.state.description });
      }, 100);  
    });
  }

  nextProject = () => {
    if(this.indexing) return false;
    
    let index = this.state.projectIndex;
    this.initProject(index);

    if (index === this.state.projects.length - 1) {
      index = 0;
    } else {
      index++;
    }

    this.setState({ projectIndex: index });
  };
  
  setProject = (newIndex) => {
    this.indexing = true;
    this.initProject(newIndex);
  };

  render () {
    return (
      <Entity
        id="stormdrain_loader"
        asset-loader={{
          cameras: [
            'stormdrain_base_cam_1',
            'stormdrain_base_cam_2',
            'stormdrain_lift_cam',
            'stormdrain_pillar_cam'
          ]
        }}
      >

        <Entity
          id="stormdrain_inner_model"
          rotation={{ x: 0, y: 180, z: 0 }}
          type-move={{
            source: './models/stormdrain/stormdrain-inner',
            tint: this.state.colour,
            title: this.state.title,
            projectImages: this.state.images,
            parentLoader: '#stormdrain_loader',
            invert: this.state.invert
          }}
        />

      </Entity>
    );
  }
}

export default Inner;
