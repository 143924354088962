import AFRAME, { THREE } from 'aframe';
import { isMobile } from 'react-device-detect';
import { MeshLine, MeshLineMaterial } from './THREE.MeshLine';

AFRAME.registerComponent('noodle', {
  multiple: true,
  schema: {
    maxRad: { type: 'number', default: 0.06 },
    minRad: { type: 'number', default: 0.0575 },

    height: { type: 'number', default: 0.1 },
    speed: { type: 'number', default: 0.00025 },
    length: { type: 'number', default: 0.25 },

    nAmp: { type: 'number', default: 0.5 },

    seed: { type: 'int', default: 0 },
    smooth: { type: 'int', defualt: 1 },
    dir: { type: 'int', default: 1 },

    seated: { type: 'boolean', default: false },
    parentLoader: { type: 'string', default: '#noodle_loader' }
  },

  init () {
    this.activated = false;
    this.timer = 0.0;
    this.steppedTimer = 0.0;
    this.dirTimer = 0.0;
    this.dirChange = 30000.0;

    this.scene = this.el.sceneEl;

    this.setDataVariables();
    this.setMovementVariables();

    this.makeLine();

    this.el.object3D.updateMatrixWorld(true);

    this._attachEventListeners();

    this.updateScene();
  },

  _attachEventListeners () {
    document.addEventListener('mousedown', this.__onMouseDown.bind(this), false);
    document.addEventListener('mouseup', this.__onMouseUp.bind(this), false);

    if (isMobile) {
      const sceneEl = this.el.sceneEl;
      const canvasEl = sceneEl && sceneEl.canvas;

      canvasEl.addEventListener('touchstart', this.__onTouchDown.bind(this));
      window.addEventListener('touchend', this.__onTouchUp.bind(this));
    }

    document.querySelector('a-scene').addEventListener('camera-set-active',
      this.__onCameraActive.bind(this), false);

    document.querySelector('a-scene').addEventListener('main-models-loaded',
      this.__onSiteLoad.bind(this), false);

    window.addEventListener('resize', this.__onWindowResize.bind(this), false);

    this.loader.addEventListener('load-now', this.__onCameraLoad.bind(this), false);
    this.loader.addEventListener('remove', this.__onRemove.bind(this), false);
  },

  __onCameraLoad () {
    if (!this.loaded) {
      this.loaded = true;
    }
  },

  __onRemove () {
    if (this.loaded) {
      this.loaded = false;
    }
  },

  __onWindowResize () {
    const resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);
    this.lineMesh.material.uniforms.resolution.value = resolution;
  },

  __onSiteLoad () {
    this.speed = this.data.speed;
  },

  __onMouseDown () {
    if (this.seated !== 0 && this.loaded) {
      this.activated = false;
      this.steppedTimer = 0.0;
    }
  },

  __onMouseUp () {
    if (this.seated !== 0 && this.loaded) {
      this.activated = true;
      this.timer = 0.0;
    }
  },

  __onTouchDown () {
    if (this.seated !== 0 && this.loaded) {
      this.activated = false;
      this.steppedTimer = 0.0;
    }
  },

  __onTouchUp () {
    if (this.seated !== 0 && this.loaded) {
      this.activated = true;
      this.timer = 0.0;
    }
  },

  __onCameraActive (evt) {
    const displayCase = document.querySelector('#display_case');

    if (evt.detail.cameraEl.id === 'noodle_cam' && this.seatedCam) {
      this.seated_active = true;
      displayCase.setAttribute('visible', false);
    } else {
      if (displayCase.getAttribute('visible') === false && this.seatedCam) {
        displayCase.setAttribute('visible', true);
      }
      if (this.seated_active) {
        this.seated_active = false;
      }
    }
  },

  setDataVariables () {
    // variables for line creation
    this.length = this.data.length;
    this.segments = 100;
    this.lineWidth = 0.0045;

    // check if seated camera is activated
    this.seatedCam = this.data.seated;
    this.loader = document.querySelector(this.data.parentLoader);

    this.loaded = false;
  },

  setMovementVariables () {
    // variables for wandering and sin,cos animation
    this.target = new THREE.Vector3(0, 0, 0);
    this.velocity = new THREE.Vector3(0, 0, 0);
    this.uTime = 0;
    this.positions = [];
    this.originPos = [];

    // for calcuating circle wandering
    this.angle = 0.0;
    this.yPos = this.el.object3D.position.y;
    this.yDir = -1.0;

    this.speed = 0.01;

    this.seated_active = false;
  },

  makeLine () {
    // create curve
    const curve = new THREE.LineCurve3(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, -this.length, 0)
    );

    // get position of points on the curve, this.segments = number of points
    // points are sampled at even intervals
    const points = curve.getPoints(this.segments);

    // create two arrays from the positions
    for (let i = 0; i < this.segments; i++) {
      this.positions.push(points[i].x, points[i].y, points[i].z);
      this.originPos.push(points[i].y);
    }

    // set material for line
    const resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);
    const lineMaterial = new MeshLineMaterial({
      useMap: false,
      color: new THREE.Color('rgb(255, 2, 2)'),
      opacity: 1,
      resolution,
      sizeAttenuation: !false,
      lineWidth: this.lineWidth,
      near: this.scene.camera.near,
      far: this.scene.camera.far,
      depthTest: true,

      side: THREE.DoubleSide
    });

    // create the line using THREE.MeshLine
    this.line = new MeshLine();
    this.line.setGeometry(this.positions);

    // create the mesh, set as component's object3D
    this.lineMesh = new THREE.Mesh(this.line.geometry, lineMaterial);
    this.lineMesh.frustumCulled = false;
    this.el.object3D.add(this.lineMesh);
  },

  updateScene () {
    // update the curve positons
    this.updateCurve();
    // if this noodle's seated camera is activated
    if (this.seatedCam && this.seated_active) {
      // update camera position
      this.updateCameraRig();
      this.updateCamera();
      // slow down the noodle and tone down the sin,cos animation for a smoother
      // movement
      this.nAmp = this.data.nAmp * 0.2;
    } else this.nAmp = this.data.nAmp;
  },

  updateCurve () {
    const mass = 2;
    // get vector position of head of noodle
    const x = this.positions[0];
    const y = this.positions[1];
    const z = this.positions[2];

    const headVec = new THREE.Vector3(x, y, z);

    // move the head towards the target
    const targetVel = this.wander(headVec);

    const steering = targetVel;
    // divide velocity by mass attribute
    steering.divideScalar(mass);

    this.velocity = steering;
    // multiply velocity by speed attribute
    this.velocity.normalize();
    this.velocity.multiplyScalar(this.speed);

    // add velocity onto the head point's position
    this.positions[0] = x + this.velocity.x;
    this.positions[1] = y + this.velocity.y;
    this.positions[2] = z + this.velocity.z;

    // update the positions array to move the rest of the curve and add sin,cos
    // animation
    this.moveCurve();

    // update the mesh lines' positions with the updated positions array
    this.line.setGeometry(this.positions);
    this.el.object3D.verticesNeedUpdate = true;
  },

  wander (_headVec) {
    const upChange = 40000.0;
    const downChange = 50000.0;
    const yScale = this.data.height;

    // variables for seeking behaviour
    const maxRad = this.data.maxRad;
    const minRad = this.data.minRad;

    const divisions = 12;
    const smooth = this.data.smooth;
    const dir = this.data.dir;

    // get head position in world space
    const currPos = this.el.object3D.position.clone();
    currPos.add(_headVec);

    // calculate distance
    const targetDist = currPos.distanceTo(this.target);

    // if noodle is close to target or its the first tick, set a new random
    // target
    if (targetDist < 0.01 || this.uTime === 0) {
    // var found = false;
    // while (!found) {
      // centre of suggested circle
      const centre = this.el.object3D.position;
      // random angle, angle between 0 and 360
      const t = 2 * Math.PI / divisions * Math.random();
      // add to global angle value
      if (dir === 1.0) this.angle += t;
      else this.angle -= t;

      // if angle is greater than 360, reset; so global variable data doesn't get
      // too large
      if (dir === 1.0 && this.angle > 360) this.angle -= 360;
      if (dir === -1.0 && this.angle < -360) this.angle += 360;

      // create a random radius within set range
      let randRadius = Math.random() * maxRad;
      randRadius = THREE.Math.clamp(randRadius, minRad, maxRad);

      // get x and y coords on theoretical circle using above random variables
      const xCircle = centre.x + randRadius * Math.cos(this.angle);
      const yCircle = centre.z + randRadius * Math.sin(this.angle);

      const x = xCircle;
      const z = yCircle;

      let y = 0.0;
      let newTarget = new THREE.Vector3();

      if (smooth === 1.0) {
        const y = Math.random(this.uTime + this.seed) * 0.01;

        if (this.dirTimer >= this.dirChange) {
          if (this.yDir === 1.0) {
            this.dirChange = this.dirTimer + downChange;
            this.yDir = -1.0;
          } else {
            this.dirChange = this.dirTimer + upChange;
            this.yDir = 1.0;
          }
        }

        this.yPos += y * this.yDir;

        if (this.yPos > this.el.object3D.position.y + yScale / 2.0) { this.yPos = this.el.object3D.position.y + yScale / 2.0; } else if (this.yPos < this.el.object3D.position.y - yScale / 2.0) { this.yPos = this.el.object3D.position.y - yScale / 2.0; }

        newTarget = new THREE.Vector3(x, this.yPos, z);
      } else {
        y = (Math.random(this.uTime + this.seed) - 0.5) * yScale + centre.y;
        newTarget = new THREE.Vector3(x, y, z);
      }

      // let a = new THREE.Vector3().subVectors(currPos, this.target);
      // let b = new THREE.Vector3().subVectors(currPos, newTarget);
      //
      // a.normalize();
      // b.normalize();
      //
      // let theta = a.dot(b);
      //
      // let angleDeg = Math.acos(theta) * (180 / Math.PI);
      // //clamp the angle
      // if (angleDeg <= 90.0) {
      this.target = newTarget;
      //  found = true;
      // }
    // }
    }
    return this.seek(_headVec);
  },

  seek (_headVec) {
    // get head position in world space
    const currPos = this.el.object3D.position.clone();
    currPos.add(_headVec);

    // get unit direction vector, eg. velocity
    const targetVector = currPos.sub(this.target);
    targetVector.normalize();
    targetVector.negate();

    return targetVector;
  },

  moveCurve () {
    const segHeight = this.length / this.segments;
    // go through every x,y,z component of curve points
    const noVerts = this.segments * 3;

    for (let i = 0; i < noVerts; i += 3) {
      const x1 = this.positions[i];
      const y1 = this.positions[i + 1];
      const z1 = this.positions[i + 2];

      const currVec = new THREE.Vector3(x1, y1, z1);

      const x2 = this.positions[i + 3];
      const y2 = this.positions[i + 4];
      const z2 = this.positions[i + 5];

      const nextVec = new THREE.Vector3(x2, y2, z2);

      // get the direction from the current point to the next point
      const vector = currVec.clone();
      vector.sub(nextVec);

      // create unit direction vector and then multiply by the intial distance
      // between the curve points
      vector.normalize();
      vector.multiplyScalar(segHeight);

      // subtract this from the current point's position
      const nextPt = currVec.clone();
      nextPt.sub(vector);

      // if it hasn't reached the end of the array
      if (i + 3 < noVerts) {
        // the next point's position is equal to the vector above
        this.positions[i + 3] = nextPt.x;
        this.positions[i + 4] = nextPt.y;
        this.positions[i + 5] = nextPt.z;
      }
    }
    // add sin,cos animation
    this.sinCosPositions();
  },

  sinCosPositions () {
    // variable for movement noise
    const nSpeed = 0.05;
    const nFreq = 0.1;
    const nAmp = 0.5;
    const seed = this.data.seed;
    // go through every x,y,z component of curve points
    const noVerts = this.segments * 3;
    let vertex = 0;

    for (let i = 0; i < noVerts; i += 3) {
      // get current point's position
      const x = this.positions[i];
      const y = this.positions[i + 1];
      const z = this.positions[i + 2];

      // get the current point's original y coordinate
      const yOrigin = this.originPos[vertex];
      // calculate weighting, 0 weighting at the head; 1 weighting at the tail
      const weight = yOrigin / this.length;
      // clamp the weighting to 0 and 0.5 so there isn't a big difference between the noodle head and end poisitons
      const yMag = Math.min(Math.max(weight, 0), 0.2);

      // add sin and cos to the current point's position
      const newX = x + Math.cos(yOrigin * nFreq + (this.uTime + seed) * nSpeed) * nAmp * yMag;
      const newY = y + Math.sin(yOrigin * nFreq + (this.uTime + 0.5 + seed) * nSpeed) * nAmp * yMag;
      const newZ = z + Math.sin(yOrigin * nFreq + (this.uTime + seed) * nSpeed) * nAmp * yMag;

      this.positions[i] = newX;
      this.positions[i + 1] = newY;
      this.positions[i + 2] = newZ;

      vertex++;
    }
  },

  updateCameraRig () {
    // get seated camera from A-frame scene
    const rig = document.querySelector('#noodle_camera_rig');
    // middle point's position in the positons array and the point after that
    const middleNo = 15 * 3;
    const nextNo = (15 + 1) * 3;

    const middleX = this.positions[middleNo];
    const middleY = this.positions[middleNo + 1];
    const middleZ = this.positions[middleNo + 2];

    const middle = new THREE.Vector3(middleX, middleY, middleZ);

    const nextX = this.positions[nextNo];
    const nextY = this.positions[nextNo + 1];
    const nextZ = this.positions[nextNo + 2];

    const next = new THREE.Vector3(nextX, nextY, nextZ);

    // set the cameras position to the middle point's position, adding the mesh line's width to the y coordinate
    const centre = this.el.object3D.position;
    middle.add(centre.clone());
    next.add(centre.clone());

    rig.object3D.position.set(
      middle.x,
      middle.y + this.lineWidth + 0.0065,
      middle.z
    );

    // set camera to look at the next point's position, add the width of the line to the y coordinate
    rig.object3D.lookAt(new THREE.Vector3(next.x, next.y + this.lineWidth + 0.0075, next.z));
  },

  updateCamera () {
    // TIME TAKEN TO RESET CAMERA TO LOOK-AT
    const turnTime = 20;
    if (this.activated) {
      this.timer += 0.1;
      if (this.timer > turnTime) {
        this.activated = false;
        this.timer = 0.0;
        this.steppedTimer = 0.1;
      }
    }

    if (this.steppedTimer > 0.0) {
      // SMOOTHNESS OF RESET
      let mix = this.steppedTimer / 160.0;
      if (mix >= 1.0) {
        mix = 1.0;
        this.steppedTimer = 0.0;
      }

      this.el.emit('reset-noodle', mix);

      if (mix < 1.0) this.steppedTimer += 0.1;
    }
  },

  tick (t) {
    // update and increment timer, always update camera noodle
    if (this.loaded || t < 3000) {
      this.uTime = t * 0.1;
      this.dirTimer = t;
      this.updateScene();
    }
  },

  _removeEventListeners () {
    document.removeEventListener('mousedown', this.__onMouseDown, false);
    document.removeEventListener('mouseup', this.__onMouseUp, false);

    if (isMobile) {
      const sceneEl = this.el.sceneEl;
      const canvasEl = sceneEl && sceneEl.canvas;

      canvasEl.removeEventListener('touchstart', this.__onTouchDown.bind(this));
      canvasEl.removeEventListener('touchend', this.__onTouchUp.bind(this));
    }

    document.removeEventListener('a-scene', this.__onCameraActive, false);

    window.removeEventListener('resize', this.__onWindowResize, false);

    this.loader.removeEventListener('load-now', this.__onCameraLoad, false);
    this.loader.removeEventListener('remove', this.__onRemove, false);
  },

  remove () {
    // delete line mesh from A-frame scene
    this._removeEventListeners();

    if (this.lineMesh) {
      this.lineMesh.material.dispose();
      this.line.geometry.dispose();
      this.el.object3D.remove(this.lineMesh);
    }
    this.lineMesh = null;
    this.line = null;
  }
});
