import { Entity } from 'aframe-react';
import React, { Component } from 'react';

class PlanetariumGif extends Component {
  constructor (props) {
    super(props);

    this.state = { aspectRatio: 0 };
  }
  
  
  componentDidMount () {
    const aspectRatio = this.props.height / this.props.width;
    
    this.setState({ aspectRatio });
  }

  render () {
    const image = (
      <Entity
        geometry={{
          primitive: 'plane',
          width: this.props.scale * 1.2, // Increase GIF size for added impact
          height: this.props.scale * 1.2 * this.state.aspectRatio,
        }}
        className="planetarium-image planetarium-gif"
        material={{
          shader: 'gif',
          src: `url(${this.props.src})`
        }}
        look-at="#planetarium_center"
        rotatomatic={{
          offset: this.props.offset * 30,
          radius: this.props.index % 2 ? '4' : '6',
          y: Math.random() * 3.5 - 0.25,
          speed: Math.random() * 4 + 0.25,
          direction: this.props.index % 2 ? 'forward' : 'backward',
          rad: this.props.rad
        }}
      />
    );

    return this.state.aspectRatio !== 0 ? image : '';
  }
}

export default PlanetariumGif;
