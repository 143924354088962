import AFRAME from 'aframe';

AFRAME.registerComponent('attach-to', {
  schema: { type: 'string' },

  init () {
    this.target = document.querySelector(`#${this.data}`);
  },

  tick () {
    const obj = this.el.object3D;
    const target = this.target.object3D;

    obj.position.copy(target.position);
    obj.rotation.copy(target.rotation);
  }
});
