import React, {Component} from 'react';
import StartAudioContext from 'startaudiocontext';
import {THREE} from 'aframe';

import { isMobile } from 'react-device-detect';

class Tutorial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: 0,
      result: ''
    };
  }

  componentDidMount() {
    document.body.classList.remove('--mute');
    document.addEventListener('keydown', this.onKeyDown);
  }

  continueTutorial = () => {
    let stage;
    this.unlockAudio();

    switch (this.state.stage) {
      case 0:
        stage = 1;
        break;
      case 1:
        this.props.handleFinishedTutorial();
        break;
    }

    this.setState({stage});
  }
  
  onKeyDown = (evt) => {
    this.unlockAudio();

    if(evt.key == "ArrowRight" || evt.key == "ArrowLeft") {
      this.continueTutorial();
    }
  }

  unlockAudio = () => {
    const context = THREE.AudioContext.getContext();

    StartAudioContext(context);
  }

  render() {
    return (<div id="tutorial_wrapper" onClick={this.continueTutorial}>
      {
        this.state.stage === 0
          ? (<div className="tutorial-message-camera message visible">
            <h1>
              {isMobile ? 'Tap' : 'Click'} to
              <br/> {' '}
              switch cameras
            </h1>
            <div className="navigation-icon-wrapper">
              {!isMobile ?
                <div>
                  <img className="mouse-icon" alt="Mouse Icon" src="./img/ui/icons/mouse-icon.svg"/>
                  <img className="keyboard-icon" alt="Keyboard Icon" src="./img/ui/icons/keyboard-icon.svg"/>
                </div> :
                <img className="tap-icon" alt="Tap Icon" src="./img/ui/icons/tap-icon.svg"/>
              }
            </div>
          </div>)
          : ''
      }
      {
        this.state.stage === 1
          ? (<div className="tutorial-message-camera message visible prev-next">
            <h1 className="prev">Previous<br /> camera</h1>
            <div className="prev-next-divider"/>
            <h1 className="next">Next<br /> camera</h1>
          </div>)
          : ''
      }
    </div>);
  }
}

export default Tutorial;
