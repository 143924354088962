import AFRAME, { THREE } from 'aframe';
import { checkResolution } from 'util';

const vertexShader = require('./shaders/vertex.glsl');
const fragmentShader = require('./shaders/fragment.glsl');

const textureUrl = './img/broth/oily-broth.jpg';

AFRAME.registerComponent('broth', {
  init () {
    const radius = this.radius = 0.075;
    this.noiseMult = 0.1;
    this.parentLoader = '#noodle_loader';

    const circle = new THREE.CircleGeometry(radius, 64);

    this.depthLoaded = true;
    this.lowRes = false;
    this.isSmall = checkResolution;
    this.loader = document.querySelector(this.parentLoader);

    this.refractor = new THREE.Refractor(circle, {
      textureWidth: 2048,
      textureHeight: 2048,
      shader: THREE.WaterRefractionShader
    });

    this.initUniforms();
    this.el.object3D.add(this.refractor);

    this._attachEventListeners();
  },

  initUniforms () {
    let source = textureUrl;

    if (this.isSmall) {
      const sections = source.split('.');
      source = `.${sections[1]}-mobile.${sections[2]}`;
      this.lowRes = true;
    }

    this.texture = new THREE.TextureLoader().load(source);
    this.texture.wrapS = THREE.RepeatWrapping;
    this.texture.wrapT = THREE.RepeatWrapping;
    
    this.refractor.material.uniforms.uNoiseMult.value = this.noiseMult;
    this.refractor.material.uniforms.uCamFar.value = this.el.sceneEl.camera.far;
    this.refractor.material.uniforms.uCamNear.value = this.el.sceneEl.camera.near;
    this.refractor.material.uniforms.uPixelRatio.value = window.devicePixelRatio;
    this.refractor.material.uniforms.tWater.value = this.texture;

    this.refractor.material.visible = false;
  },

  initDepthTarget () {
    // needed for foam shader
    let resolution = new THREE.Vector2();
    this.el.sceneEl.renderer.getSize(resolution);
    
    const pixels = window.devicePixelRatio;
    this.target = new THREE.WebGLRenderTarget(
      resolution.width * pixels,
      resolution.height * pixels
    );

    this.target.texture.format = THREE.RGBFormat;
    this.target.texture.minFilter = THREE.NearestFilter;
    this.target.texture.magFilter = THREE.NearestFilter;
    this.target.texture.generateMipmaps = false;
    this.target.stencilBuffer = false;
    this.target.depthBuffer = true;
    this.target.depthTexture = new THREE.DepthTexture();
    this.target.depthTexture.type = THREE.UnsignedShortType;

    this.refractor.material.uniforms.tDepth.value = this.target.depthTexture;
  },

  _attachEventListeners () {
    window.addEventListener('resize', this.__onResize.bind(this), false);

    this.el.sceneEl.addEventListener('loaded', this.__onLoad.bind(this), false);

    document.querySelector('a-scene').addEventListener(
      'camera-set-active', this.__onCameraActive.bind(this), false
    );

    if (!this.isSmall) {
      document.querySelector('a-scene')
        .addEventListener('lowFPS', this.__onLowFPS.bind(this), false);
      document.querySelector('a-scene')
        .addEventListener('highFPS', this.__onHighFPS.bind(this), false);
    }
  },

  __onResize () {
    let resolution = new THREE.Vector2();
    this.el.sceneEl.renderer.getSize(resolution);
    
    this.refractor.material.uniforms.uResolution.value = new THREE.Vector2(
      resolution.width,
      resolution.height
    );
  },

  __onLoad () {
    this.initDepthTarget();
    this.__onResize();
  },

  __onLowFPS () {
    let source = textureUrl;
    const sections = source.split('.');
    source = `.${sections[1]}-mobile.${sections[2]}`;

    if (this.texture) this.texture.dispose();

    this.texture = new THREE.TextureLoader().load(source);
    this.refractor.material.uniforms.tWater.value = this.texture;
    this.lowRes = true;
  },

  __onHighFPS () {
    const source = textureUrl;

    if (this.texture) this.texture.dispose();

    this.texture = new THREE.TextureLoader().load(source);
    this.refractor.material.uniforms.tWater.value = this.texture;
    this.lowRes = false;
  },

  __onCameraActive (evt) {
    if (evt.detail.cameraEl.id === 'noodle_cam') {
      if (!this.depthLoaded) {
        this.refractor.material.visible = true;
        this.refractor.material.uniforms.uCamFar.value = this.el.sceneEl.camera.far;
        this.refractor.material.uniforms.uCamNear.value = this.el.sceneEl.camera.near;
        this.refractor.material.uniforms.uLoadDepth.value = true;
        this.depthLoaded = true;
      }
    } else if (evt.detail.cameraEl.id === 'bench_cam') {
      this.refractor.material.visible = true;
      this.refractor.material.uniforms.uLoadDepth.value = false;
      this.depthLoaded = false;
    } else if (this.depthLoaded) {
      this.refractor.material.visible = false;
      this.refractor.material.uniforms.uLoadDepth.value = false;
      this.depthLoaded = false;
    }
  },

  tick (t) {
    if (this.depthLoaded) {
      const cameraEl = this.el.sceneEl.camera.el;
      if (!cameraEl) return;

      const y = this.el.sceneEl.camera.el.parentNode.object3D.position.y;
      // Possibly cause a problem later on, keeping for now
      if (y >= 0.65) {
        const renderer = this.el.sceneEl.renderer;        
        const previousRenderTarget = renderer.getRenderTarget();
        renderer.setRenderTarget(this.target);
        renderer.render(this.el.sceneEl.object3D, this.el.sceneEl.camera);
        renderer.setRenderTarget(previousRenderTarget);
        this.refractor.material.uniforms.uTime.value = t;
      }
    }
  },

  _removeEventListeners () {
    window.removeEventListener('resize', this.__onWindowResize, false);

    document.removeEventListener('main-models-loaded', this.onLoad);

    document.querySelector('a-scene')
      .removeEventListener('camera-set-active', this.__onCameraActive);

    if (!this.isSmall) {
      document.querySelector('a-scene')
        .removeEventListener('lowFPS', this.__onLowFPS, false);
      document.querySelector('a-scene')
        .removeEventListener('highFPS', this.__onLowFPS, false);
    }
  },

  remove () {
    this._removeEventListeners();

    if (this.texture) this.texture.dispose();
    if (this.refractor) {
      this.refractor.material.dispose();
      this.refractor.geometry.dispose();
    }
    if (this.target) this.target.dispose();

    this.el.object3D.remove(this.refractor);

    this.refractor = null;
    this.texture = null;
    this.target = null;
  }
});

THREE.WaterRefractionShader = {
  uniforms: {
    uTime: {
      type: 'f',
      value: 0
    },

    uNoiseMult: {
      type: 'f',
      value: 10
    },

    uLoadDepth: {
      type: 'b',
      value: false
    },

    tDiffuse: {
      type: 't',
      value: null
    },

    tDepth: {
      type: 't',
      value: null
    },

    tWater: {
      type: 't',
      value: null
    },

    uTextureMatrix: {
      type: 'm4',
      value: null
    },

    uResolution: {
      type: 'v2',
      value: null
    },
    uCamFar: {
      type: 'f',
      value: null
    },
    uCamNear: {
      type: 'f',
      value: null
    },
    uPixelRatio: {
      type: 'f',
      value: null
    }
  }
};

THREE.Refractor = function (geometry, options) {
  THREE.Mesh.call(this, geometry);

  this.type = 'Refractor';

  const scope = this;

  options = options || {};

  const textureWidth = checkResolution() ? 1024 : options.textureWidth;
  const textureHeight = checkResolution() ? 1024 : options.textureHeight;
  const clipBias = options.clipBias || 0;
  const shader = options.shader;

  const virtualCamera = new THREE.PerspectiveCamera();
  virtualCamera.matrixAutoUpdate = false;
  virtualCamera.userData.refractor = true;

  const refractorPlane = new THREE.Plane();
  const textureMatrix = new THREE.Matrix4();

  const parameters = {
    minFilter: THREE.LinearFilter,
    magFilter: THREE.LinearFilter,
    format: THREE.RGBFormat,
    stencilBuffer: false
  };

  const renderTarget = new THREE.WebGLRenderTarget(
    textureWidth,
    textureHeight,
    parameters
  );

  if (!THREE.Math.isPowerOfTwo(textureWidth) ||
      !THREE.Math.isPowerOfTwo(textureHeight)) {
    renderTarget.texture.generateMipmaps = false;
  }

  // material
  this.material = new THREE.ShaderMaterial({
    uniforms: THREE.UniformsUtils.clone(shader.uniforms),
    vertexShader,
    fragmentShader,
    transparent: true, // ensures, refractors are drawn from farthest to closest
    depthWrite: false,
    side: THREE.DoubleSide
  });

  this.material.uniforms.tDiffuse.value = renderTarget.texture;
  this.material.uniforms.uTextureMatrix.value = textureMatrix;

  // functions

  const visible = (function () {
    const refractorWorldPosition = new THREE.Vector3();
    const cameraWorldPosition = new THREE.Vector3();
    const rotationMatrix = new THREE.Matrix4();

    const view = new THREE.Vector3();
    const normal = new THREE.Vector3();

    return function visible (camera) {
      refractorWorldPosition.setFromMatrixPosition(scope.matrixWorld);
      cameraWorldPosition.setFromMatrixPosition(camera.matrixWorld);

      view.subVectors(refractorWorldPosition, cameraWorldPosition);

      rotationMatrix.extractRotation(scope.matrixWorld);

      normal.set(0, 0, 1);
      normal.applyMatrix4(rotationMatrix);

      return view.dot(normal) < 0;
    };
  }());

  const updateRefractorPlane = (function () {
    const normal = new THREE.Vector3();
    const position = new THREE.Vector3();
    const quaternion = new THREE.Quaternion();
    const scale = new THREE.Vector3();

    return function updateRefractorPlane () {
      scope.matrixWorld.decompose(position, quaternion, scale);
      normal.set(0, 0, 1).applyQuaternion(quaternion).normalize();

      // flip the normal because we want to cull everything above the plane
      normal.negate();

      refractorPlane.setFromNormalAndCoplanarPoint(normal, position);
    };
  }());

  const updateVirtualCamera = (function () {
    const clipPlane = new THREE.Plane();
    const clipVector = new THREE.Vector4();
    const q = new THREE.Vector4();

    return function updateVirtualCamera (camera) {
      virtualCamera.matrixWorld.copy(camera.matrixWorld);
      virtualCamera.matrixWorldInverse.getInverse(virtualCamera.matrixWorld);
      virtualCamera.projectionMatrix.copy(camera.projectionMatrix);
      virtualCamera.far = camera.far; // used in WebGLBackground

      // The following code creates an oblique view frustum for clipping.
      // see: Lengyel, Eric. “Oblique View Frustum Depth Projection and Clipping”.
      // Journal of Game Development, Vol. 1, No. 2 (2005), Charles River Media, pp. 5–16

      clipPlane.copy(refractorPlane);
      clipPlane.applyMatrix4(virtualCamera.matrixWorldInverse);

      clipVector.set(
        clipPlane.normal.x,
        clipPlane.normal.y,
        clipPlane.normal.z,
        clipPlane.constant
      );

      // calculate the clip-space corner point opposite the clipping plane and
      // transform it into camera space by multiplying it by the inverse of the projection matrix

      const projectionMatrix = virtualCamera.projectionMatrix;

      q.x = (Math.sign(clipVector.x) + projectionMatrix.elements[8]) /
        projectionMatrix.elements[0];
      q.y = (Math.sign(clipVector.y) + projectionMatrix.elements[9]) /
        projectionMatrix.elements[5];
      q.z = -1.0;
      q.w = (1.0 + projectionMatrix.elements[10]) / projectionMatrix.elements[14];

      // calculate the scaled plane vector

      clipVector.multiplyScalar(2.0 / clipVector.dot(q));

      // replacing the third row of the projection matrix

      projectionMatrix.elements[2] = clipVector.x;
      projectionMatrix.elements[6] = clipVector.y;
      projectionMatrix.elements[10] = clipVector.z + 1.0 - clipBias;
      projectionMatrix.elements[14] = clipVector.w;
    };
  }());

  // This will update the texture matrix that is used for projective texture mapping in the shader.
  // see: http://developer.download.nvidia.com/assets/gamedev/docs/projective_texture_mapping.pdf

  function updateTextureMatrix (camera) {
    // this matrix does range mapping to [ 0, 1 ]

    textureMatrix.set(
      0.5, 0.0, 0.0, 0.5,
      0.0, 0.5, 0.0, 0.5,
      0.0, 0.0, 0.5, 0.5,
      0.0, 0.0, 0.0, 1.0
    );

    // we use "Object Linear Texgen", so we need to multiply the texture matrix T
    // (matrix above) with the projection and view matrix of the virtual camera
    // and the model matrix of the refractor

    textureMatrix.multiply(camera.projectionMatrix);
    textureMatrix.multiply(camera.matrixWorldInverse);
    textureMatrix.multiply(scope.matrixWorld);
  }

  //

  const render = (function () {
    const viewport = new THREE.Vector4();

    return function render (renderer, scene, camera) {
      scope.visible = false;

      const currentRenderTarget = renderer.getRenderTarget();
      const currentVrEnabled = renderer.vr.enabled;
      const currentShadowAutoUpdate = renderer.shadowMap.autoUpdate;

      renderer.vr.enabled = false; // avoid camera modification
      renderer.shadowMap.autoUpdate = false; // avoid re-computing shadows

      renderer.setRenderTarget(renderTarget);
      renderer.render(scene, virtualCamera);

      renderer.vr.enabled = currentVrEnabled;
      renderer.shadowMap.autoUpdate = currentShadowAutoUpdate;
      renderer.setRenderTarget(currentRenderTarget);

      // restore viewport

      const bounds = camera.bounds;

      if (bounds !== undefined) {
        let size = new THREE.Vector2();
        this.el.sceneEl.renderer.getSize(size);
        
        const pixelRatio = renderer.getPixelRatio();

        viewport.x = bounds.x * size.width * pixelRatio;
        viewport.y = bounds.y * size.height * pixelRatio;
        viewport.z = bounds.z * size.width * pixelRatio;
        viewport.w = bounds.w * size.height * pixelRatio;

        renderer.state.viewport(viewport);
      }

      scope.visible = true;
    };
  }());

  //

  this.onBeforeRender = function (renderer, scene, camera) {
    // ensure refractors are rendered only once per frame

    if (camera.userData.refractor === true) return;

    // avoid rendering when the refractor is viewed from behind

    if (!visible(camera) === true) return;

    // update
    // console.log("rendering");

    updateRefractorPlane();

    updateTextureMatrix(camera);

    updateVirtualCamera(camera);

    render(renderer, scene, camera);
  };

  this.getRenderTarget = function () {
    return renderTarget;
  };
};

THREE.Refractor.prototype = Object.create(THREE.Mesh.prototype);
THREE.Refractor.prototype.constructor = THREE.Refractor;
