import AFRAME from 'aframe';

AFRAME.registerComponent('lift-anim', {
  init () {
    this.scene = this.el.sceneEl;
    this.object3D = this.el.object3D;

    this.loader = document.querySelector('#lift_loader');

    this.camera = document.querySelector('#stormdrain_lift_cam').parentNode;
    
    this.liftAudio = document.querySelector('#lift_audio');

    this.setDataVariables();

    this._attachEventListeners();
  },

  setDataVariables () {
    this.animSpeed = 20.0;
    this.speed = 0.05;

    this.triggerUp = false;
    this.triggerDown = false;
    this.waitTimer = 0.0;

    this.firstCall = false;
    this.loaded = false;

    this.startY = 0;
    this.endY = -33.75;
    this.timer = 0.0;
  },

  _attachEventListeners () {
    document.querySelector('a-scene').addEventListener('camera-set-active',
      this.__onCameraSwitch.bind(this), false);
    this.loader.addEventListener('load-now', this.__onCameraLoad.bind(this), false);
    this.loader.addEventListener('remove', this.__onRemove.bind(this), false);
  },

  __onCameraSwitch (evt) {
    if (!this.firstCall) {
      if (evt.detail.cameraEl.id === 'stormdrain_lift_cam') {
        this.triggerDown = true;
        this.triggerUp = false;
        this.firstCall = true;
        this.loaded = true;
        this._removeEventListeners();
      }
    }
  },

  __onCameraLoad () {
    if (!this.loaded && this.firstCall) {
      this.loaded = true;
    }

    let liftSrc;
    
    if(this.triggerUp) {
      liftSrc = 'Motion_ElevUp';
    } else {
      liftSrc = 'Motion_ElevDown';
    }
    
    this.liftAudio.setAttribute('sound', {
      src: `url(./audio/${liftSrc}.mp3)`
    });
  },

  __onRemove () {
    // if (this.loaded) {
    //   this.loaded = false;
    // }
  },

  animate (t) {
    let currY = this.el.object3D.position.y;
    if (this.triggerDown === true && currY > this.endY) {
      this.timer -= t;

      const val = map(this.timer, this.startY, this.endY, 0, 1);
      currY = map(easeOutCubic(val), 0, 1, this.startY, this.endY);

      this.el.object3D.position.y = currY;
    } else if (this.triggerUp === true && currY < this.startY) {
      this.timer -= t;

      const val = map(this.timer, this.startY, this.endY, 0, 1);
      currY = map(easeOutCubic(1.0 - val), 0, 1, this.startY, this.endY);
      this.el.object3D.position.y = currY;
    }

    if (currY <= this.endY) {
      this.waitTimer += 0.1;
      if (this.waitTimer >= this.animSpeed) {
        this.waitTimer = 0.0;
        this.timer = 0;

        this.triggerDown = false;
        this.triggerUp = true;
        this.loaded = false;
      }
    } else if (currY >= this.startY) {
      this.waitTimer += 0.1;
      if (this.waitTimer >= this.animSpeed) {
        this.waitTimer = 0.0;
        this.timer = 0;

        this.triggerDown = true;
        this.triggerUp = false;
        this.loaded = false;
      }
    }
  },

  tick () {
    if (this.loaded) this.animate(this.speed);
  },

  _removeEventListeners () {
    document.querySelector('a-scene').removeEventListener('camera-set-active',
      this.__onCameraSwitch, false);
    this.loader.removeEventListener('load-now', this.__onCameraLoad, false);
    this.loader.removeEventListener('remove', this.__onRemove, false);
  },

  remove () {
    this._removeEventListeners();
  }
});

function easeOutCubic (t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

function map (value, istart, istop, ostart, ostop) {
  return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
}
