import React, { Component } from 'react';

import { isMobile } from 'react-device-detect';

class Message extends Component {
  constructor (props) {
    super(props);

    this.state = { 
      message: '',
      promptDrag: false
    };
  }

  componentDidMount () {
    document.body.addEventListener('dong', (e) => {
      clearTimeout(this.timeout);

      this.setMessage(e);
      this.timeout = setTimeout(this.hideMessage, 1000);
    });
  }
  
  __onCameraSwitch = () => {
    this.setState({
      message: '',
      abstract: '',
      visibleMessage: false
    });
    
    document.body.classList.remove('--has-info');
  }

  setMessage = (event) => {
    this.setState({
      message: event.detail.title,
      abstract: event.detail.abstract,
      description: event.detail.description,
      visibleMessage: true,
      promptDrag: event.detail.canDrag
    });

    if (event.detail.description || isMobile) {
      document.body.classList.add('--has-info');
    } else {
      document.body.classList.remove('--has-info');
    }
  }

  hideMessage = () => {
    this.setState({ visibleMessage: false });
  }

  render () {
    return (
      <div>
        <div className={this.state.visibleMessage ? 'message visible' : 'message'}>
          <h1>{!isMobile && this.state.promptDrag ? <span>Click and drag <br />to look</span> : this.state.message}</h1>
    
          {!isMobile && this.state.promptDrag ? 
            <div className="navigation-icon-wrapper">
                <img className="mouse-icon" alt="Mouse Icon" src="./img/ui/icons/mouse-icon.svg"/>
            </div>
          : ''}
        </div>  
  
          {this.state.abstract ? 
            <div className="abstract">
              <span className="abstract-inner">
                {isMobile ? 'Info' : this.state.abstract}
              </span>
              
              {this.state.description || isMobile ? 
                <img
                  className="more-info-icon"
                  src="./img/ui/icons/more-info-icon.svg"
                /> : '' }
            </div>
          : '' }
      </div>
    );
  }
}

export default Message;
