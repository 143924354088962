import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('birds', {
  multiple: true,
  schema: {
    parentLoader: { type: 'string', default: '#bird_loader' },
    count: { type: 'number', default: 20 }
  },

  init () {
    this.loader = document.querySelector(this.data.parentLoader);
    this.loaded = false;

    this.BoundaryX = 5;
    this.BoundaryY = 2;
    this.BoundaryZ = 5;

    this.maxVel = 1;

    this.createBirds();

    this._attachEventListeners();
  },

  createBirds () {
    this.birds = [];
    this.boids = [];

    for (let i = 0; i < this.data.count; i++) {
      const boid = (this.boids[i] = new Boid());
      boid.position.x = (Math.random() * this.BoundaryX - this.BoundaryX * 0.5) * 0.5;
      boid.position.y = (Math.random() * this.BoundaryY - this.BoundaryY * 0.5) * 0.5;
      boid.position.z = (Math.random() * this.BoundaryZ - this.BoundaryZ * 0.5) * 0.5;
      boid.velocity.x = Math.random() * this.maxVel - this.maxVel * 0.5;
      boid.velocity.y = Math.random() * this.maxVel - this.maxVel * 0.5;
      boid.velocity.z = Math.random() * this.maxVel - this.maxVel * 0.5;
      boid.setAvoidWalls(true);
      boid.setWorldSize(this.BoundaryX, this.BoundaryY, this.BoundaryZ);

      const bird = (this.birds[i] = new THREE.Mesh(
        new Bird(),
        new THREE.MeshBasicMaterial({
          side: THREE.DoubleSide,
          color: new THREE.Color('black')
        })
      ));
      bird.phase = Math.floor(Math.random() * 62.83);
      bird.scale.set(0.025, 0.025, 0.025);
      this.el.object3D.add(bird);
    }
  },

  _attachEventListeners () {
    this.loader.addEventListener('load-now', this.__onCameraLoad.bind(this), false);
    this.loader.addEventListener('remove', this.__onRemove.bind(this), false);
  },

  __onCameraLoad () {
    if (!this.loaded) {
      this.loaded = true;
    }
  },

  __onRemove () {
    if (this.loaded) {
      this.loaded = false;
    }
  },

  tick () {
    if (this.loaded) this.updateBoids();
  },

  updateBoids () {
    for (let i = 0; i < this.birds.length; i++) {
      const currPos = this.el.getAttribute('position');

      const boid = this.boids[i];
      boid.run(this.boids);

      const bird = this.birds[i];
      bird.position.copy(this.boids[i].position);
      bird.position.x += currPos.x;
      bird.position.y += currPos.y;
      bird.position.z += currPos.z;

      bird.rotation.y = Math.atan2(-boid.velocity.z, boid.velocity.x);
      bird.rotation.z = Math.asin(boid.velocity.y / boid.velocity.length());

      bird.phase = (bird.phase + (Math.max(0, bird.rotation.z) + 0.1)) % 62.83;
      bird.geometry.vertices[5].y = bird.geometry.vertices[4].y = Math.sin(bird.phase) * 0.5;

      bird.geometry.verticesNeedUpdate = true;
    }
  },

  _removeEventListeners () {
    this.loader.removeEventListener('load-now', this.__onCameraLoad, false);
    this.loader.removeEventListener('remove', this.__onRemove, false);
  },

  remove () {
    this._removeEventListeners();

    for (let i = 0; i < this.birds.length; i++) {
      let bird = this.birds[i];
      bird.geometry.dispose();
      bird.material.dispose();

      this.el.object3D.remove(bird);
      bird = null;
    }
  }
});

// define birds and boids

var Bird = function () {
  const scope = this;

  THREE.Geometry.call(this);

  v(0.5, 0, 0);
  v(-0.5, -0.2, 0.1);
  v(-0.5, 0, 0);
  v(-0.5, -0.2, -0.1);

  v(0, 0.2, -0.6);
  v(0, 0.2, 0.6);
  v(0.2, 0, 0);
  v(-0.3, 0, 0);

  f3(0, 2, 1);

  f3(4, 7, 6);
  f3(5, 6, 7);

  this.computeFaceNormals();

  function v (x, y, z) {
    scope.vertices.push(new THREE.Vector3(x, y, z));
  }

  function f3 (a, b, c) {
    scope.faces.push(new THREE.Face3(a, b, c));
  }
};

Bird.prototype = Object.create(THREE.Geometry.prototype);
Bird.prototype.constructor = Bird;

// Based on https://www.openprocessing.org/sketch/6910

export class Boid {
  constructor () {
    this.vector = new THREE.Vector3();
    this._width = 500;
    this._height = 500;
    this._depth = 200;
    this._goal = 0.0;
    this._neighborhoodRadius = 0.1;
    this._maxSpeed = 0.0035;
    this._maxSteerForce = 0.1;
    this._avoidWalls = false;

    this.position = new THREE.Vector3();
    this.velocity = new THREE.Vector3();
    this._acceleration = new THREE.Vector3();
  }

  setGoal (target) {
    this._goal = target;
  }

  setAvoidWalls (value) {
    this._avoidWalls = value;
  }

  setWorldSize (width, height, depth) {
    this._width = width;
    this._height = height;
    this._depth = depth;
  }

  run (boids) {
    if (this._avoidWalls) {
      this.vector.set(-this._width, this.position.y, this.position.z);
      this.vector = this.avoid(this.vector);
      this.vector.multiplyScalar(0.0001);
      this._acceleration.add(this.vector);

      this.vector.set(this._width, this.position.y, this.position.z);
      this.vector = this.avoid(this.vector);
      this.vector.multiplyScalar(0.0001);
      this._acceleration.add(this.vector);

      this.vector.set(this.position.x, -this._height, this.position.z);
      this.vector = this.avoid(this.vector);
      this.vector.multiplyScalar(0.0001);
      this._acceleration.add(this.vector);

      this.vector.set(this.position.x, this._height, this.position.z);
      this.vector = this.avoid(this.vector);
      this.vector.multiplyScalar(0.0001);
      this._acceleration.add(this.vector);

      this.vector.set(this.position.x, this.position.y, -this._depth);
      this.vector = this.avoid(this.vector);
      this.vector.multiplyScalar(0.0001);
      this._acceleration.add(this.vector);

      this.vector.set(this.position.x, this.position.y, this._depth);
      this.vector = this.avoid(this.vector);
      this.vector.multiplyScalar(0.0001);
      this._acceleration.add(this.vector);
    }

    if (Math.random() > 0.75) {
      this.flock(boids);
    }

    this.move();
  }

  flock (boids) {
    if (this._goal) {
      this._acceleration.add(this.reach(this._goal, 0.005));
    }
    this._acceleration.add(this.separation(boids));
  }

  move () {
    this.velocity.add(this._acceleration);

    const l = this.velocity.length();

    if (l > this._maxSpeed) {
      this.velocity.divideScalar(l / this._maxSpeed);
    }

    this.position.add(this.velocity);
    this._acceleration.set(0, 0, 0);
  }

  checkBounds () {
    if (this.position.x > this._width) this.position.x = -this._width;
    if (this.position.x < -this._width) this.position.x = this._width;
    if (this.position.y > this._height) this.position.y = -this._height;
    if (this.position.y < -this._height) this.position.y = this._height;
    if (this.position.z > this._depth) this.position.z = -this._depth;
    if (this.position.z < -this._depth) this.position.z = this._depth;
  }

  //

  avoid (target) {
    const steer = new THREE.Vector3();

    steer.copy(this.position);
    steer.sub(target);

    steer.multiplyScalar(1 / this.position.distanceToSquared(target));

    return steer;
  }

  repulse (target) {
    const distance = this.position.distanceTo(target);

    if (distance < 1) {
      const steer = new THREE.Vector3();

      steer.subVectors(this.position, target);
      steer.multiplyScalar(10 / distance);

      this._acceleration.add(steer);
    }
  }

  reach (target, amount) {
    const steer = new THREE.Vector3();

    steer.subVectors(target, this.position);
    steer.multiplyScalar(amount);

    return steer;
  }

  separation (boids) {
    const posSum = new THREE.Vector3();
    const repulse = new THREE.Vector3();

    for (let i = 0, il = boids.length; i < il; i++) {
      if (Math.random() > 0.6) continue;

      const boid = boids[i];
      const distance = boid.position.distanceTo(this.position);

      if (distance > 0 && distance <= this._neighborhoodRadius) {
        repulse.subVectors(this.position, boid.position);
        repulse.normalize();
        repulse.divideScalar(distance);
        posSum.add(repulse);
      }
    }

    return posSum;
  }
}
