import AFRAME, { THREE } from 'aframe';

AFRAME.registerComponent('shadows', {
  schema: {
    camera: { type: 'string' }
  },

  init () {
    this.loaded = false;

    this.camera = document.querySelector(`#${this.data.camera}`);

    this.createShadow();
  },

  createShadow () {
    const self = this;
    const texture = new THREE.TextureLoader().load('./img/utility/shadow.png',
      () => {
        self.width = texture.image.width;
        self.height = texture.image.height;
      });

    const geometry = new THREE.PlaneBufferGeometry(this.width, this.height);
    const material = new THREE.MeshLambertMaterial({
      map: texture,
      transparent: true,
      side: THREE.BackSide,
      depthWrite: false,
      blending: THREE.SubtractiveBlending
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.rotation.x = Math.PI * 0.5;

    this.el.object3D.add(this.mesh);

    const camera = this.data.camera;
    switch (camera) {
      case 'lab_in_cam':
        this.el.object3D.position.y = 0.35;
        break;
      case 'lab_outside_cam':
        this.el.object3D.position.y = 0.3;
        break;
      default:
        this.el.object3D.position.y = 0.01;
    }

    this.loaded = true;
  },

  tick () {
    if (this.loaded) {
      this.el.object3D.position.x = this.camera.object3D.position.x;
      this.el.object3D.position.z = this.camera.object3D.position.z;

      this.height = this.camera.object3D.position.y - this.el.object3D.position.y;

      const scale = Math.pow(this.height, 2) / 1.3;
      this.el.object3D.scale.set(scale, 1, scale);

      this.mesh.material.opacity = 0.1;

      const percent = 1.0 - THREE.Math.clamp(Math.pow(this.height / this.camera.object3D.position.y, 2), 0.5, 0.8);

      this.mesh.material.color = new THREE.Color(percent, percent, percent);
    }
  },

  remove () {
    if (this.mesh) {
      this.mesh.material.map.dispose();
      this.mesh.material.dispose();
      this.mesh.geometry.dispose();
    }
    this.el.object3D.remove(this.mesh);
    this.mesh = null;
  }
});
