import React, { Component } from 'react';

import Interface from './Interface';
import Loader from './Loader';

import './styles.css';

class UI extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loaded: false
    };

    this.click = false;
    this.startTime = new Date();
  }

  componentDidMount () {
    document.addEventListener('main-models-loaded', this.onLoad);
    document.body.classList.add('--loading');
    document.body.classList.add('--mute');
    
    this.consoleLogo();
  }
  
  consoleLogo = () => {
    window.console.log(`%c                                                                                
      &&&&&&&&&&&&&&&&&&&&&&&&                                                 
      &&&&&&&&&&&&&&&&&&&&&&&&                                                 
      &&&&&&&&&&&&&&&&&&&&&&&      &&&&&&&&&                                   
      &&&&&&&&&&&&&&&&&&&&&     &&&&&&&&&&&&&&&&                               
                &&&&&&&&&&    &&&&&&&&&&&&&&&&&&&                              
               &&&&&&&&&&    &&&&&&&&&&&&&&&&&&&&&                            
              &&&&&&&&&&    &&&&&&&&&     &&&&&&&&&        &&&&&&&&&           
             &&&&&&&&&&     &&&&&&&&       &&&&&&&&    &&&&&&&&&&&&&&&&        
            &&&&&&&&&&      &&&&&&&&       &&&&&&&&   &&&&&&&&&&&&&&&&&&&     
           &&&&&&&&&&       &&&&&&&&&     &&&&&&&&&  &&&&&&&&&&&&&&&&&&&&&     
          &&&&&&&&&&         &&&&&&&&&&&&&&&&&&&&&  &&&&&&&&&     &&&&&&&&&    
         &&&&&&&&&&            &&&&&&&&&&&&&&&&&&   &&&&&&&&       &&&&&&&&    
        &&&&&&&&&&&&&&&&&&&&&    &&&&&&&&&&&&&&     &&&&&&&&       &&&&&&&&    
       &&&&&&&&&&&&&&&&&&&&&&      &&&&&&&&&&       &&&&&&&&&     &&&&&&&&&    
      &&&&&&&&&&&&&&&&&&&&&&&                        &&&&&&&&&&&&&&&&&&&&&     
     &&&&&&&&&&&&&&&&&&&&&&&&                         &&&&&&&&&&&&&&&&&&       
                                                        &&&&&&&&&&&&&&         
                                                          &&&&&&&&&&            
                                                                           `, "font-family:monospace");
  }

  onLoad = () => {
    const timeToLoad = new Date();
    const timeDiff = timeToLoad - this.startTime;

    let addToLoad = 0;

    if (timeDiff < 3000) {
      addToLoad = 1000;
    }

    setTimeout(() => {
      document.body.classList.add('--fade-out-loader');

      setTimeout(() => {
        document.body.classList.remove('--fade-out-loader');
        document.body.classList.remove('--loading');
        this.setState({ loaded: true });
      }, 250);
    }, addToLoad);
  }

  render () {
    return (
      <main id="interface">
        { this.state.loaded ? <Interface /> : <Loader /> }
      </main>
    );
  }
}

export default UI;
